import api from "./api";
var queryString = (params) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};

export const getEmail = (params) => {
  return api.get("v1/crm/system-emails?" + queryString(params));
};

export const addEmail = (params) => {
  return api.post("/v1/crm/system-emails/", params);
};

export const deleteEmail = (id) => {
  return api.delete("v1/crm/system-emails/" + id);
};

export const updateEmail = (id, params) => {
  return api.patch("v1/crm/system-emails/" + id, params);
};