import React, { Fragment, useState } from "react";
import { Btn } from "../../../AbstractElements";
// import { NewMessage } from '../../../../../Constant';
import IbFormModal from "../../Pages/Ibs/IbFormModal";

const IbModalButton = ({ btnText, defaultVal }) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <Fragment>
      <Btn attrBtn={{ color: "primary", onClick: toggle }}>{btnText}</Btn>
      <IbFormModal
        modal={modal}
        NewMessage="Add IBs"
        toggle={toggle}
        defaultVal={defaultVal}
      />
    </Fragment>
  );
};

export default IbModalButton;
