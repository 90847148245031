import React, { Fragment, useEffect, useState } from "react";
import { Col, Form, Row } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { useForm } from "react-hook-form";
import { FInput, FTextArea, FSelect } from "CommonElements/Form";
import CommonModal from "../../../Common/Data/Ui-kits/Modals/common/modal";
import { transactionsService } from "Services";
import { toast } from "react-toastify";
import { ModalFooter } from "reactstrap";
import { Close, SaveChanges } from "../../../../Constant";

const ApprovedFormModal = (
  { show, NewMessage, toggle, setApprovedModal, approvedData, onSubmit },
  props
) => {
  const { data = {} } = props;
  const [ submitState, setSubmitState] = useState({
    loading: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
    getValues,
    setValue,
    control
  } = useForm();
  const resetForm = () => {
    return reset({
      status: "",
      reason: "",
    });
  };



  return (
    <Fragment>
      <CommonModal
        isOpen={show}
        title={'Reject Document'}
        toggler={toggle}
        isButtonShown={true}
        
      >
        <Form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row style={{ marginBottom: 10 }}>
            <Col md='12 mb-3' >
                <FSelect
                    control={control}
                    errors={errors}
                    label = 'Select Status'
                    name = 'status'
                    register = {register}
                    placeholder= 'Select Status'
                    options = {[
                        {name: 'Rejected', value: 'Rejected'},
                        {name: 'Expired', value: 'Expired'}
                    ]}
                    setValue={setValue}
                    validation={{required: true}}

                />
            </Col>
            <Col md='12 mb-3' >
                <FInput
                    errors={errors}
                    label = 'Reason'
                    name = 'rejectionReason'
                    register = {register}
                    placeholder= 'Enter Reason'
                    validation={{required: true}}
                />
            </Col>
          </Row>
         
          <ModalFooter>
            <Btn
              attrBtn={{
                color: "secondary",
                onClick: () => toggle(),
                type: "button",
              }}
            >
              {Close}
            </Btn>
            <Btn disabled={submitState.loading} attrBtn={{ color: "primary"}}>
              {SaveChanges}
            </Btn>
          </ModalFooter>
        </Form>
      </CommonModal>
    </Fragment>
  );
};
export default ApprovedFormModal;
