import api from "./api";
var queryString = (params) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};
export const getBanners = (params={}) => {
  return api.get("v1/crm/banners?" + queryString(params));
};

export const deleteBanner = (id) => {
  return api.delete("v1/crm/banners/" + id);
};

export const addBanner = (params) => {
  console.log(params);
  return api.post("v1/crm/banners", params,{
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const updateBanner = (id, params) => {
  //return api.patch("v1/crm/banners/"+id, params);
  return api.patch("v1/crm/banners/"+id, params,{
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const getGroups = (params={}) => {
  return api.get("v1/crm/banners?" + queryString(params));
};