import React, { Fragment, useState } from "react";
import { Col, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";

import { tradingAccountsService } from 'Services';
import CommonModal from "Components/Common/Data/Ui-kits/Modals/common/modal";
import { FSelect, FInput } from 'CommonElements/Form';
import { Btn } from "AbstractElements";

const AddBankAccount = ({ customerId,  loadData, accountTypes = []  }) => {
  const [modal, setModal] = useState(false);
  const [accountType, setAccountType] = useState('');
  const [platform, setPlatform] = useState('');

  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal(!modal)

  };
  const { register, handleSubmit, formState: { errors }, reset, setValue, control } = useForm();
  
  const formValueChange = (name, value) => {
    setValue(name , value);
    if(name === 'accountType') {
        setAccountType(value);
    }
    if(name === 'platform') {
        setPlatform(value);
    }
  }

  setTimeout(() => {
    // setAccountType('LIVE');
    // setValue('accountType', 'LIVE')
  }, 1);

  const modalSubmit = formValues => {
    if (formValues !== '') {
        setLoading(true);
        const params = {
            login: formValues.login,
            accountTypeId: formValues.accountTypeId,
            customerId,
            platform: formValues.platform,
            currency: formValues.currency
        }
        tradingAccountsService.linkAccount(params).then(res => {
            setLoading(false);
            toggle();
            reset();
            toast.success('Trading account linked, successfully');
            loadData();
        }).catch(err => {
            setLoading(false);
            toast.error(err?.response?.data?.message || 'Failed to link Trading Account');
        })
    } else {
        errors.showMessages();
        toast.error('Failed to link Trading Account');

    }
};
  return (
    <Fragment>
        {/* <Button disabled={loading} onClick={toggle}  color={'primary'} >{'Add Bank Account'}</Button> */}
        <Btn attrBtn={{ disabled:loading, onClick:toggle, color: 'primary' }} >{'Link Account'}</Btn>

        <CommonModal
            isOpen={modal}
            title={'Link Existing Account'}
            toggler={toggle}
            formSubmit={()=>{}}
            hideFooter={true}
        >
            <Form  className="needs-validation" noValidate="" 
                onSubmit={handleSubmit(modalSubmit)}
            >
                    <Row>  
                        <Col md='4 mb-3' >
                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Category'
                                name = 'accountType'
                                register = {register}
                                options = {[{name: 'Live', value: 'LIVE'},{name: 'Demo', value: 'DEMO'}]}
                                validation={{required: true}}
                                // value={accountType}
                                setValue={formValueChange}
                            />
                        </Col>
                        
                        <Col md='4 mb-3' >
                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Platform'
                                name = 'platform'
                                register = {register}
                                placeholder = 'Platform'
                                options = {[{name: 'MT4', value: 'MT4'},{name: 'MT5', value: 'MT5'}]}
                                validation={{required: true}}
                                // value={accountType}
                                setValue={formValueChange}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Currency'
                                name = 'currency'
                                register = {register}
                                placeholder = 'Currency'
                                options = {[{name: 'USD', value: 'USD'},{name: 'EUR', value: 'EUR'},{name: 'CNT', value: 'CNT'},{name: 'AED', value: 'AED'}]}
                                validation={{required: true}}
                                // value={accountType}
                                setValue={formValueChange}
                            />
                        </Col>
                        <Col md='6 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Login Number'
                                name = 'login'
                                register = {register}
                                placeholder= 'Enter login number of account'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='6 mb-3' >
                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Select Account Type'
                                name = 'accountTypeId'
                                register = {register}
                                placeholder = 'Select account Type'
                                options = {accountTypes.filter(obj => obj.type === accountType && obj.platform === platform && obj.showInCp === 1).map(obj => {
                                    return {value: obj.id, name: obj.title}
                                })}
                                validation={{required: true}}
                                // value={accountType}
                                setValue={formValueChange}
                            />
                        </Col>
                        <Col md={12} className="d-flex flex-row-reverse">
                            <Btn disabled={loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn>
                        </Col>
                        
                    </Row>

                    {/* <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn> */}
                </Form>
        </CommonModal>
    </Fragment>
  );
};

export default AddBankAccount;
