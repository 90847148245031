import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { FSelect, FDateTime } from 'CommonElements/Form';
import { getMenuPerm } from 'utils';
import { useSelector } from 'react-redux';
import { writeFile, utils } from 'xlsx';
import { reportService } from 'Services';
import { FormGroup, Button } from 'reactstrap';
import { addFourHours } from '../../Common/Utility';


const SearchForm = ({ formValues, onSearchSubmit, reportData }) => {
  const [currentDate] = useState(new Date());
  const [submitState, setSubmitState] = useState({
    loading: false,
  });

  const [exportData, setExportData] = useState([]);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [option] = useState([
    { value: 'deposit', name: 'Deposit' },
    { value: 'withdrawal', name: 'Withdrawal' },
    { value: 'funded', name: 'Funded Clients' },
    { value: 'unFunded', name: 'Un Funded Clients' },
    { value: 'ibPayout', name: 'IB Payout' },
    { value: 'ibTotal', name: 'IB Total' },
    { value: 'ibInternalTransfer', name: 'IB Internal Transfer' },
  ]);

  const getParamsAndBody = (url) => {
    const queryParams = extractUrlParams(url);
    const bodyParams = queryParams;

    return {
      bodyParams
    };
  };
  // Function to extract query parameters from the URL
  const extractUrlParams = (url) => {
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    const queryParams = {};
    for (const [key, value] of params) {
      queryParams[key] = value;
    }

    return queryParams;
  };
  const currentUrl = window.location.href;
  let { bodyParams } = getParamsAndBody(currentUrl);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm();

  const dataExport = async () => {

    setSubmitState({ loading: true });
    const exports = {
      limit: 100000,
      dateFrom: addFourHours(bodyParams.fromDate),
      dateTo: addFourHours(bodyParams.toDate),
      page: 1
    };
    reportService
      .getReports(exports, formValues.report)
      .then((res) => {
        console.log(res);
        setSubmitState({
          loading: false,
        });
        if (res.data != null) {
          setExportData(res);
          const workbook = exportToExcel(res.data);
          console.log("hma,sdmfk");
          writeFile(workbook, 'report_list.xlsx');
        }
      })
      .catch((err) => {
        setSubmitState({
          loading: false,
        });
        console.log('Failed!');
      });
    return true;
  }
  const permissions = useSelector(
    (state) =>
      (state.login &&
        state.login.profile &&
        state.login.profile.Role &&
        state.login.profile.Role.permissions) ||
      {}
  );


  const handleExport = async () => {
    await dataExport();
  };
  function exportToExcel(data) {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'report_sheet');
    return workbook;
  }

  return (
    <Fragment>
      <Form
        className='needs-validation'
        noValidate=''
        onSubmit={handleSubmit(onSearchSubmit)}
      >
        <FormGroup className='form-group row' style={{ padding: '30px' }}>
          <Col md='3'>
            <FSelect
              control={control}
              errors={errors}
              label='Select Report'
              name='report'
              register={register}
              placeholder='Select Report'
              options={option}
              validation={{ required: true }}
              value={formValues.report}
              setValue={setValue}
            />
          </Col>
          <Col md='3'>
            <FDateTime
              errors={errors}
              label='From Date'
              name='fromDate'
              value={formValues.fromDate}
              register={register}
              placeholder='From Date'
              validation={{ required: true }}
              setValue={setValue}
              selectedValue={bodyParams.fromDate || new Date(currentDate.setMonth(currentDate.getMonth() - 3))}
            />
          </Col>
          <Col md='3'>
            <FDateTime
              errors={errors}
              label='To Date'
              name='toDate'
              value={formValues.toDate}
              register={register}
              placeholder='To Date'
              validation={{ required: true }}
              setValue={setValue}
              selectedValue={bodyParams.toDate || new Date().setDate(currentDate.getDate() + 1)}
            />
          </Col>
          <Col md='2' className=''>
            <Button
              style={{
                marginTop: 30,
                position: 'relative',
                left: '2rem',
                paddingLeft: '50px',
                paddingRight: '50px',
                fontSize: '15px',
              }}
            >
              Search
            </Button>
          </Col>
          {reportData.data && reportData.data.length > 0 ? (
            <Col md={'2'}>
              {getMenuPerm(permissions, 'reports:view') === 1 &&
                <Button
                  className='btn-primary'
                  onClick={handleExport}
                  style={{
                    marginTop: 30,
                    position: 'relative',
                    left: '2rem',
                    paddingLeft: '50px',
                    paddingRight: '50px',
                    fontSize: '15px',
                    backgroundColor: '#51bb25',
                    borderColor: '#51bb25',
                  }}
                >
                  Export
                </Button>
              }
            </Col>
          ) : null}
        </FormGroup>
      </Form>
    </Fragment>
  );
};
export default SearchForm;
