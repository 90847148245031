import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, CardHeader, Table } from 'reactstrap';
import TableLoader from 'CommonElements/Table/TableLoader';
import TableNodata from 'CommonElements/Table/TableNodata';
import Swal from "sweetalert2";
import { customerNotesService } from "Services";
import { toast } from 'react-toastify';
import { formatTimeInDubaiZone } from '../../../../Common/Utility';


const tasksToDo = ({ notes }) => {
  const rec = notes;


  const approveRequest = (id) => {
    Swal.fire({
      title: "Are you sure you want to Approve?",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      reverseButtons: true,
      showCancelButton: true,
      text: "Once approved, you will not be able to approve this request!",
      icon: "success",
    }).then((res) => {
      if (res.isConfirmed) {
        const params = {};
        params.isReminder = false;
        customerNotesService
          .updateCustomerNotes(id, params)
          .then((res) => {
            toast.success('Note Updated, successfully');
            //loadNotes();
          })
          .catch((err) => {
            toast.error('Failed to add Note');
          });
      }
    });
  };
  return (
    <Col style={{ padding: '0px' }}>
      <Col>
        <Card>
          <CardHeader className='p-3 d-flex align-items-center justify-content-between'>
            Tasks to do
          </CardHeader>
          <CardBody>
            <Table hover striped>
              {rec && rec.loading && <TableLoader />}
              {rec && !rec.loading && rec.data && rec.data.length === 0 && (
                <TableNodata title='Tasks' />
              )}

              <tbody>
                {rec && rec.data &&
                  rec.data
                    .filter(item => item.isReminder === 1)
                    .map((item, id) => (
                      <tr key={id}>
                        <td>
                          <strong>Lead Id:</strong> <a href={`${process.env.PUBLIC_URL}/leads/${item['Customer.id']}`}>{item['Customer.id']}</a><br></br>
                          <strong>Name:</strong> {item['Customer.firstName'] + ' ' + item['Customer.lastName']}<br></br>
                          <strong>Note:</strong> {item.note}<br></br>
                          <p>{formatTimeInDubaiZone(item.remainderTime, 1)}</p>
                        </td>
                        <td style={{ verticalAlign: 'middle' }}>
                          <span
                            onClick={(e) => {
                              approveRequest(item.id);
                            }}
                            className="m-2 font-success" style={{ cursor: 'pointer' }}>
                            <i className="fa fa-check"></i>
                          </span>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Col>
  );
};

export default tasksToDo;
