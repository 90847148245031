import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardHeader, Table, Container } from "reactstrap";
import { H5 } from "../../../AbstractElements";
import { videosService } from "Services";
import TableLoader from "CommonElements/Table/TableLoader";
import TableNodata from "CommonElements/Table/TableNodata";
import { Row } from "react-bootstrap";
import { FSwitch } from "CommonElements/Form";
import { Breadcrumbs } from '../../../AbstractElements';
import AddVideo from "./AddVideos";
import EditVideo from "./EditVideo";
import DeleteVideo from "./DeleteVideo";
import { toast } from "react-toastify";
import TablePagination from "CommonElements/Table/WithoutFilterPagination";
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';

// import { Link } from "react-router-dom";

const Videos = () => {

  const [rec, setRec] = useState({});
  useEffect(() => {
    loadData(1);
  }, []);
  const loadData = (page = 1) => {
    setRec({
      loading: true,
      data: [],
    });
    videosService
      .getvideos({
        page,
      })
      .then((res) => {
        setRec({
          ...res,
          loading: false,
        });
      })
      .catch((err) => {
      });
  }

  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  );

  const changeStatus = (item, index, fieldName) => {
    videosService
      .updateVideo(
        item.id,
        {
          [fieldName]: !item[fieldName],
        },
      )
      .then((res) => {
        rec.data[index].isActive = !item.isActive;
        toast.success("Video updated, successfully");
      })
      .catch((err) => {
        toast.error("Failed to update Video");
      });
  };
  return (
    <Fragment>
      <Breadcrumbs title="Videos" mainTitle='' />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="8">
                    {" "}
                    <H5>{'Videos'}</H5>
                  </Col>
                  <Col md="4" className="" style={{ textAlign: 'right' }}>
                    {getMenuPerm(permissions, 'banners:create') === 1 &&
                      <AddVideo loadData={loadData} />
                    }
                  </Col>
                </Row>
              </CardHeader>
              <div className="table-responsive">
                <Table hover striped>
                  <thead>
                    <tr>
                      <th scope="col">{"#"}</th>
                      <th scope="col">{"Gallery"}</th>
                      <th scope="col">{"Url"}</th>
                      <th scope="col">{"File"}</th>
                      <th scope="col">{"Active"}</th>
                      <th scope="col">{"Actions"}</th>

                    </tr>
                  </thead>
                  {rec.loading && <TableLoader />}
                  {!rec.loading && rec.data && rec.data.length === 0 && (
                    <TableNodata title="Videos" />
                  )}
                  <tbody>
                    {rec.data &&
                      rec.data.map((item, id) => (
                        <tr key={id}>
                          <th scope="row">{item.id}</th>
                          <td>{item.gallery}</td>
                          <td>{item.url}</td>
                          <td>{item.fileUrl}</td>
                          <td>
                            {getMenuPerm(permissions, 'banners:update') === 1 &&
                              <FSwitch
                                onChange={() => {
                                  changeStatus(item, id, 'isActive');
                                }}
                                value={item.isActive}
                              />}
                          </td>
                          <td>
                            {getMenuPerm(permissions, 'banners:update') === 1 &&
                              <EditVideo data={item} loadData={loadData} />
                            }
                            {getMenuPerm(permissions, 'banners:delete') === 1 &&
                              <DeleteVideo data={item} loadData={loadData} />
                            }
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <TablePagination data={rec} loadData={loadData} />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Videos;
