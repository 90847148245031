import React, { Fragment, useState } from "react";
import { Col, Form, Row, Input } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";

import { ibService } from 'Services';
import CommonModal from "Components/Common/Data/Ui-kits/Modals/common/modal";
import { FInput } from 'CommonElements/Form';
import { Btn } from "AbstractElements";

const AddGroup = ({ customerId, loadData }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const mappings = [{ title: 'Standard' }, { title: 'Standard MT4' }];

  const toggle = () => {
    setModal(!modal);
  };

  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();

  const modalSubmit = (formValues) => {
    console.log('Modal submit first', formValues);
    setLoading(true);
    const params = { ...formValues, ibId: customerId };
    // ibService.postGroupMapping(params).then(res => {
    //     setLoading(false);
    //     toggle();
    //     reset();
    //     toast.success('Group added, successfully');
    //     loadData();
    // }).catch(err => {
    //     setLoading(false);
    //     console.log('error is ', err);
    //     toast.error('Failed to add Groups');
    // });
    if (formValues !== '' && formValues.item) {
      console.log("Group Values is ", formValues.item);
      ibService.postGroupMapping(customerId, formValues.item).then(res => {
        toast.success('Group added, successfully');
        //loadData();
      }).catch(err => {
        toast.error('Failed to add Groups');
      });
    } else {
      toast.error('Form Values empty');
    }
  };

  return (
    <Fragment>
      <Btn attrBtn={{ disabled: loading, onClick: toggle, color: 'primary' }}>{'Add IB Group'}</Btn>

      <CommonModal
        isOpen={modal}
        title={'Add IB Group'}
        toggler={toggle}
        formSubmit={() => { }}
        hideFooter={true}
      >
        <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(modalSubmit)}>
          <Row>
            {mappings.map((item, index) => (
              <React.Fragment key={index}>
                <Col md="4" className="mb-3">
                  {/* <Input
                        name={`item[${index}].accountTypeId`} // Format the name correctly
                        defaultValue={item.title === 'Standard' ? 2 : 14} // Use defaultValue for hidden inputs
                        type="hidden"
                    /> */}
                  <FInput
                    name={`item[${index}].accountTypeId`}
                    register={register}
                    validation={{ required: true }}
                    value={item.title === 'Standard' ? 2 : 14}
                    type="hidden"
                  />
                  <FInput
                    errors={errors}
                    label={`${item.title} Group`}
                    name={`item[${index}].groupPath`}
                    register={register}
                    placeholder="Enter group Path"
                  />
                </Col>
                <Col md="4" className="mb-3">
                  <FInput
                    errors={errors}
                    label={`${item.title} Group Swap Free`}
                    name={`item[${index}].groupPathSwapFree`}
                    register={register}
                    placeholder="Enter group Path"
                  />
                </Col>
                <Col md="4" className="mb-3">
                  <FInput
                    errors={errors}
                    label="Leverage"
                    name={`item[${index}].leverage`}
                    register={register}
                    placeholder="Enter Leverage"
                  />
                </Col>
              </React.Fragment>
            ))}
          </Row>
          <Col md={12} className="d-flex flex-row-reverse mt-4">
            <Btn attrBtn={{ color: 'primary', type: 'submit' }}>{'Add Group'}</Btn>
          </Col>
        </Form>
      </CommonModal>
    </Fragment>
  );
};

export default AddGroup;
