import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardBody, Form, Row, CardHeader, Button } from 'reactstrap';
import { Btn } from '../../../../AbstractElements';
import { useForm } from 'react-hook-form';
import { FInput, FUserSelect, FSelect, FTextArea } from 'CommonElements/Form';
import { clientService } from 'Services';
import SweetAlert from 'sweetalert2';
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';
import AuthService from "../../../../Services/auth.service";
import Notes from '../Notes/Notes';


const BasicInformation = (props) => {
    const [coldCall, setcoldCall] = useState(false);

    const {
        customerId = '',
        data = {},
    } = props;
    const COUNTRIES = useSelector(
        (state) => (state.config.constants.COUNTRIES)
    );
    const [submitState, setSubmitState] = useState({
        loading: false,
    })

    const [kycStatusValue, setKycStatusValue] = useState(data.kycStatus);
    const [kycTrigger, setkycTrigger] = useState(false);

    const [fullName, setFullName] = useState(`${data.firstName} ${data.lastName ? data.lastName : ''}`);
    const handleFullName = (e) => {
        setFullName(e.target.value);
        setValue('firstName', e.target.value);
    };

    const { register, handleSubmit, formState: { errors }, setValue, getValues, control } = useForm();
    useEffect(() => {
        const formFields = getValues();
        Object.keys(formFields).forEach(key => {
            if (data[key]) {
                setValue(key, data[key])
            }
        })
        setValue('callStatus', '')
    }, [])
    const permissions = useSelector(
        (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
    );

    const setFlagToTrue = () => {
        setcoldCall(true);
    };
    
    const onSubmit = data => {
        console.log('data value: ', data);
    
        // Clear DetailNote if kycStatus is not 'pending'
        if (data.kycStatus !== 'pending') {
            setValue('DetailNote', ''); 
        }
    
        // Validate and handle submission
        if (data.kycStatus === 'pending' && kycTrigger && data.DetailNote === '') {
            SweetAlert.fire({ title: 'Failed', text: 'Please provide DetailNote', icon: 'error' });
            return;
        }
    
        // Proceed with submission
        setSubmitState({ loading: true });

        data.kycTrigger = kycTrigger; // add new value to data object
    
        clientService.updateClient(customerId, data)
            .then(res => {
                setSubmitState({
                    loading: false,
                    status: 'Profile updated successfully'
                });
                SweetAlert.fire({ title: 'Success', text: 'Profile updated successfully!', icon: 'success' });
            })
            .catch(err => {
                setSubmitState({
                    loading: false,
                    status: 'Error in updating Profile'
                });
                SweetAlert.fire({ title: 'Failed', text: 'Error in updating Profile!', icon: 'error' });
            });
    };
    

    const handleClick = (email, password) => {
        console.log('Handle client', email);
        AuthService.masterLoginIB(email, password);
    };
    return (
        <Fragment>
            <Row>
                <Col md="8">
                    <Card>
                        <CardHeader className='p-3 d-flex justify-content-between'>
                            Basic Information IB
                            {permissions && permissions.ibs && permissions.ibs.viewAll && <Button class="btn-primary" onClick={() => { handleClick(customerId, 'y6f4n@E94DEg^bwa') }} style={{ cursor: 'pointer', fontSize: '14px', background: 'primary', color: 'primary' }}>{'Login'}</Button>}
                        </CardHeader>
                        <CardBody>
                            <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col md='4 mb-3' >
                                        <FInput
                                            errors={errors}
                                            label='Full Name'
                                            name='firstName'
                                            value={fullName}
                                            handleOnChange={handleFullName}
                                            register={register}
                                            //placeholder= 'Enter First Name'
                                            validation={{ required: true }}
                                        />
                                    </Col>
                                    {/* <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Last Name'
                                name = 'lastName'
                                register = {register}
                                placeholder= 'Enter Last Name'
                            />
                        </Col> */}
                                    <Col md='4 mb-3' >
                                        <FInput
                                            errors={errors}
                                            label='Email'
                                            name='email'
                                            register={register}
                                            placeholder='Enter Email Address'
                                            validation={{ required: true }}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FInput
                                            errors={errors}
                                            label='Phone'
                                            name='phone'
                                            register={register}
                                            placeholder='Enter Phone Number'
                                            validation={{ required: true }}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    {/* <Col md='4 mb-3' >
                           
                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Call Status'
                                name = 'callStatus'
                                register = {register}
                                placeholder= 'Enter Call Status'

                                options = {[
                                    {name: 'Not contacted ', value: 'Not contacted '},
                                    {name: 'Not Interested', value: 'Not Interested'},
                                    {name: 'Not Reachable ', value: 'Not Reachable '},
                                    {name: 'Cold -  call later ', value: 'Cold -  call later '},
                                    {name: 'Warm -  call after 10 days ', value: 'Warm -  call after 10 days '},
                                    {name: 'Hot - Intrested ', value: 'Hot - Intrested '},
                                    {name: 'Wrong Number', value: 'Wrong Number'},
                                    {name: 'Invalid number ', value: 'Invalid number '},
                                    {name: 'Do not call again ', value: 'Do not call again '},
                                ]}
                                
                                
                                validation={{required: true}}
                                value={data.callStatus}
                                setValue={setValue}
                            />
                        </Col> */}
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <FInput
                                            errors={errors}
                                            label='Enter Source'
                                            name='source'
                                            register={register}
                                            placeholder='Enter Source'
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FUserSelect
                                            control={control}
                                            errors={errors}
                                            label='Select Assigned User'
                                            name='agentId'
                                            validation={{ required: true }}
                                            setValue={setValue}
                                            value={data.agentId}
                                            defaultOptions={data.Agent ? [data.Agent] : []}

                                        // isClient={true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FSelect
                                            control={control}
                                            errors={errors}
                                            label='Country of Residence'
                                            name='country'
                                            register={register}
                                            placeholder='Enter Country of Residence'
                                            validation={{ required: true }}
                                            value={data.country}
                                            options={COUNTRIES && COUNTRIES.map(obj => {
                                                return {
                                                    name: obj.text,
                                                    value: obj.text
                                                }
                                            })}
                                            setValue={setValue}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FSelect
                                            control={control}
                                            errors={errors}
                                            label='KycStatus'
                                            name='kycStatus'
                                            register={register}
                                            //placeholder=''
                                            options={[
                                                { name: 'New Case', value: 'new' },
                                                { name: 'Pending Documents', value: 'pending' },
                                                { name: 'KYC Approved', value: 'approved' },
                                                data.kycStatus === 'missing' && { name: 'Missing', value: 'missing' },
                                            ]}
                                            value={data.kycStatus}
                                            validation={{ required: false }}
                                            setValue={setValue}
                                            onChange={(e) => {
                                                console.log('FSelect changed:', e.value); // Log the event
                                                setKycStatusValue(e.value); // Update state with the selected value
                                                setkycTrigger(true);
                                            }}
                                        />
                                    </Col>
                                    { ( kycStatusValue === 'pending' ) && (
                                        <Col md="12 mb-3">
                                            <FTextArea
                                                control={control}
                                                errors={errors}
                                                label="DetailNote"
                                                name="DetailNote"
                                                register={register}
                                                validation={{ required: false }}
                                                setValue={setValue}
                                            />
                                        </Col>
                                    )}
                                    <Col md='4 mb-3' >
                                        <FInput
                                            errors={errors}
                                            label='Page Url'
                                            name='pageUrl'
                                            register={register}
                                            placeholder='Page Url'
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <FInput
                                            errors={errors}
                                            label='Website Langauge'
                                            name='language'
                                            register={register}
                                            placeholder='Website Langauge'
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <FInput
                                            errors={errors}
                                            label='Affiliate ID'
                                            placeholder='Affiliate ID'
                                            name='affiliateId'
                                            register={register}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <FInput
                                            errors={errors}
                                            label='UTM Campaign'
                                            placeholder='UTM Campaign'
                                            name='utmCampaign'
                                            register={register}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <FInput
                                            errors={errors}
                                            label='UTM Source'
                                            placeholder='UTM Source'
                                            name='utmSource'
                                            register={register}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <FInput
                                            errors={errors}
                                            label='UTM Medium'
                                            placeholder='UTM Medium'
                                            name='utmMedium'
                                            register={register}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <FInput
                                            errors={errors}
                                            label='UTM Term'
                                            placeholder='UTM Term'
                                            name='utmTerm'
                                            register={register}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <FInput
                                            errors={errors}
                                            label='UTM Content'
                                            placeholder='UTM Content'
                                            name='utmContent'
                                            register={register}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <FInput
                                            errors={errors}
                                            label='UTM Category'
                                            placeholder='UTM Category'
                                            name='utmCategory'
                                            register={register}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FInput
                                            errors={errors}
                                            label='Website'
                                            placeholder='Website'
                                            name='website'
                                            register={register}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>








                                    <Col md='4 mb-3' >
                                        <FInput
                                            errors={errors}
                                            label='Enter Address Steet'
                                            name='address'
                                            register={register}
                                            placeholder='Enter Address Street'
                                        // validation={{required: true}}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FInput
                                            errors={errors}
                                            label='Enter PO Box'
                                            name='address2'
                                            register={register}
                                            placeholder='Enter PO Box'
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FInput
                                            errors={errors}
                                            label='Enter Postal Code'
                                            name='zipCode'
                                            register={register}
                                            placeholder='Enter Postal Code'
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FInput
                                            errors={errors}
                                            label='City'
                                            name='city'
                                            register={register}
                                            placeholder='Enter City'
                                            validation={{ required: true }}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FInput
                                            errors={errors}
                                            label='State'
                                            name='state'
                                            register={register}
                                            placeholder='Enter State'
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>

                                    <Col md='4 mb-3' >
                                        <FSelect
                                            control={control}
                                            errors={errors}
                                            label='Nationality'
                                            name='nationality'
                                            register={register}
                                            placeholder='Enter Nationality'
                                            validation={{ required: true }}
                                            value={data.nationality}
                                            options={COUNTRIES && COUNTRIES.map(obj => {
                                                return {
                                                    name: obj.text,
                                                    value: obj.text
                                                }
                                            })}
                                            setValue={setValue}
                                        />
                                    </Col>

                                </Row>

                                {getMenuPerm(permissions, 'ibs:update') === 1 &&
                                    <Btn disabled={submitState.loading} attrBtn={{ color: 'primary' }} >{'Update'}</Btn>
                                }
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="4 mb-3">

                    <Card style={{ overflow: 'visible', height: '400px', overflowY: 'scroll' }}>
                        <CardBody style={{ padding: '30px' }}>
                            <Row>
                                <Col>
                                    <Notes customerId={customerId} setFlagToTrue={setFlagToTrue} />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};
export default BasicInformation;
