import React from 'react';

const Loader = ({title=""}) =>(
    <tbody>
        <tr>
            <td className='text-center ' colSpan={"100%"}>
                No {title === "" ? "records": title} found
            </td>
            
        </tr>
    </tbody>
);

export default Loader;