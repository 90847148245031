import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import UploaderFile from '../../../Shared/FileUpload/Index';
import { documentsService, activitiesService } from 'Services';
import Swal from "sweetalert2";
import { formatTimeInDubaiZone } from '../../../Common/Utility/index';
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  CardHeader,
} from 'reactstrap';
import { FCheckbox } from 'CommonElements/Form';
import { Btn, UL, LI } from '../../../../AbstractElements';

const Documents = (props) => {
  const { customerId = '', loadData } = props;
  const [loading, setLoading] = useState(false);
  const [emialFlag, setEmailFlag] = useState([]);
  const [selectedDocs, setSelectedDocs] = useState({
    proof_of_Id: false,
    proof_of_address: false,
    additional_docs: false,
  });

  const userProfile = useSelector(
    (state) => (state.login && state.login.profile) || {}
  );


  useEffect(() => {
    fetchActivities();
  }, [customerId]); // Add customerId as dependency if it can change

  let dateTimeData;
  const fetchActivities = async () => {
    try {
      const res = await activitiesService.getActivities({ customerId: customerId ,type: `emailSend to ${customerId}`},);
      setEmailFlag(res); // Adjust according to actual data structure
      console.log(res);
    } catch (err) {
      console.log("err: ", err);
    }
  };

  // Handler to toggle checkbox selection
  const handleCheckboxChange = (name, label) => {
    setSelectedDocs((prevSelectedDocs) => ({
      ...prevSelectedDocs,
      [name]: !prevSelectedDocs[name],
    }));

    // Log the selectedData before the actual API call
    console.log('Selected Data (before API call):', { [name]: label });
  };

  // Handler to submit selected data to API
  const pendingDoc = (e) => {
    e.preventDefault(); // Prevents the default form submission

    // Prepare data to be sent to the API based on checkbox selections
    const selectedData = Object.keys(selectedDocs).reduce((acc, doc) => {
      if (selectedDocs[doc]) {
        let modifiedValue = doc.replace(/_/g, ' '); // Convert 'proof_of_Id' to 'Proof of ID'
        // Modify the value as needed
        modifiedValue = modifiedValue.toUpperCase(); // Example: Convert to uppercase
        acc[doc] = modifiedValue;
      }
      return acc;
    }, {});

    // Log the selectedData before the actual API call
    console.log('Selected Data (before API call):', selectedData);
    selectedData.customerId = customerId;
    Swal.fire({
      title: "Are you sure you want to Approve?",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      reverseButtons: true,
      showCancelButton: true,
      text: "Once approved, you will not be able to change this request!",
      icon: "success",
    }).then((res) => {
      if (res.isConfirmed) {
        //setModal(false);
        documentsService
          .pendingDocs(selectedData)
          .then((res) => {
            setLoading(false);
            toast.success('Email Sent, successfully');
            // loadData();
          })
          .catch((err) => {
            setLoading(false);
            toast.error('Failed to sent email!');
          });
        
        const docName = selectedData.proof_of_Id && selectedData.proof_of_address && selectedData.source_of_income ? 'proof_of_Id, proof_of_address, source_of_income' :
        selectedData.proof_of_Id ? 'proof_of_Id' : selectedData.proof_of_address ? 'proof_of_address': 'source_of_income';  

        activitiesService.postActivity({
          type: `emailSend to ${customerId}`,
          customerId: customerId,
          note: `Email of ${docName} send By ${userProfile.firstName}`
        })
          .then((res) => {
            setLoading(false);
            toast.success('activitiy post, successfully');
            // loadData();
            fetchActivities();
          })
          .catch((err) => {
            setLoading(false);
            toast.error('Failed to post activity!');
          });
      }
    });

  };

  const [submitState, setSubmitState] = useState({
    loading: false,
  });
  const [imageList, SetImageList] = useState([]);
  const { handleSubmit, setValue } = useForm();

  const onSubmit = (data) => {
    if (imageList !== '') {
      setSubmitState({ loading: true });

      const updatePromises = [];
      const uploadPromises = [];
      const formDataMap = new Map();

      // Populate formDataMap with formData for each document type
      for (let index = 0; index < imageList.length; index++) {
        const key = imageList[index].key;
        if (!formDataMap.has(key)) {
          formDataMap.set(key, new FormData());
          formDataMap.get(key).append('customerId', customerId);
        }
        for (let i = 0; i < imageList[index].value.length; i++) {
          const fileData = imageList[index].value[i];
          formDataMap.get(key).append(key, fileData);
        }
      }

      // Ensure props.fileData is an array or provide an empty array as a fallback
      const fileDataArray = Array.isArray(props.fileData) ? props.fileData : [];

      // Determine whether to update or upload documents
      formDataMap.forEach((formData, key) => {
        const file = fileDataArray.find(file => file.type === key);
        console.log('file: ', file);
        if (file) {
          // If file type exists, update the document
          updatePromises.push(
            documentsService.updateDocuments(file.id, formData)
          );
        } else {
          // If file type does not exist, upload the document
          uploadPromises.push(
            documentsService.uploadDocuments(formData)
          );
        }
      });

      //Handle the update and upload promises
      Promise.all([...updatePromises, ...uploadPromises])
        .then((res) => {
          setSubmitState({
            loading: false,
            status: 'Documents uploaded successfully',
          });
          SweetAlert.fire({
            title: 'Success',
            text: 'Documents uploaded successfully!',
            icon: 'success',
          });
          loadData();
          SetImageList([]);
        })
        .catch((err) => {
          setSubmitState({
            loading: false,
            status: 'Error in uploading documents',
          });
          SweetAlert.fire({
            title: 'Failed',
            text: 'Error in uploading documents!',
            icon: 'error',
          });
        });
    }
  };


  const updateListFunction = (key, value) => {
    SetImageList([...imageList, { key, value }]);
  };

  return (
    <Fragment>
      <Row>
        <Col md={8}>
          <Card>
            <CardHeader className='p-3'>Upload Documents</CardHeader>
            <Form
              className='form theme-form needs-validation'
              noValidate=''
              onSubmit={handleSubmit(onSubmit)}
            >
              <CardBody>
                <Row>
                  <FormGroup className='row'>
                    <Col md={12}>
                      <UploaderFile
                        fileTitle='Proof of Id'
                        fileName='PROOF_OF_ID'
                        updateList={updateListFunction}
                      />
                    </Col>
                    <Col md={12}>
                      <UploaderFile
                        fileTitle='Proof of Address'
                        fileName='PROOF_OF_ADDRESS'
                        updateList={updateListFunction}
                      />
                    </Col>
                    <Col md={12}>
                      <UploaderFile
                        fileTitle='Additional Documents'
                        fileName='ADDITIONAL'
                        updateList={updateListFunction}
                      />
                    </Col>
                  </FormGroup>
                </Row>
                <Col md={12} className='d-flex flex-row-reverse'>
                  <Btn
                    disabled={submitState.loading}
                    attrBtn={{ color: 'primary' }}
                  >
                    {'Upload'}
                  </Btn>
                </Col>
              </CardBody>
            </Form>
          </Card>
        </Col>
        <Col md={4}>
          <Card className='p-3'>
            <UL>
              <LI>
                Proof of ID max 2 files on every upload - Front & Back for ID -
                First & Second Page for Passport
              </LI>
              <LI>Proof of Address max 2 files</LI>
              <LI>
                Additional max 2 files on every upload, you can upload as many
                files as you want
              </LI>
            </UL>
          </Card>
          <form onSubmit={pendingDoc}>
            <Card className='p-3'>
              <div>
                <FCheckbox
                  label='Proof Of ID'
                  name='proof_of_Id'
                  value={selectedDocs.proof_of_Id}
                  setValue={handleCheckboxChange}
                />
              </div>
              <div>
                <FCheckbox
                  label='Proof Of Address'
                  name='proof_of_address'
                  value={selectedDocs.proof_of_address}
                  setValue={handleCheckboxChange}
                />
              </div>
              <div>
                <FCheckbox
                  label='Source of Income'
                  name='source_of_income'
                  value={selectedDocs.source_of_income}
                  setValue={handleCheckboxChange}
                />
              </div>
              <div>
                <Btn
                  attrBtn={{
                    className: 'btn-block',
                    color: 'primary',
                    type: 'submit', // Set type to 'submit' to submit the form
                  }}
                >
                  Send Email
                </Btn>
              </div>
            </Card>
          </form>
          <Card className='overflow-y p-4'>
          {
            emialFlag.data && emialFlag.data.map((data) => (
              data.type === `emailSend to ${customerId}` &&
              <p className='text-start mb-0'>{data.content.note + " at " + formatTimeInDubaiZone(data.createdAt)}</p>
            ))
          }
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
export default Documents;
