import React, { useState, Fragment, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Check, Trash2, Edit } from "react-feather";
import { useParams } from "react-router-dom";
import { Table } from "reactstrap";
import { userService, ticketService, accountService } from "Services";
import { useSelector } from "react-redux";

import TablePagination from "CommonElements/Table/WithoutFilterPagination";

const TodoList = (props) => {
  const { data = {} } = props;
  const pathParams = useParams();
  const customerIds = pathParams.id;
  const [users, setUsers] = useState(null);
  const [assignToList, setAssignToList] = useState([]);
  const [ticketList, setTicketList] = useState([]);
  const [clientType, setClientType] = useState('');
  const [clientName, setClientName] = useState('');
  const [requestOwner, setRequestOwner] = useState('');
  const [modifiedBy, setModifiedBy] = useState('');
  const [accountStatus, setAccountStatus] = useState('');
  const [accountNo, setAccountNo] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [submitState, setSubmitState] = useState({
    loading: false,
  });
  const userProfile = useSelector(
    (state) => (state.login && state.login.profile) || {}
  );
  const [formData, setFormData] = useState({
    // Initialize your form data here
    login: '',
    // other fields...
  });
  useEffect(() => {
    if (userProfile.firstName && userProfile.lastName) {
      // Assuming userProfile has firstName and lastName properties
      setRequestOwner(userProfile.firstName + ' ' + userProfile.lastName);
      setModifiedBy(userProfile.firstName + ' ' + userProfile.lastName);
    }
  }, [userProfile]); // This effect will run whenever userProfile changes
  const [accounts, setAccount] = useState([]);

  const [rec, setRec] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
    reset,
  } = useForm();
  useEffect(() => {
    const formFields = getValues();
    Object.keys(formFields).forEach((key) => {
      if (data[key]) {
        setValue(key, data[key]);
      }
    });


    getUserList();
    getTicketList(1);
  }, []);

  const getUserList = () => {
    var limit = 100;
    userService
      .getUsers({
        limit,
      })
      .then((res) => {
        console.log('users data is ', res.data);
        setUsers(res.data);
      })
      .catch((err) => {
        console.log("err getUser ", err);
      });
  };


  const getTicketList = (page = 1) => {
    setRec({
      loading: true,
      data: [],
    });
    ticketService
      .getTickets({ page })
      .then((res) => {
        console.log('Tickets are ', res);
        const formattedData = res.data.filter((item) => item.customerId == customerIds)
          .map((ticket) => ({
            ...ticket,
          }));
        setRec({
          loading: false,
          ...res,
        });
      })
      .catch((err) => {
        console.log("err getTickets ", err);
      });
  };
  const filteredData = rec.data && rec.data.filter((item) => item.customerId === customerIds);

  useEffect(() => {
    if (users !== null && users !== undefined && users.length > 0) {
      var optionArr = [];
      users.map((opt) =>
        optionArr.push({
          name: opt.firstName + " " + opt.lastName + " - " + opt["Role.title"],
          value: opt.id,
        })
      );
      setAssignToList(optionArr);
      console.log("optionArr" + optionArr);
    }
  }, [users]);

  const tableRef = useRef(null); // Create a ref for the table
  const editBtn = (e, rowData) => {
    e.preventDefault();
    if (tableRef.current) {
      tableRef.current.scrollIntoView({
        behavior: 'smooth', // Use 'auto' for instant scrolling
        block: 'start', // Scroll to the top of the table
      });
    }
    if (rowData !== undefined && rowData !== null && rowData.id !== 0) {
      const formFields = getValues();
      setSelectedRow(rowData);
      console.log('edited data is ', rowData);
      setAccountNo(rowData.tag_tp_acc_no);
      Object.keys(formFields).forEach((key) => {
        if (rowData[key]) {
          if (key === "startTime") {
            var newDate = new Date(rowData[key]);
            const dateOptions = {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            };
            const formattedDate = newDate.toLocaleDateString(
              "en-CA",
              dateOptions
            );
            setValue(key, formattedDate);
          } else {
            setValue(key, rowData[key]);
          }
        }
        setAddTask(" visible");
        document.getElementById("addTaskBtn").classList.add("hide");
      });
    }
  };
  const resetTicketData = () => {
    clearErrors();
    reset({
      request_owner: "",
      request_mode: "",
      request_category: "",
      request_type: "",
      request_status: "",
      assignTo: "",
      sub_type: "",
      description: "",
      priority: "",
      modified_by: "",
      tag_c_type: "",
      tag_c_name: "",
      tag_c_acc_status: "",
      tag_tp_acc_no: "",
      tag_ib_name: "",
      tag_ib_no: "",
    });
    setSelectedRow(null);
  };
  const objectReturn = (data) => {
    return {
      request_owner: requestOwner,
      request_mode: data.request_mode,
      request_category: data.request_category,
      request_type: data.request_type,
      request_status: data.request_status,
      assignTo: data.assignTo,
      sub_type: data.sub_type,
      action: data.action,
      description: data.description,
      priority: data.priority,
      modified_by: modifiedBy,
      tag_c_type: clientType,
      tag_c_name: clientName,
      tag_c_acc_status: accountStatus !== null ? accountStatus : "",
      tag_tp_acc_no: accountNo,
      tag_ib_name: "",
      tag_ib_no: "",
    };
  };
  const onSubmit = (data) => {
    var payload = objectReturn(data);
    console.log('data is', payload);
    if (payload !== "" && selectedRow == null) {
      setSubmitState({ loading: true });
      const formData = new FormData();

      // Assuming data is an object containing file data
      Object.keys(data).forEach((fieldKey) => {
        if (fieldKey === "PROOF_OF_ID" && data[fieldKey]) {
          // If data[fieldKey] is an array (multiple files), loop through it
          for (let i = 0; i < data[fieldKey].length; i++) {
            const fileData = data[fieldKey][i];
            formData.append("fileUrl", fileData);
          }
        }
      });

      // Append payload to formData
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });

      console.log('combined form data is', formData);

      ticketService.postTicketImage(formData)
        .then((res) => {
          setSubmitState({
            loading: false,
            status: 'Documents uploaded successfully',
          });

          getTicketList();
          closeTaskWrapper();
          toast.success("Ticket Added Successfully");
        })
        .catch((err) => {
          setSubmitState({
            loading: false,
            status: 'Error in uploading documents',
          });

          toast.error("Ticket Not Added");
        });
    } else {
      const formData = new FormData();

      // Assuming data is an object containing file data
      Object.keys(data).forEach((fieldKey) => {
        if (fieldKey === "PROOF_OF_ID" && data[fieldKey]) {
          // If data[fieldKey] is an array (multiple files), loop through it
          for (let i = 0; i < data[fieldKey].length; i++) {
            const fileData = data[fieldKey][i];
            formData.append("fileUrl", fileData);
          }
        }
      });

      // Append payload to formData
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });

      console.log('combined form data is for updation', formData);

      // Pass payload as the first argument to updateTicket
      updateTicket(formData, selectedRow.id);
    }
  };

  const updateTicket = (formData, selectedRowId) => {
    ticketService
      .updateTicket(formData, selectedRowId)
      .then((res) => {
        getTicketList();
        toast.success("Ticket Update Successfully");
        closeTaskWrapper();
      })
      .catch((err) => {
        setSubmitState({
          loading: false,
        });
        toast.error("Ticket Not Updated ");
      });
  };

  const [addTask, setAddTask] = useState("");
  const border_danger = useState("");
  const [status, setStatus] = useState("pending");

  const handleRemoveTodo = (todoId) => {
    const confirmed = window.confirm('Are you sure you want to delete this item?');
    if (confirmed) {
      ticketService
        .deleteTicket(todoId)
        .then((res) => {
          getTicketList();
          setTicketList(ticketList.filter((data) => data.id !== todoId));
          toast.success("Deleted Ticket !");
        })
        .catch((err) => {
          setSubmitState({
            loading: false,
          });
          toast.error("Ticket Not Delete ");
        });
    }
  };
  const findObjectIndex = (id) => {
    ticketList.findIndex(function (c) {
      return c.id === id;
    });
  };
  const updateObject = (id, newData) => {
    const index = findObjectIndex(id);
    if (index === -1) return;
    const updatedList = [...ticketList];
    updatedList[index] = { ...updatedList[index], ...newData };
    setTicketList(updatedList);
  };
  const handleMarkedTodo = (itemId, itemStatus) => {
    const confirmed = window.confirm('Are you sure you want to delete this item?');
    if (confirmed) {
      var data = rec.data.filter((obj) => {
        return obj.id === itemId;
      });
      var updateStatus = data[0].request_status === "completed" ? "pending" : "completed";
      data[0].request_status = updateStatus;
      var updatedRecord = data[0];
      updateObject(updatedRecord.id, updatedRecord);
      var payloadObj = objectReturn(updatedRecord);
      updateTicket(payloadObj, updatedRecord.id);
      if (updateStatus === "completed") {
        setStatus("pending");
        toast.success("Task Completed !");
      } else if (updateStatus === "pending") {
        setStatus("completed");
        toast.error(" Task In-completed !");
      }
    }
  };
  const openTaskWrapper = () => {
    if (selectedRow !== null) {
      resetTicketData();
    }
    setAddTask(" visible");
    document.getElementById("addTaskBtn").classList.add("hide");
  };
  const closeTaskWrapper = () => {
    resetTicketData();
    setAddTask("");
    document.getElementById("addTaskBtn").classList.remove("hide");
  };
  console.log(assignToList);


  const categories = [
    { name: 'Finance' },
    { name: 'Onboarding' },
    { name: 'Operation' }
  ];




  const [hiddenDetailsIndex, setHiddenDetailsIndex] = useState(null);
  const toggleDetails = (index) => {
    console.log('index', index);
    if (hiddenDetailsIndex === index) {
      // If the same row is clicked again, hide its details
      setHiddenDetailsIndex(null);
    } else {
      // Otherwise, show the details of the clicked row
      setHiddenDetailsIndex(index);
    }
  };




  // Define state for selected category and type
  const [selectedCategory, setSelectedCategory] = useState("Finance");
  const [selectedType, setSelectedType] = useState("");
  const [searchQuery, setSearchQuery] = useState('');

  const handleCategoryChange = (name, value) => {
    setValue(name, value);
    //setSelectedCategory(value);
    console.log(selectedCategory);
    if (name === 'request_category') {
      setSelectedCategory(value);
    }
  }
  const handleChange = (e) => {
    setSelectedFile(e.target.files[0]);
  }
  const getUrl = async (item, num = 1) => {
    ticketService.getDocumentSas(item).then(res => {
      console.log('res ', res);
      if (res && res.url) {
        window.open(res.url, '_blank')
      } else {
        toast.error('Failed to generate Link');
      }

    }).catch(err => {
      toast.error('Failed to generate Link');

    })
    return false;
  }

  const priorityColors = {
    High: 'high-priority',
    Normal: 'normal-priority',
    Low: 'low-priority',
  };

  return (
    <Fragment>
      <div className="table-responsive">
        <Table hover striped>
          <thead>
            <tr>
              <th scope="col">{"Ticket No"}</th>
              <th scope="col">{"Category"}</th>
              <th scope="col">{"Request"}</th>
              <th scope="col">{"Assign To"}</th>
              <th scope="col">{"Account No"}</th>
              <th scope="col">{"Priority"}</th>
              <th scope="col">{"Status"}</th>
              <th scope="col">{"Action"}</th>
            </tr>
          </thead>
          <tbody class="tableBody">
            {rec.data && rec.data.length > 0 ? (
              rec.data
                .filter((item) => item.customerId == customerIds) // Apply the filter here
                .map((item, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <th scope="row">{item.id}</th>
                      <td>{item.request_category}</td>
                      <td>{item.request_type}</td>
                      <td>{item["assigned.firstName"] + " " + item["assigned.lastName"]}</td>
                      <td>{item.tag_tp_acc_no}</td>
                      <td>
                        <span className={priorityColors[item.priority] || 'inherit'}>
                          {item.priority}
                        </span></td>
                      <td>{item.request_status}</td>
                      <td>
                        <span
                          className="action-box large delete-btn"
                          title="Edit Task"
                          onClick={(e) => editBtn(e, item)}
                        >
                          <Edit />
                        </span>
                        <span
                          className="action-box large delete-btn"
                          title="Delete Task"
                          onClick={() => handleRemoveTodo(item.id)}
                        >
                          <Trash2 />
                        </span>
                        <span
                          className="action-box large complete-btn"
                          title="Mark Complete"
                          onClick={() => handleMarkedTodo(item.id, status)}
                        >
                          <Check />
                        </span>
                        <span
                          className='fa fa-eye toggle-details'
                          style={{
                            position: 'absolute',
                            cursor: 'pointer',
                            marginLeft: '10px',
                          }}
                          onClick={() => toggleDetails(index)}
                        >
                        </span>
                      </td>
                    </tr>
                    {hiddenDetailsIndex === index && (
                      <tr>
                        <td colSpan="12"> {/* Adjust the colspan to match the number of columns in your main table */}
                          <table width="100%">
                            <tbody>
                              <tr>
                                <td><strong>Request Owner:</strong> {item.request_owner}</td>
                                <td><strong>Request Mode:</strong> {item.request_mode}</td>
                                <td><strong>Priority:</strong> {item.priority}</td>
                              </tr>
                              <tr>
                                <td><strong>Modified By:</strong> {item.modified_by}</td>
                                <td><strong>Account Type:</strong> {item.tag_c_type}</td>
                                <td><strong>Client Name:</strong> {item.tag_c_name}</td>
                              </tr>
                              <tr>
                                <td><strong>Account Status:</strong> {item.tag_c_acc_status}</td>
                                <td><strong>Created At:</strong> {new Date(item.createdAt).toLocaleDateString()}</td>
                                <td><strong>Modified At:</strong> {new Date(item.updatedAt).toLocaleDateString()}</td>
                              </tr>
                              <tr>
                                <td><strong>Sub Type:</strong> {item.sub_type}</td>
                                <td><strong>Description:</strong> {item.description}</td>
                              </tr>
                              {item.imageUrl ?
                                <tr>
                                  <td><p className='m-0 text-decoration-underline' style={{ cursor: 'pointer' }} onClick={() => { getUrl(item, 1) }} target='_blank' rel="noreferrer">Attachment</p></td>
                                </tr> : ''}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))
            ) : (
              ''
            )}
          </tbody>

        </Table>
        <TablePagination data={filteredData} loadData={getTicketList} />
      </div>

    </Fragment>
  );
};
export default TodoList;
