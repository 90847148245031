import api from './api';
var queryString = (params) => {
  return Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
};
export const getIbs = (params) => {
  return api.get('/v1/crm/customers/ibs?' + queryString(params));
};
export const updateIb = (id, params) => {
  return api.patch('/v1/crm/customers/ib/' + id, params);
};
export const deleteBulkIbs = (data) => {
  return api.delete('/v1/crm/customers/ibs', { data }, {});
};
export const postIbs = (params, token) => {
  return api.post('/v1/crm/customers/ib', params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAgreements = (ibId=0) => {
  return api.get('/v1/crm/ib/agreements?' + queryString({ibId}));
};

export const getStatement = (params) => {
  console.log('get data statement',params);
  return api.get('/v1/crm/ib/statement?' + queryString(params));
};

export const getExportStatement = (params) => {
  console.log('get data statement export',params);
  return api.get('/v1/crm/ib/export-statement?' + queryString(params));
};

export const getExportStatement2 = (params) => {
  console.log('get data statement export',params);
  return api.get('/v1/crm/ib/export-statement2?' + queryString(params));
};

export const getStatementDealWise = (params) => {
  console.log(params, 'get Statement is');
  return api.get('/v1/crm/ib/statement-deal-wise?' + queryString(params));
};

export const makeIb = (customerId) => {
  return api.post('/v1/crm/ib/make-ib', {customerId});
};

export const rejectIb = (customerId) => {
  return api.post('/v1/crm/ib/reject-ib', {customerId});
};

export const getHierarchy = (ibId) => {
  return api.get('/v1/crm/ib/hierarchy?ibId='+ibId);
};

export const addAgreement = (params) => {
  console.log('params are');
  return api.post('/v1/crm/ib/agreement', params);
};

export const deleteAgreement = (id) => {
  return api.delete('/v1/crm/ib/agreement/'+id);
};

export const getClients = (params) => {
  return api.get('/v1/crm/ib/clients?' + queryString(params));
};

export const getClientsAccount = (params) => {
  return api.get('/v1/crm/ib/clientsAccount?' + queryString(params));
};

export const unlinkClient = (id) => {
  return api.post('/v1/crm/ib/unlink-client', {id});
};

export const linkClient = (params) => {
  return api.post('/v1/crm/ib/link-client', params);
};

export const getGroupMapping = (ibId=0) => {
  return api.get('/v1/crm/ib/groups?' + queryString({ibId}));
};

export const postGroupMapping = (ibId, groups) => {
  return api.post('/v1/crm/ib/groups', {ibId, groups});
};

export const setGroupMapping = (ibId, groups) => {
  return api.patch('/v1/crm/ib/groups', {ibId, groups});
};

export const getdealForRebate = (params) => {
  console.log('get data',params);
  return api.get('/v1/crm/ib/dealForRebate?' + queryString(params));
};

export const searchCustomers = (params) => {
  const { page, limit, ...searchValue } = params;
  const queryString = `page=${page}&limit=${limit}`;
  return api.post(`/v1/crm/customers/search-customers?${queryString}` , searchValue);
};