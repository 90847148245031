import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row, Form, Card, CardHeader, CardBody } from 'reactstrap';
import { useSelector } from 'react-redux';
import { ibService, clientService } from 'Services';
import TableLoader from 'CommonElements/Table/TableLoader';
import TableNodata from 'CommonElements/Table/TableNodata';
import AddAgreement from './AddAgreement';
import DeleteAgreement from './DeletAgreement';
import { getMenuPerm } from 'utils';
import MakeIb from './MakeIb';
import RejectIb from './RejectIb';
import { useForm } from 'react-hook-form';
import { FInput, FSelect } from 'CommonElements/Form';
import SweetAlert from 'sweetalert2';

import ViewAgreement from 'Components/Common/Component/ViewAgreement';
import GroupSetting from './GroupSetting';
import { Btn } from 'AbstractElements';

const Agreements = (props) => {
  const {
    customerId = '',
    activeTab,
    tabNumber,
    data = {},
    loadClientProfile
  } = props;
  const [rec, setRec] = useState({});
  const [deal, setDeal] = useState('');
  const [submitState, setSubmitState] = useState({
    loading: false,
});

  const permissions = useSelector(
    (state) =>
      (state.login &&
        state.login.profile &&
        state.login.profile.Role &&
        state.login.profile.Role.permissions) ||
      {}
  );
  const userProfile = data;
  // console.log("userProfile is: ", userProfile.entiry);
  const signupLink = `https://signup.godocm.com?ptr=${userProfile.id}&rm=${userProfile.agentId}${(userProfile && userProfile.entity === 'FSA_MA') ? '&entity=FSA_MA&utm_medium=godoweb_ma' : ''}`;
  const demoLink = `https://signup-demo.godocm.com?ptr=${userProfile.id}&rm=${userProfile.agentId}${(userProfile && userProfile.entity === 'FSA_MA') ? '&entity=FSA_MA&utm_medium=godoweb_ma' : ''}`;
  const partnerLink = `https://signup-partner.godocm.com?ptr=${userProfile.id}&rm=${userProfile.agentId}${(userProfile && userProfile.entity === 'FSA_MA') ? '&entity=FSA_MA&utm_medium=godoweb_ma' : ''}`;
  useEffect(() => {
    if (activeTab === tabNumber) {
      loadData();
    }
  }, [activeTab, tabNumber]);

  const loadData = () => {
    ibService
      .getAgreements(customerId)
      .then((res) => {
        setRec({
          ...res,
          loading: false,
        });
      })
      .catch((err) => { });
  };

  const { register, handleSubmit, formState: { errors }, setValue, getValues, control } = useForm();

  const handleFullName = (e) => {
    setDeal(e.target.value);
    setValue('deal', e.target.value);
  };

  const onSubmit = (data) => {
    console.log('data: ', data);

    if (data !== '') {
      clientService.updateClient(customerId, data).then(res => {
        setSubmitState({
          loading: false,
          status: 'Profile updated sucessfully'
        })
        SweetAlert.fire({ title: 'Success', text: 'Profile updated sucessfully!', icon: 'success' });
      }).catch(err => {
        setSubmitState({
          loading: false,
          status: 'Error in updating Profile'
        })
        SweetAlert.fire({ title: 'Failed', text: err.response.data.message, icon: 'error' });
      });
    } else {
      errors.showMessage();
    }
  };

return (
  <Fragment>
    <Card>
      <CardHeader className='p-3'>
        <Row>
          <Col md={4} className='d-flex'>
            Commission Structure
          </Col>
          <Col md={8} className='d-flex'>
            <div
              style={{ display: 'block', textAlign: 'right', width: '100%' }}
            >
              {(data?.ibStatus === null || data?.ibStatus === 'pending') && (
                <>
                  {getMenuPerm(permissions, 'ibs:create') === 1 && (
                    <React.Fragment>
                      <MakeIb customerId={customerId} loadData={loadData} />
                      <RejectIb customerId={customerId} loadData={loadData} />
                    </React.Fragment>
                  )}
                </>
              )}
              {/* Need to remove after work 
                <MakeIb customerId={customerId} loadData={loadData} /> &nbsp;*/}

              {data?.ibStatus === 'approved' &&
                !rec.loading &&
                rec?.data?.length === 0 && (
                  <>
                    {getMenuPerm(permissions, 'ibs:create') === 1 && (
                      <AddAgreement
                        customerId={customerId}
                        loadData={loadData}
                      />
                    )}
                  </>
                )}
              {/* Need to remove after work 
                  <AddAgreement
                      customerId={customerId}
                      loadData={loadData}
                    />*/}
              {!rec.loading &&
                rec?.data?.length > 0 && (
                  <>
                    {getMenuPerm(permissions, 'ibs:create') === 1 && (
                      <DeleteAgreement
                        customerId={customerId}
                        loadData={loadData}
                        agreementId={rec?.data[0]?.id}
                      />
                    )}
                  </>
                )}
            </div>
          </Col>
        </Row>
      </CardHeader>

      <CardBody>
        {rec.loading && <TableLoader />}
        {!rec.loading && rec.data && rec.data.length === 0 && (
          <TableNodata title='Commission Structure' />
        )}
        {!rec.loading && rec.data && rec.data.length > 0 && (
          <Row>
            <Col sm={6}>
              <ViewAgreement agreement={rec.data[0]} />
            </Col>
            <Col sm={6}>
              <Row>
                <Col sm={12}>
                  <Card>
                    <CardHeader className='p-3'>Dedicated Links</CardHeader>
                    <CardBody>
                      <p className='mb-0 ml-2'>
                        Register Demo &nbsp;{' '}
                        <a className='pl-3' href={demoLink}>
                          Demo
                        </a>
                      </p>
                      <p className='mb-0 ml-2'>
                        Register Live &nbsp;&nbsp;&nbsp;{' '}
                        <a className='pl-3' href={signupLink}>
                          Live
                        </a>
                      </p>
                      <p className='mb-0 ml-2'>
                        Register Sub Ib{' '}
                        <a className='pl-3' href={partnerLink}>
                          Sub IB
                        </a>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            {/* <Col md={12}>
              <Row>
                <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md={6}>
                      <FSelect
                        control={control}
                        errors={errors}
                        label='IB Type'
                        name='ibType'
                        register={register}
                        //placeholder= 'Enter Lead Status'

                        options={[
                          { name: 'NTP', value: 'NTP' },
                          { name: 'Rebate', value: 'Rebate' },
                        ]}

                        validation={{ required: true }}
                        value={data.callStatus}
                        setValue={setValue}
                      />
                    </Col>
                    <Col md={6} >
                      <FInput
                        errors={errors}
                        label='Deal'
                        name='deal'
                        value={deal}
                        handleOnChange={handleFullName}
                        register={register}
                        //placeholder= 'Enter First Name'
                        validation={{ required: true }}
                      />
                    </Col>
                  </Row>
                  <Btn disabled={submitState.loading} attrBtn={{ color: 'primary' }} >{'Update'}</Btn>
                </Form>
              </Row>
            </Col> */}
            <Col sm={12} style={{ marginTop: "10px"}}>
              {getMenuPerm(permissions, 'ibs:update') === 1 && (
                <GroupSetting
                  customerId={customerId}
                  activeTab={activeTab}
                  tabNumber={tabNumber}
                />
              )}
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  </Fragment>
);
};
export default Agreements;