
import React, { Fragment } from "react";

import SweetAlert from 'sweetalert2';
import { toast } from "react-toastify";

import { ibService } from 'Services';
import TableRowDelete from 'CommonElements/Table/TableRowDelete';


const DeleteAgreement = ({ data={}, loadData, agreementId='' }) => {


  const deleteAgreement = () => {
    SweetAlert.fire({
        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover this record!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            ibService.deleteAgreement(agreementId).then(res => {
                toast.success('Agreement delete, successfully');
                loadData();
            }).catch(err => {
                toast.error('Failed to delete Agreement');

            })
        }
    });
};
  return (
    <Fragment>
        <TableRowDelete onClick={deleteAgreement} />

    </Fragment>
  );
};

export default DeleteAgreement;
