import React, { Fragment, useEffect, useState } from "react";
import ReportsList from "./ReportsList";
import { Breadcrumbs } from "../../../AbstractElements";
import SearchForm from "./SearchForm";
import { Col, Card, CardHeader, Container, Row } from "reactstrap";
import { Reports } from "../../../Constant";
import { H5 } from "../../../AbstractElements";
import { reportService } from 'Services';
import { toast } from 'react-toastify';
import { appendParameterToUrl } from "Components/Common/Utility";
const BasicTables = () => {
  const [reportData, setReportData] = useState([]);
  const [formValues, setFormValues] = useState({})

  const [submitState, setSubmitState] = useState({
    loading: false,
  });
  // Function to append fromDate and toDate
  const appendDateParameters = (fromDate, toDate) => {
    // Convert Date objects to ISO string format
    const fromDateStr = fromDate.toISOString();
    const toDateStr = toDate.toISOString();

    // Append the parameters to the URL
    appendParameterToUrl('fromDate', fromDateStr);
    appendParameterToUrl('toDate', toDateStr);
  };

  const onSearchSubmit = (data) => {
    if (data !== '') {
      setFormValues(data);
    }
    console.log("Data for search is ", data);
    appendDateParameters(data.fromDate, data.toDate);
  };

  const loadData = async (page = 1) => {

    setSubmitState({ loading: true });
    const report = {
      dateFrom: formValues.fromDate,
      dateTo: formValues.toDate,
      page,
    };
    reportService
      .getReports(report, formValues.report)
      .then((res) => {
        setSubmitState({
          loading: false,
        });
        if (res.data != null) {
          setReportData(res);
        }
      })
      .catch((err) => {
        setSubmitState({
          loading: false,
        });
        toast.error('Deposit Failed ');
      });

  }

  useEffect(() => {
    if (formValues && formValues.report) {
      loadData();
    }
  }, [formValues])




  return (
    <Fragment>
      <Breadcrumbs title="Reports" mainTitle="" />
      <Container fluid={true}>
        <Col sm="12">
          <Card style={{ minHeight: '35rem' }}>
            <CardHeader className="p-4">
              <Row>
                <Col md="8">
                  <H5>{Reports}</H5>
                </Col>
              </Row>
            </CardHeader>
            <SearchForm onSearchSubmit={onSearchSubmit} formValues={formValues} reportData={reportData} setFormValues={setFormValues} />
            <ReportsList submitState={submitState} reportData={reportData} formValues={formValues} loadData={loadData} />
          </Card>
        </Col>
      </Container>
    </Fragment>
  );
};

export default BasicTables;
