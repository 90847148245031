import api from "./api";
var queryString = (params) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};
export const getGroupMembers = (params={}) => {
  return api.get("v1/crm/group-member?" + queryString(params));
};

export const deleteGroupMember = (id) => {
  return api.delete("v1/crm/group-member/" + id);
};

export const addGroupMember = (params) => {
  console.log('details ',params);
  return api.post("v1/crm/group-member", params);
};

export const updateGroupMember = (id, params) => {
  return api.patch("v1/crm/group-member/"+id, params);
};
