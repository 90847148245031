import api from './api';
var queryString = (params={}) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};
export const getRoles = (params)=> {
    return api.get('/v1/crm/roles?' + queryString(params))
}
export const deleteRole = (id)=> {
  return api.delete('/v1/crm/roles/'+ id)
}
export const addRole = (params)=> {
  return api.post('/v1/crm/roles/', params)
}
export const updateRole = (id, params)=> {
  return api.patch('/v1/crm/roles/'+id, params)
}