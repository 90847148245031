import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, InputGroup, ListGroup } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { FInput, FSelect, FCheckbox, FUserSelect } from 'CommonElements/Form';
import { authService } from "Services";
import { toast } from "react-toastify";


const EditprofileForm = () => {
  const userProfile = useSelector(
    (state) => (state.login && state.login.profile ) || {}
  );
      
      const [loading, setLoading] = useState(false);

      const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        getValues
      } = useForm();
      const modalSubmit = (formValues) => {
        setLoading(true);
        // authService.updateProfile(formValues).then(res => {
        //     setLoading(false);
        //     toast.success('Profile Updated, successfully');

        // }).catch(err => {
        //     setLoading(false);
        //     toast.error('Failed to update Profile');

        // })
      };

      useEffect(()=>{
        const formFields = getValues();
        Object.keys(formFields).forEach(key => {
          if(userProfile[key]) {
            setValue(key, userProfile[key])
          }
        })
      }, [userProfile])

    return (<div>
         <Card>
         <Form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit(modalSubmit)}
            >
          <Card.Header>
            <Card.Title as="h3">My Profile</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>

                <Row>
                    <Col md="12 mb-3">
                        <FInput
                            errors={errors}
                            label = 'First Name'
                            name = 'firstName'
                            register = {register}
                            placeholder= 'Enter First Name'
                            validation={{required: true}}
                            disabled={true}
                        />
                    </Col>
                    <Col md="12 mb-3">
                        <FInput
                            errors={errors}
                            label = 'Last Name'
                            name = 'lastName'
                            register = {register}
                            placeholder= 'Enter Last Name'
                            validation={{required: true}}
                            disabled={true}
                        />
                    </Col>
                    <Col md="12 mb-3">
                        <FInput
                            errors={errors}
                            label = 'Phone'
                            name = 'phone'
                            register = {register}
                            placeholder= 'Enter Phone Number'
                            validation={{required: true}}
                            disabled={true}
                        />
                    </Col>
                    <Col md="12 mb-3">
                        <FInput
                            errors={errors}
                            label = 'Email'
                            name = 'email'
                            register = {register}
                            placeholder= 'Enter Email Address'
                            validation={{required: true}}
                            disabled={true}
                        />
                    </Col>
                   
                  
                    <Col md={12} className="d-flex flex-row-reverse center">
                    
                    </Col>
                </Row>

                {/* <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn> */}
            </Row>
            
          </Card.Body>
          <Card.Footer className="text-end">
            {/* <Btn disabled={dis} attrBtn={{ color: "primary" }}>
                {"Update"}
            </Btn> */}
          </Card.Footer>
          </Form>
        </Card>
    </div>)
}
export default EditprofileForm;
