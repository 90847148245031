import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import { Fragment } from 'react';
import { useSelector } from "react-redux";
import { Breadcrumbs } from '../../../../AbstractElements';
//import SummaryEarnings from '../Default/SummaryEarnings';
// import ChartDataRight from '../Default/ChartDataRight';
import Transactions from '../Default/Transactions/ApexChart';
//import Requests from '../Default/Requests/ApexChart';
import Customers from '../Default/Customers/ApexChart';
import TasksToDo from '../Default/Transactions/tasksToDo';
import TableLoader from "CommonElements/Table/TableLoader";
import { dashboardService, customerNotesService } from "Services";

const Dashboard = () => {
  const [rec, setRec] = useState({});
  const [notes, setNotes] = useState({});

  useEffect(() => {
    loadData();
    loadNotes();
  }, []);
  const loadNotes = () => {
    customerNotesService
      .getCustomerNotes({ limit: 10000 })
      .then((res) => {
        setNotes({
          ...res,
          loading: false,
        });
      })
      .catch((err) => {
        console.log('err ', err);
      });
  };
  const loadData = () => {
    setRec({
      loading: true,
      data: [],
      accounts: null,
      ibAccounts: null,
      deposits: null,
      withdrawals: null,
      depositsFtd: null,
      depositsSales: null,
    });
    Promise.all([
      dashboardService.getAccountsCount(),
      dashboardService.getIbAccountsCount(),
      dashboardService.getDepositsCount(),
      dashboardService.getWithdrawalsCount(),
      dashboardService.getDepositsFtd(),
      dashboardService.getDepositsBySales(),
    ]).then(([accounts, ibAccounts, deposits, withdrawals, depositsFtd, depositsSales]) => {
      setRec({
        loading: false,
        accounts, ibAccounts, deposits, withdrawals, depositsFtd, depositsSales
      });
      console.log("First Time Deposit is ", depositsFtd);
    }).catch(err => {
      console.log("Error is Test", err);
    })

  }
  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  );
  
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Dashboard" />
      {rec.loading && <TableLoader />}
      {!rec.loading && (
        <Container fluid={true} className="dashboard-default-sec">
          <Row className="second-chart-list third-news-update">
            {permissions && permissions.dashboard && permissions.dashboard.view &&
              <>
                <Customers {...rec} />
                <Transactions {...rec} show={!permissions.dashboard.delete} />
              </>
            }
            {permissions && permissions.tasksToDo && permissions.tasksToDo.view &&
              <TasksToDo notes={notes} />
            }
          </Row>
        </Container>
      )}
    </Fragment>
  );
};

export default Dashboard;
