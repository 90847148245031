import React, { Fragment, useEffect, useState } from 'react';
import { Btn } from '../../../../AbstractElements';
import { useForm } from 'react-hook-form';
import { FSelect } from 'CommonElements/Form';
import CommonModal from '../../../Common/Data/Ui-kits/Modals/common/modal';
import { userService, clientService, leadService } from 'Services';
import { toast } from 'react-toastify';
import SelectedSalesCard from './SelectedSales/index';
import { ModalFooter, Form, FormGroup, Col } from 'reactstrap';
import { Close } from '../../../../Constant';
import { FUserSelect } from 'CommonElements/Form';

const UserFormModal = (
  {
    modal,
    toggle,
    setModal,
    selectedSales,
    checkBoxClear,
    loadLeadData,
    titleMessage,
  },
  props
) => {
  const { data = {} } = props;
  const [salesUserList, setSalesUserList] = useState([]);
  const [isLeadAssign, setIsLeadAssign] = useState(true);
  const [submitState, setSubmitState] = useState({
    loading: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm();
  useEffect(() => {
    if (titleMessage == 'Delete Leads') {
      setIsLeadAssign(false);
    } else {
      setIsLeadAssign(true);
    }
  }, [titleMessage]);
  useEffect(() => {
    if (isLeadAssign) {
      userService
        .getUsers({ limit: 100 })
        .then((res) => {
          if (res.data.length > 0) {
            debugger;
            var salesData = res.data
              .filter((f) => f.roleId === 3 || f.roleId === 4)
              .map((x) => {
                return {
                  value: x.id,
                  name: x.firstName + ' ' + x.lastName,
                };
              });
            setSalesUserList(salesData);
          } else {
            // toast.error("No Role Found ");
          }
        })
        .catch((err) => {
          setSubmitState({
            loading: false,
          });

          // toast.error("No Role Found ");
        });
    }
  }, []);
  const onSubmit = (data) => {
    if (data.saleUserId != null) {
      const postData = {
        agentId: data.saleUserId,
        customerIds: selectedSales.map((x) => x.id).toString(),
      };
      clientService
        .postBulkAssign(postData)
        .then((res) => {
          toast.success('Lead Assign Successfully');
          checkBoxClear();
          setModal(false);
          loadLeadData();
        })
        .catch((err) => {
          toast.error('Not Assign');
        });
    } else {
      const postData = {
        ids: selectedSales.map((x) => x.id),
      };
      leadService
        .deleteBulkLeads(postData)
        .then((res) => {
          toast.success('Lead Delete Successfully');
          checkBoxClear();
          setModal(false);
          loadLeadData();
        })
        .catch((err) => {
          toast.error('Not Delete');
        });
    }
  };
  return (
    <Fragment>
      <CommonModal
        isOpen={modal}
        title={titleMessage}
        toggler={toggle}
        isButtonShown={true}
      >
        <Form
          className='needs-validation'
          noValidate=''
          onSubmit={handleSubmit(onSubmit)}
        >
          {isLeadAssign ? (
            <FormGroup className='form-group row'>
              <Col md='12'>
                <FUserSelect
                  control={control}
                  errors={errors}
                  label='Select Agent'
                  name='saleUserId'
                  validation={{ required: true }}
                  setValue={setValue}
                  value={data.saleUserId}
                  defaultOptions={data.Agent ? [data.Agent] : []}

                  // isClient={true}
                />
                {/* <FSelect
                  control={control}
                  errors={errors}
                  label='Select Sales'
                  name='saleUserId'
                  register={register}
                  placeholder='Select Sales Person'
                  options={salesUserList}
                  validation={{ required: true }}
                  value={data.saleUserId}
                  setValue={setValue}
                /> */}
              </Col>
            </FormGroup>
          ) : (
            <div></div>
          )}
          <hr></hr>
          {selectedSales != null ? (
            <SelectedSalesCard salesData={selectedSales} />
          ) : null}
          <ModalFooter>
            <Btn
              attrBtn={{
                color: 'secondary',
                onClick: toggle,
                type: 'button',
              }}
            >
              {Close}
            </Btn>
            <Btn
              disabled={submitState.loading}
              attrBtn={{ color: 'primary' }}
              // onSubmit={() => handleMyFunction()}
            >
              {isLeadAssign ? 'Assign Leads' : 'Delete Leads'}
            </Btn>
          </ModalFooter>
        </Form>
      </CommonModal>
    </Fragment>
  );
};
export default UserFormModal;
