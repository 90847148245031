import React, { Fragment, useState } from 'react';
import { Label, FormGroup } from 'reactstrap';
import { Controller } from 'react-hook-form';
import { groupsService } from 'Services';
import AsyncSelect from 'react-select/async';
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';

const FormInput = ({
    errors = {},
    label = '',
    name = '',
    validation={},
    value: defValue,
    setValue = () => {},
    control,
    defaultOptions=[]
}) => {
  const [, setSelectedValue] = useState( null);

  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  );

  // handle selection
  const handleChange = e => {
    setSelectedValue(e);
    setValue(name, e.id);
  }
 
  // load options using API call
  const loadOptions = (inputValue) => {
    return new Promise(async(resolve , reject) => {
      return groupsService.getUsersDropdown(inputValue).then(res => {
        return resolve(res);
      }).catch(err => {
        resolve([])
      });
    })

  };
  return (
    <Fragment>
        <FormGroup>
            <Label className="form-label" for={name}>{label}</Label>
          {control && 
            <Controller
                  control={control}
                  defaultValue={defValue}
                  name={name}
                  id={name}
                  isSearchable={true}
                  isClearable
                  rules={validation}
                  render={({ 
                    field: { onChange, onBlur, value, name, ref }
                  }) => (
                    <AsyncSelect
                    // defaultInputValue={defValue && defValue.id}
                    cacheOptions
                    defaultOptions={defaultOptions}
                    isDisabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                    // value={selectedValue}
                    value={defaultOptions && defaultOptions.find(c => c.id === value)}
                    getOptionLabel={e => `${e.name}`}
                    getOptionValue={e => e.id}
                    loadOptions={loadOptions}
                    // onInputChange={handleUpdate}
                    onChange={(e)=>{onChange(e); handleChange(e)}}
                  />
                      
                  )}
              />
            }
            <span className='error'>{errors[name] && `${label} is ${errors[name].type}`}</span>
        </FormGroup>
    </Fragment>
  );
};
export default FormInput;
