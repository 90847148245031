// dateUtils.js

export const formatTimeInDubaiZone = (dateTimeString, isReminder) => {
  if (!dateTimeString || isReminder === 0) {
    return 'N/A';
  }

  const utcTime = new Date(dateTimeString);
  // const localTime = new Date(dateTimeString);
  // const dubaiTime = new Date(localTime);
  // dubaiTime.setUTCHours(localTime.getUTCHours() + 4); // Adding 4 hours to convert to Dubai time
  const dubaiTime = new Date(utcTime.getTime());

  return dubaiTime.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    //timeZoneName: 'short',
  });
};

export const addFourHours = (dateString) => {
  const date = new Date(dateString);
  date.setHours(date.getHours() + 4);
  return date.toISOString();
};

export const formatDateTime = (dateTimeString) => {
  const date = new Date(dateTimeString);

  const padZero = (num) => num.toString().padStart(2, '0');

  const formattedDate = `${padZero(date.getUTCDate())}/${padZero(date.getUTCMonth() + 1)}/${date.getUTCFullYear()} ${padZero(date.getUTCHours())}:${padZero(date.getUTCMinutes())}:${padZero(date.getUTCSeconds())}`;

  console.log(formattedDate);
  return formattedDate;
};

export const formatDate = (dateTimeString) => {
  const date = new Date(dateTimeString);

  const padZero = (num) => num.toString().padStart(2, '0');

  const formattedDate = `${padZero(date.getUTCDate())}/${padZero(date.getUTCMonth() + 1)}/${date.getUTCFullYear()}`;

  console.log(formattedDate);
  return formattedDate;
};

export const parseDateString = (dateString) => {
  const [datePart, timePart] = dateString.split(' ');
  const [day, month, year] = datePart.split('/').map(Number);
  const [hours, minutes, seconds] = timePart.split(':').map(Number);

  // Create a new Date object
  const dateObject = new Date(year, month - 1, day, hours, minutes, seconds);

  return dateObject;
};

export const formatMoney = (number, locale = 'en-US') => {
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(number);
}

export const appendParameterToUrl = (key, value) => {
  // Get the current URL
  const currentUrl = window.location.href;

  // Create a URL object
  const url = new URL(currentUrl);

  // Check if the parameter exists
  if (url.searchParams.has(key)) {
    // Update the existing parameter
    url.searchParams.set(key, value);
  } else {
    // Append the new parameter
    url.searchParams.append(key, value);
  }
  // Update the browser's address bar
  window.history.pushState(null, '', url.toString());
};

// Function to separate page and limit, and construct body params
export const getParamsAndBody = (url) => {
  const queryParams = extractUrlParams(url);

  const page = queryParams.page || 1;
  const limit = queryParams.limit || 25;
  // setLimit(limit);
  // Remove page and limit from queryParams to construct the body parameters
  delete queryParams.page;
  delete queryParams.limit;

  const bodyParams = queryParams;

  return {
    page,
    limit,
    bodyParams
  };
};

// Function to extract query parameters from the URL
const extractUrlParams = (url) => {
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);
  const queryParams = {};
  for (const [key, value] of params) {
    queryParams[key] = value;
  }

  return queryParams;
};