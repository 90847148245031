import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row, Form, Card, CardHeader, CardBody } from 'reactstrap';
import { useSelector } from "react-redux";
import { ibService } from 'Services';
import TableLoader from 'CommonElements/Table/TableLoader';
import { getMenuPerm } from 'utils';
import { FInput } from 'CommonElements/Form';
import { useForm } from 'react-hook-form';
import { Btn, H6 } from "AbstractElements";
import { toast } from "react-toastify";
import AddGroup from './AddGroup';



const GroupSetting = (props) => {
    const {
        customerId = '',
        activeTab,
        tabNumber,
    } = props;

    const permissions = useSelector(
        (state) =>
            (state.login &&
                state.login.profile &&
                state.login.profile.Role &&
                state.login.profile.Role.permissions) ||
            {}
    );

    const [rec, setRec] = useState({});
    //   const permissions = useSelector(
    //     (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
    //   );
    useEffect(() => {
        if (activeTab === tabNumber) {
            loadData();
        }
    }, [activeTab, tabNumber]);

    const loadData = () => {
        ibService.getGroupMapping(customerId).then((res) => {
            setRec({
                data: res,
                loading: false,
            })
        }).catch(err => {
        });
    }
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
    const modalSubmit = formValues => {
        if (formValues !== '' && formValues.data) {
            console.log("Group Values is ", formValues.data);
            ibService.setGroupMapping(customerId, formValues.data).then(res => {
                toast.success('IB groups updated, successfully');
                loadData();
            }).catch(err => {
                toast.error('Failed to update IB groups');

            });
        } else {
            errors.showMessages();
        }
    };

    useEffect(() => {
        if (rec && rec.data && rec.data.length > 0) {
            rec.data.forEach((obj, index) => {
                setValue(`data.${index}.accountTypeId`, obj.id);
                setValue(`data.${index}.groupPath`, obj.groupMapping?.groupPath ?? '');
                setValue(`data.${index}.groupPathSwapFree`, obj.groupMapping?.groupPathSwapFree ?? '');
                setValue(`data.${index}.leverage`, obj.groupMapping?.leverage ?? '');
            })
        }
    }, [rec])

    return (
        <Fragment>
            <Card>
                <CardHeader className='p-3'>
                    <Row>
                        <Col>
                            IB Group Setting
                        </Col>
                        <Col className='d-flex justify-content-end'>
                            {getMenuPerm(permissions, 'ibs:create') === 1 && (
                                <AddGroup
                                    customerId={customerId}
                                />
                            )}
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    {rec.loading && <TableLoader />}
                    {!rec.loading && rec.data && rec.data.length > 0 &&
                        <Row>
                            <Col sm={12}>

                                <Form className="needs-validation" noValidate=""
                                    onSubmit={handleSubmit(modalSubmit)}
                                >
                                    <Row>
                                        {rec.data.map((mapping, index) => ((mapping.title == 'Standard' || mapping.title == 'Standard MT4') && <>
                                            <React.Fragment key={index}>
                                                <Col md='4 mb-3' >
                                                    <FInput
                                                        name={'data.' + index + '.accountTypeId'}
                                                        register={register}
                                                        validation={{ required: true }}
                                                        type="hidden"
                                                    />
                                                    <FInput
                                                        errors={errors}
                                                        label={mapping.title + ' Group'}
                                                        name={'data.' + index + '.groupPath'}
                                                        register={register}
                                                        placeholder='Enter group Path'
                                                    />
                                                </Col>
                                                <Col md='4 mb-3' >
                                                    <FInput
                                                        errors={errors}
                                                        label={mapping.title + ' Group Swap Free'}
                                                        name={'data.' + index + '.groupPathSwapFree'}
                                                        register={register}
                                                        placeholder='Enter group Path'
                                                    />
                                                </Col>
                                                <Col md='4 mb-3' >
                                                    <FInput
                                                        errors={errors}
                                                        label={'Leverage'}
                                                        name={'data.' + index + '.leverage'}
                                                        register={register}
                                                        placeholder='Enter Leverage'
                                                    />
                                                </Col>
                                            </React.Fragment>
                                        </>))}
                                        <Col md={12} className="d-flex flex-row-reverse mt-4">
                                            <Btn disabled={rec.loading} attrBtn={{ color: 'primary', type: 'submit' }} >{'Update Groups'}</Btn>
                                        </Col>

                                    </Row>

                                    {/* <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn> */}
                                </Form>

                            </Col>
                        </Row>
                    }

                </CardBody>

            </Card>

        </Fragment>
    );
};
export default GroupSetting;