import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import LeadsList from './LeadsList';
import { Breadcrumbs } from '../../../AbstractElements';


const BasicTables = () => {
    return (
        <Fragment>
            <Breadcrumbs title="Leads" mainTitle='' />

            <Container fluid={true}>
                <Row>
                    <LeadsList />
                </Row>
            </Container>
        </Fragment>
    );
};

export default BasicTables;