import React from 'react';
import { Card, CardBody, Col, Table } from 'reactstrap';

const Index = ({ xlsxData }, props) => {
  return (
    <Col xl='12' className='appointment' style={{ border: '1px solid #eee' }}>
      <div className='table-responsive'>
        <Table hover striped>
          <thead>
            <tr>
              <th scope='col'>{'#'}</th>
              <th scope='col'>{'Name'}</th>
              <th scope='col'>{'Email'}</th>
              <th scope='col'>{'Phone'}</th>
            </tr>
          </thead>
          <tbody>
            {xlsxData &&
              xlsxData.map((item, id) => (
                <tr key={id}>
                  <th scope='row'>{item.id}</th>
                  <td>{`${item.firstName} ${item.lastName}`}</td>
                  <td>{item.email}</td>
                  <td>{item.phone}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </Col>
  );
};

export default Index;
