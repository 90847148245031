import api from "./api";
var queryString = (params) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};
export const getAccountTypes = (params={}) => {
  return api.get("v1/crm/account-types?" + queryString(params));
};

export const deleteAccountType = (id) => {
  return api.delete("v1/crm/account-types/" + id);
};

export const addAccountType = (params) => {
  return api.post("v1/crm/account-types", params);
};

export const updateAccountType = (id, params) => {
  return api.patch("v1/crm/account-types/"+id, params);
};
