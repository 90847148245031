import React, { Fragment, useState } from "react";
import { Col, Form, Row, FormGroup, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";

import { videosService } from 'Services';
import CommonModal from "Components/Common/Data/Ui-kits/Modals/common/modal";
import { FInput,FSelect } from 'CommonElements/Form';
import { Btn } from "AbstractElements";

const VideoAdd = ({ loadData }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const { register, handleSubmit, formState: { errors }, reset, control, setValue, getValues } = useForm();

  const modalSubmit = formValues => {
    if (formValues !== '') {
        setLoading(true);

        var formData = new FormData();
        formData.append("gallery", formValues.gallery);
        formData.append("url", formValues.url);
        formData.append("fileUrl", formValues['fileUrl']);

        videosService.addVideo(formData).then(res => {
            setLoading(false);
            toggle();
            reset();
            toast.success('Video added, successfully');
            loadData();
        }).catch(err => {
            setLoading(false);
            toast.error('Failed to add Video');
        });
    } else {
        errors.showMessages();
    }
};
const handleChange = (e) => {
    setValue(e.target.name, e.target.files[0]);
}
const formValueChange = (name, value) => {
  setValue(name , value);
}
  return (
    <Fragment>
      <Btn attrBtn={{ disabled: loading, onClick: toggle, color: 'primary' }}>Add Video</Btn>

      <CommonModal
        isOpen={modal}
        title={'Add New Video'}
        toggler={toggle}
        formSubmit={() => { }}
        hideFooter={true}
      >
        <Form className="needs-validation" noValidate=""
          onSubmit={handleSubmit(modalSubmit)}
        >
          <Row>
            <Col md='12 mb-3'>
              <FSelect
                  control={control}
                  errors={errors}
                  label="Gallery"
                  name="gallery"
                  register={register}
                  placeholder="Gallery Select"
                  options={[{ name: "Webinars", value: "Webinars" },{ name: "Market Overview", value: "MarketOverview" },{ name: "Technical Analysis", value: "TechnicalAnalysis" }]}
                  validation={{ required: true }}
                  setValue={formValueChange}
                />
            </Col>

            <Col md='12 mb-3'>
              <FInput
                errors={errors}
                label='URL'
                name='url'
                register={register}
                placeholder='Enter URL'
                validation={{ required: false }}
              />
            </Col>
            <Col md='12 mb-3'>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label">Image</Label>
                <Col sm="9">
                  <input onChange={handleChange} name="fileUrl" className="form-control" type="file" />
                </Col>
              </FormGroup>
            </Col>

            <Col md={12} className="d-flex flex-row-reverse">
              <Btn disabled={loading} attrBtn={{ color: 'primary' }}>Add</Btn>
            </Col>

          </Row>
        </Form>
      </CommonModal>
    </Fragment>
  );
};

export default VideoAdd;
