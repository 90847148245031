export const idTypeCodes = {
    "EID-EMIRATES ID": "EID",
    "PP-PASSPORT": "pp",
    "NID-NATIONAL ID": "NID",
    "DL-DRIVING LICENSE": "DL",
    "RESIDENT PERMIT CARD": "RPC"
}


export const modeOfPaymentCodes = {
    "Bank Wire": "BW",
    "Online": "ON",
    "Crypto": "CY",
}


export const sourceOfFundCodes = {
    "Salaried": "SAL",
    "Self-Employed": "SLF",
    "Freelancer": "FRE",
    "Inheritance": "INH",
    "Unemployed": "UNE"
}