/* eslint-disable import/no-anonymous-default-export */
import { 
    SET_LOADING,
    GET_LOGIN,
    SET_LOGIN_TITLE,
    CREATE_LOGIN,
    DELETE_LOGIN,
    CLEAR_LOGIN_TITLE,
    GET_PROFILE_DONE
  } from '../actions/login-action'
  
  // Define your state here
  const initialState = {
    loading: false,
    login: [],
    title: ''
  }
  
  // This export default will control your state for your application
  export default(state = initialState, {type, payload}) => {
    switch(type) {
      // Set loading
      case SET_LOADING:
        return {
          ...state,
      loading: true
        }
      // Get LOGIN
      case GET_LOGIN:
        return {
          ...state,
          LOGIN: payload,
          loading: false
        }
      // Set LOGIN title from user that gonna input a title in form
      case SET_LOGIN_TITLE:
        return {
          ...state,
      title: payload
        }
      // Create new LOGIN
      case CREATE_LOGIN:
        return {
      ...state,
      LOGIN: [payload, ...state.LOGIN],
      loading: false
        }
      // Clear LOGIN title in form after creating a new one
      case CLEAR_LOGIN_TITLE:
        return {
          ...state,
      title: ''
        }
      // Delete existed LOGIN
      case DELETE_LOGIN:
        return {
          ...state,
      LOGIN: state.LOGIN.filter(LOGIN => LOGIN.id !== payload),
      loading: false
        }
      // Return default state if you didn't match any case
      case GET_PROFILE_DONE:
        return {
          ...state,
          profile: payload
        }
      default:
        return state
    }
  }