import React , {useState,useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import loader from 'assets/images/loader.gif';

const Loader = (props) =>{
    const [show, setShow] = useState(true);
    
    useEffect(() => {
        const timeout = setTimeout(() => {
            setShow(false)
            }, 3000);

        return () => {
            clearTimeout(timeout);
            }
       
    },[show]);
    return (
        <tbody>
            <tr className={`loader-wrapper ${show ? '' : 'loderhide'}`}>
                <td id="global-loader1">
                    <img src={loader} className="loader-img" alt="Loading...."/>
                </td>
            </tr>
        </tbody>
    )
    /*<tbody>
        <tr>
            <td className='text-center ' colSpan={"100%"}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </td>
            
        </tr>
</tbody>*/
    };

export default Loader;