import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import WithdrawalsList from './WithdrawalsList';
import { Breadcrumbs } from '../../../AbstractElements';


const BasicTables = () => {
    return (
        <Fragment>
            <Breadcrumbs title="Withdrawals" mainTitle='' />
            <Container fluid={true}>
                <Row>
                    <WithdrawalsList />
                </Row>
            </Container>
        </Fragment>
    );
};

export default BasicTables;