import React, { useState, useRef, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Form, FormGroup, Button, Col,Container } from 'reactstrap';
import CommonModal from '../../../src/Components/Common/Data/Ui-kits/Modals/common/modal';
import { AddNewTask, AddTask, Close, Search } from "../../Constant";
import { Check, Trash2, Edit } from "react-feather";
import { Btn, H4, LI, UL } from "../../AbstractElements";
import SweetAlert from 'sweetalert2';
import { userService, ticketService, accountService, documentsService } from "Services";
import { FInput, FSelect, FTextArea } from "CommonElements/Form";
import data from './data.json';
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const AddFormModal = ({ modal, NewMessage, toggle, defaultVal, reloadData }) => {
  useEffect(() => {
  }, []);
    const [users, setUsers] = useState(null);
    const [assignToList, setAssignToList] = useState([]);
    const [clientType, setClientType] = useState('');
    const [clientName, setClientName] = useState('');
    const [requestOwner, setRequestOwner] = useState('');
    const [modifiedBy, setModifiedBy] = useState('');
    const [accountStatus, setAccountStatus] = useState('');
    const [accountNo, setAccountNo] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState("Finance");
    const requestTypes = data.requestTypes;
    const requestStatus = data.requestStatus;
    const subType = data.subType;
    const categories = [
        { name: 'Finance' },
        { name: 'Onboarding' },
        { name: 'Operation' }
      ];
    const [submitState, setSubmitState] = useState({
      loading: false,
    });
    const userProfile = useSelector(
      (state) => (state.login && state.login.profile) || {}
    );
    console.log('Profile is ',userProfile);
    const [formData, setFormData] = useState({
      // Initialize your form data here
      login: '',
      // other fields...
    });
    useEffect(() => {
      if (userProfile.firstName && userProfile.lastName) {
        // Assuming userProfile has firstName and lastName properties
        setRequestOwner(userProfile.firstName + ' ' + userProfile.lastName);
        setModifiedBy(userProfile.firstName + ' ' + userProfile.lastName);
      }
    }, [userProfile]); // This effect will run whenever userProfile changes
    const [accounts, setAccount] = useState([]);
    
    const [rec, setRec] = useState([]);
    const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
      getValues,
      control,
      clearErrors,
      reset,
    } = useForm();
    useEffect(() => {
      const formFields = getValues();
      Object.keys(formFields).forEach((key) => {
        if (data[key]) {
          setValue(key, data[key]);
        }
      });
      
      
      getUserList();
      getTicketList(1);
      //getAccountList();
    }, []);

    const getUserList = () => {
      var limit = 100;
      setRec({
        loading: true,
        data: [],
      });
      userService
        .getUsers({
          limit,
        })
        .then((res) => {
          console.log('users data is ',res.data);
          setUsers(res.data);
        })
        .catch((err) => {
          console.log("err getUser ", err);
        });
    };
    
    
    const getTicketList = (page = 1) => {
      setRec({
        loading: true,
        data: [],
      });
      ticketService
        .getTickets({page})
        .then((res) => {
          const formattedData = res.data.map((ticket) => ({
            ...ticket,
            createdAt: new Date(ticket.createdAt).toLocaleDateString(),
            updatedAt: new Date(ticket.updatedAt).toLocaleDateString(),
          }));
        setRec({
          loading: false,
          ...res,
        });
        })
        .catch((err) => {
          console.log("err getTickets ", err);
        });
      console.log('data is dsaf',rec);
    };
    useEffect(() => {
      if (users !== null && users !== undefined && users.length > 0) {
        var optionArr = [];
        users.map((opt) =>
          optionArr.push({
            name: opt.firstName + " " + opt.lastName + " - " + opt["Role.title"],
            value: opt.id,
          })
        );
        setAssignToList(optionArr);
        console.log("optionArr" + optionArr);
      }
    }, [users]);
   
    const editBtn = (e, rowData) => {
      e.preventDefault();
      if (rowData !== undefined && rowData !== null && rowData.id !== 0) {
        const formFields = getValues();
        setSelectedRow(rowData);
        console.log('edited data is ',rowData);
        setAccountNo(rowData.tag_tp_acc_no);
        Object.keys(formFields).forEach((key) => {
          if (rowData[key]) {
            if (key === "startTime") {
              var newDate = new Date(rowData[key]);
              const dateOptions = {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              };
              const formattedDate = newDate.toLocaleDateString(
                "en-CA",
                dateOptions
              );
              setValue(key, formattedDate);
            } else {
              setValue(key, rowData[key]);
            }
          }
          setAddTask(" visible");
        });
      }
    };
    const resetTicketData = () => {
      clearErrors();
      reset({
        request_owner: "",
        request_mode: "",
        request_category:"",
        request_type: "",
        request_status: "",
        assignTo: "",
        sub_type: "",
        description: "",
        priority: "",
        modified_by: "",
        tag_c_type: "",
        tag_c_name: "",
        tag_c_acc_status: "",
        tag_tp_acc_no: "",
        tag_ib_name: "",
        tag_ib_no: "",
        imageUrl: "",
      });
      setSelectedRow(null);
    };
    const objectReturn = (data) => {
      return {
        request_owner: requestOwner,
        request_mode: data.request_mode,
        request_category: data.request_category,
        request_type: data.request_type,
        request_status: data.request_status,
        assignTo: data.assignTo !== undefined ? data.assignTo : '',
        sub_type: data.sub_type,
        action: data.action,
        description: data.description,
        priority: data.priority,
        modified_by: modifiedBy,
        tag_c_type: clientType,
        tag_c_name: clientName,
        tag_c_acc_status: accountStatus !== null ? accountStatus : "",
        tag_tp_acc_no: accountNo,
        //customerId: customerId,
        tag_ib_name: "",
        tag_ib_no: "",
      };
    };
    const onSubmit = (data) => {
      var payload = objectReturn(data);
      console.log('data is', payload);
      if (payload !== "" && selectedRow == null) {
        setSubmitState({ loading: true });
        const formData = new FormData();
    
        // Assuming data is an object containing file data
        Object.keys(data).forEach((fieldKey) => {
          if (fieldKey === "imageUrl" && data[fieldKey]) {
            // If data[fieldKey] is an array (multiple files), loop through it
            for (let i = 0; i < data[fieldKey].length; i++) {
              const fileData = data[fieldKey][i];
              formData.append("imageUrl", fileData);
            }
          }
        });
    
        // Append payload to formData
        Object.keys(payload).forEach((key) => {
          formData.append(key, payload[key]);
        });
    
        console.log('combined form data is', formData);
    
        ticketService.postTicketImage(formData)
          .then((res) => {
            setSubmitState({
              loading: false,
              status: 'Documents uploaded successfully',
            });
            toggle();
    
            getTicketList();
            resetTicketData();
            reloadData();
            toast.success("Ticket Added Successfully");
          })
          .catch((err) => {
            setSubmitState({
              loading: false,
              status: 'Error in uploading documents',
            });
    
            toast.error("Ticket Not Added");
          });
      } else {
        const formData = new FormData();

        formData.append("imageUrl", data['imageUrl'][0]);
        formData.delete('customerId');
        // Append payload to formData
        Object.keys(payload).forEach((key) => {
          formData.append(key, payload[key]);
        });
    
        console.log('combined form data is for updation', formData);
    
        // Pass payload as the first argument to updateTicket
        updateTicket(formData, selectedRow.id);
        resetTicketData();
      }
    };
    
    const handleSearchSubmit = (event) => {
      event.preventDefault();
      // Handle the search form submission here
      console.log('Search button clicked');
      console.log(formData.login); // Access the form data

      accountService
          .getAccountSearch(formData.login)
          .then((res) => {
            console.log('Accounts data is ',res);
            setClientName(res.Customer.firstName+' '+res.Customer.lastName);
            setAccountStatus(res.Customer.accountStatus);
            setAccountNo(res.login);
            setClientType(res.AccountType.type);
            setCustomerId(res.Customer.id);
          })
          .catch((err) => {
            setSubmitState({
              loading: false,
            });
            console.log('eeror fetching data');
          });
          console.log(clientName , accountStatus,accountNo,customerId);
    };
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
    const updateTicket = (formData, selectedRowId) => {
      ticketService
        .updateTicket(formData, selectedRowId)
        .then((res) => {
          getTicketList();
          toast.success("Ticket Update Successfully");
        })
        .catch((err) => {
          setSubmitState({
            loading: false,
          });
          toast.error("Ticket Not Updated ");
        });
    };
    
    const [addTask, setAddTask] = useState("");
    const border_danger = useState("");
    const [status, setStatus] = useState("pending");

  const modalSubmit = (e) => {
    e.preventDefault();
    // handle form submission
    onSubmit(e);
  };
  const handleCategoryChange = (name, value) => {
    setValue(name , value);
    //setSelectedCategory(value);
    console.log(selectedCategory);
    if(name === 'request_category') {
      setSelectedCategory(value);
    }
  }
  const handleChange = (e) => {
    setSelectedFile(e.target.files[0]);
  }
  const validation = () => {
   
  };

  return (
    <CommonModal
      isOpen={modal}
      title={NewMessage}
      toggler={toggle}
      formSubmit={handleSubmit(onSubmit)}
      isButtonShown={false}
      customClassName={'ModalAddTicket'}
    >
      <div className={"new-task-wrapper"}>
            <Form
                  className="needs-validation"
                  noValidate=""
                >
                  <Container>
                    <FormGroup className="form-group row">
                    
                      <Col md="4 mb-3">
                        <input className="form-control"
                          control={control}
                          errors={errors}
                          name="login"
                          register={register}
                          placeholder="Enter Login No"
                          validation={{ required: true }}
                          onChange={handleInputChange}
                        />
                      </Col>
                      <Col md="4 mb-2">
                        <Button
                          className="ms-3 add-new-task-btn"
                          color="success"
                          onClick={handleSearchSubmit}
                        >
                          {Search}
                        </Button>
                      </Col>
                      
                    </FormGroup>
                  </Container>
                  
            </Form>
            <Form
              className="needs-validation"
              noValidate=""
              //onSubmit={handleSubmit(onSubmit)}
            >
              <Container>
                <FormGroup className="form-group row">
                <Col md="4 mb-3">
                  <FInput
                    type='text'
                    errors={errors}
                    label='Request Owner:'
                    name='request_owner'
                    register={register}
                    placeholder={requestOwner}
                    value={requestOwner}
                    defaultValue={requestOwner}
                    setValue={setValue}
                    validation={{ required: false }}
                    disabled={{disabled: true}}
                  />
                </Col>
                <Col md="4 mb-3">
                  <FInput
                    type='text'
                    errors={errors}
                    label='Request Modified:'
                    name='modified_by'
                    register={register}
                    placeholder={modifiedBy}
                    value={modifiedBy}
                    defaultValue={modifiedBy}
                    setValue={setValue}
                    validation={{ required: false }}
                    disabled={{disabled: true}}
                  />
                </Col>
                <Col md="4 mb-3">
                  <FInput
                    type='text'
                    errors={errors}
                    label='Account Type:'
                    name='tag_c_type'
                    register={register}
                    placeholder={clientType}
                    value={clientType}
                    defaultValue={clientType}
                    setValue={setValue}
                    validation={{ required: false }}
                    disabled={{disabled: true}}
                  />
                </Col>
                <Col md="4 mb-3">
                  <FInput
                    type='text'
                    errors={errors}
                    label='Client Name:'
                    name='tag_c_name'
                    register={register}
                    placeholder={clientName}
                    value={clientName}
                    defaultValue={clientName}
                    setValue={setValue}
                    validation={{ required: false }}
                    disabled={{disabled: true}}
                  />
                </Col>
                <Col md="4 mb-3">
                  <FInput
                    type='text'
                    errors={errors}
                    label='Account Status:'
                    name='c_account_status'
                    register={register}
                    placeholder={accountStatus}
                    value={accountStatus}
                    defaultValue={accountStatus}
                    setValue={setValue}
                    validation={{ required: false }}
                    disabled={{disabled: true}}
                  />
                </Col>
                <Col md="4 mb-3">
                  <FInput
                    type='text'
                    errors={errors}
                    label='TP Account No:'
                    name='tag_tp_acc_no'
                    register={register}
                    placeholder={accountNo}
                    value={accountNo}
                    defaultValue={accountNo}
                    setValue={setValue}
                    validation={{ required: false }}
                    disabled={{disabled: true}}
                  />
                </Col>
                <Col md="4 mb-3">
                <FSelect
                  control={control}
                  errors={errors}
                  label="Request Mode"
                  name="request_mode"
                  register={register}
                  placeholder="Select Request"
                  options={[
                    { name: "Manual", value: "Manual" },
                    { name: "Online", value: "Online" },
                  ]}
                  validation={{ required: true }}
                  value={"Manual"}
                  setValue={setValue}
                  disabled={selectedRow == null ? false : true}
                />
              </Col>
            
            
              <Col md="4 mb-3">
              <FSelect
              control={control}
              errors={errors}
              label="Request Category"
              name="request_category"
              register={register}
              placeholder="Select Category"
              options = {categories.map(obj => {
                return {value: obj.name, name: obj.name}
              })}
              validation={{ required: true }}
              //value={data.category}
              setValue={handleCategoryChange}
              disabled={selectedRow == null ? false : true}
            />
            </Col>

          <Col md="4 mb-3">
          <FSelect
            control={control}
            errors={errors}
            label="Request Type"
            name="request_type"
            register={register}
            placeholder="Select Type"
            options = {requestTypes.filter(obj => obj.category === selectedCategory).map(obj => {
              return {value: obj.name, name: obj.name}
            })}
                  validation={{ required: true }}
              setValue={handleCategoryChange}
                      />
                  </Col>

                  <Col md="4 mb-3">
                    <FSelect
                      control={control}
                      errors={errors}
                      label="Request Status"
                      name="request_status"
                      register={register}
                      placeholder="Select Status"
                      options={requestStatus.filter(obj => obj.category === selectedCategory).map(obj => {
                        return {value: obj.name, name: obj.name}
                      })}
                      validation={{ required: true }}
                      value={data.request_status || "Open"}
                      setValue={setValue}
                      disabled={selectedRow == null ? false : true}
                    />
                  </Col>

                  <Col md="4 mb-3">
                    <FSelect
                      control={control}
                      errors={errors}
                      label="Sub Type"
                      name="sub_type"
                      register={register}
                      placeholder="Select Type"
                      options={subType.filter(obj => obj.category === selectedCategory).map(obj => {
                        return {value: obj.name, name: obj.name}
                      })}
                      validation={{ required: true }}
                      value={data.sub_type}
                      setValue={setValue}
                    />
                  </Col>
                  
                  <Col md="4 mb-3" style={{ display: selectedCategory === 'Finance' ? 'block' : 'none' }}>
                    <FSelect
                      control={control}
                      errors={errors}
                      label="Action"
                      name="action"
                      register={register}
                      placeholder="Select Type"
                      options={[
                        { name: "Add", value: "Add" },
                        { name: "Checked", value: "Checked" },
                        { name: "Processed", value: "Processed" },
                        { name: "NA", value: "NA" },
                      ]}
                      validation={{ required: false }}
                      value={data.action}
                      setValue={setValue}
                    />
                  </Col>
                  {userProfile?.Role?.permissions?.tickets?.view
                      && userProfile.Role.permissions.tickets.create
                      && userProfile.Role.permissions.tickets.delete
                      && userProfile.Role.permissions.tickets.update &&
                    <Col md="4 mb-3">
                      <FSelect
                        control={control}
                        errors={errors}
                        label="Assign To"
                        name="assignTo"
                        register={register}
                        placeholder="Select Assigne"
                        options={assignToList}
                        validation={{ required: true }}
                        value={data.assignTo}
                        setValue={setValue}
                      />
                    </Col>
                  }
                  
                  <Col md="4 mb-3">
                    <FSelect
                      control={control}
                      errors={errors}
                      label="Priority"
                      name="priority"
                      register={register}
                      placeholder="Select Priority"
                      options={[
                      
                        { name: "High", value: "High" },
                        { name: "Normal", value: "Normal" },
                        { name: "Low", value: "Low" },
                      ]}
                      validation={{ required: true }}
                      value={data.priority}
                      setValue={setValue}
                    />
                  </Col>
                  <Col md="4 mb-3">
                  <FInput
                      errors={errors}
                      label="Attachments"
                      name="imageUrl"
                      type="file"
                      className={"ng-untouched ng-pristine" + border_danger}
                      onChange={handleChange}
                      register={register}
                      placeholder="Enter Description"
                      validation={{ required: false }}
                      //multiple
                    />
                  </Col>
                  {/*username: janycemetz_authorizer
                    password: QxVepIOEQzQ*/}
                  
                  <Col md="12 mb-3">
                    <FTextArea
                      errors={errors}
                      label="Description"
                      name="description"
                      className={"ng-untouched ng-pristine" + border_danger}
                      value={data.description}
                      register={register}
                      placeholder="Enter Description"
                      validation={{ required: false }}
                    />
                  </Col>
                  
                </FormGroup>
              </Container>
             {/* <Btn
                attrBtn={{
                  color: "danger",
                  className: "cancel-btn",
                  id: "close-task-panel",
                  onClick: closeTaskWrapper,
                }}
              >
                {Close}
              </Btn>
              <Button
                className="ms-3 add-new-task-btn"
                color="success"
                id="submitBtn"
                disable={submitState}
              >
                {AddTask}
            </Button>*/}
            </Form>
          </div>
    </CommonModal>
  );
};

export default AddFormModal;
