import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardHeader, Table, Container } from "reactstrap";
import { H5 } from "../../../AbstractElements";
import TableLoader from "CommonElements/Table/TableLoader";
import TableNodata from "CommonElements/Table/TableNodata";
import { Row } from "react-bootstrap";
import { groupsService, groupMemberService } from "Services";
import { FSwitch } from "CommonElements/Form";
import { Breadcrumbs } from '../../../AbstractElements';
import AddGroup from "./AddGroup";
import AddUsers from "./AddUsers";
import EditGroup from "./EditGroup";
import DeleteGroup from "./DeleteGroup";
import DeleteGroupMember from "./DeleteGroupMember";
import { toast } from "react-toastify";
import TablePagination from "CommonElements/Table/WithoutFilterPagination";
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';

// import { Link } from "react-router-dom";

const Groups = () => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [rec, setRec] = useState({});
  const [data, setData] = useState({});
  useEffect(() => {
    loadData(1);
  }, []);
  const loadData = (page = 1) => {
    setRec({
      loading: true,
      data: [],
    });
    groupsService
      .getGroups({
        page,
      })
      .then((res) => {
        setRec({
          ...res,
          loading: false,
        });
      })
      .catch((err) => {
      });
  }

  const loadUsers = async (groupId) => {
    await groupMemberService
      .getGroupMembers({
        groupId
      })
      .then((res) => {
        setData({
          ...res,
          loading: false,
        });
      })
      .catch((err) => {
      });
  }
  const handleRowClick = (id, groupId) => {
    // Toggle selection
    if (selectedRow === id) {
      setSelectedRow(null);
    } else {
      setSelectedRow(id);
    }
    loadUsers(groupId);
  };
  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  );
  return (
    <Fragment>
      <Breadcrumbs title="Groups" mainTitle='' />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="8">
                    {" "}
                    <H5>{'Groups'}</H5>
                  </Col>
                  <Col md="2" className="" style={{ textAlign: 'right' }}>
                    {getMenuPerm(permissions, 'banners:create') === 1 &&
                      <AddGroup loadData={loadData} />
                    }
                  </Col>
                  <Col md="2" className="">
                    {getMenuPerm(permissions, 'banners:create') === 1 &&
                      <AddUsers loadData={loadData} loadUsers={loadUsers} />
                    }
                  </Col>
                </Row>
              </CardHeader>
              <div className="table-responsive">
                <Table hover striped>
                  <thead>
                    <tr>
                      <th scope="col">{"#"}</th>
                      <th scope="col">{"Title"}</th>
                      <th scope="col">{"Description"}</th>
                      <th scope="col">{"Actions"}</th>

                    </tr>
                  </thead>
                  {rec.loading && <TableLoader />}
                  {!rec.loading && rec.data && rec.data.length === 0 && (
                    <TableNodata title="Banners" />
                  )}
                  <tbody>
                    {rec.data &&
                      rec.data.map((item, id) => (
                        <React.Fragment key={id}>
                          <tr onClick={() => handleRowClick(id, item.id)}>
                            <th scope="row">{item.id}</th>
                            <td>{item.name}</td>
                            <td>{item.description}</td>
                            <td>
                              {getMenuPerm(permissions, 'banners:update') === 1 && (
                                <EditGroup data={item} loadData={loadData} />
                              )}
                              {getMenuPerm(permissions, 'banners:delete') === 1 && (
                                <DeleteGroup data={item} loadData={loadData} />
                              )}
                            </td>
                          </tr>
                          {/* Render additional table below the clicked row */}
                          {selectedRow === id && (
                            <tr>
                              <td colSpan="12"> {/* Adjust the colspan based on the number of columns in your table */}
                                <Table hover striped>
                                  <thead>
                                    <tr>
                                      <th scope="col">{"#"}</th>
                                      <th scope="col">{"First Name"}</th>
                                      <th scope="col">{"Last Name"}</th>
                                      <th scope="col">{"Actions"}</th>

                                    </tr>
                                  </thead>
                                  {data.loading && <TableLoader />}
                                  {!data.loading && data.data && data.data.length === 0 && (
                                    <TableNodata title="Users For Group" />
                                  )}
                                  <tbody>
                                    {data.data &&
                                      data.data.map((items, ids) => (
                                        <React.Fragment key={ids}>
                                          <tr>
                                            <th scope="row">{items.id}</th>
                                            <td>{items['User.firstName']}</td>
                                            <td>{items['User.lastName']}</td>
                                            <td>
                                              {getMenuPerm(permissions, 'banners:delete') === 1 && (
                                                <DeleteGroupMember data={items} loadData={() => loadUsers(item.id)} />
                                              )}
                                            </td>
                                          </tr>
                                        </React.Fragment>
                                      ))}

                                  </tbody>
                                </Table>

                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                  </tbody>
                </Table>
                <TablePagination data={rec} loadData={loadData} />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Groups;
