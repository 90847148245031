
import React, { Fragment, useState } from "react";

import SweetAlert from 'sweetalert2';
import { toast } from "react-toastify";

import { documentsService } from 'Services';
import TableRowApprove from 'CommonElements/Table/TableRowApprove';

import ApproveDocumentModal from './ApproveDocumentModal';
const DeleteDocument = ({ data={}, loadData }) => {


  const approveDocument = () => {
    setShow(true);

};

const [show, setShow] = useState(false);

const onSubmit = (formData) => {
    documentsService.updateDocument(data.id, {...formData, status: 'Approved' }).then(res => {
        setShow(false);
        toast.success('Document updated, successfully');
        loadData();
    }).catch(err => {
        setShow(false);
        toast.error('Failed to reject Document');
    })
}
  return (
    <Fragment>
        <TableRowApprove onClick={approveDocument} />
        <ApproveDocumentModal data={data} show={show} toggle = {() => {setShow(!show)}} onSubmit={onSubmit}/>

    </Fragment>
  );
};

export default DeleteDocument;
