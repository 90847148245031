import React, { Fragment, useState } from "react";
import { Col, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";

import { accountTypeService } from 'Services';
import TableRowEdit from 'CommonElements/Table/TableRowEdit';
import CommonModal from "Components/Common/Data/Ui-kits/Modals/common/modal";
import { FInput, FSelect } from 'CommonElements/Form';
import { Btn } from "AbstractElements";

const EditAccountType = ({ data={},  loadData  }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal(!modal)
    setTimeout(()=>{
        setFormFields();
    },1)
  };
  const { register, handleSubmit, formState: { errors }, setValue, control } = useForm();
  
  // for adding data values to form
  const setFormFields = () => {
    setValue('title', data.title);
    setValue('groupPath', data.groupPath);
    setValue('groupPathSwapFree', data.groupPathSwapFree);
    
  }

  const modalSubmit = formValues => {
    if (formValues !== '') {
        setLoading(true);
        delete formValues.type;
        accountTypeService.updateAccountType(data.id, formValues).then(res => {
            setLoading(false);
            toggle();
            toast.success('Account Type updated, successfully');
            loadData();
        }).catch(err => {
            setLoading(false);
            toast.error('Failed to update Account Type');
        });
    } else {
        errors.showMessages();
    }
};
  return (
    <Fragment>
        <TableRowEdit onClick={toggle} />

        <CommonModal
            isOpen={modal}
            title={'Edit Account Type'}
            toggler={toggle}
            formSubmit={()=>{}}
            hideFooter={true}
            size={'lg'}
        >
            {/* {JSON.stringify(data.permissions)} */}
            <Form  className="needs-validation" noValidate="" 
                onSubmit={handleSubmit(modalSubmit)}
            >
                    <Row>  
                        <Col md='12 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Account Type Title'
                                name = 'title'
                                register = {register}
                                placeholder= 'Enter Account Type Title'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='12 mb-3' >

                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Account Type'
                                name = 'type'
                                disabled={true}
                                register = {register}
                                placeholder= 'Account Type'
                                options = {[{name: 'IB', value: 'IB'},{name: 'LIVE', value: 'LIVE'},{name: 'DEMO', value: 'DEMO'}]}
                                validation={{required: true}}
                                value={data.type}
                                setValue={setValue}
                            />
                        </Col>
                        <Col md='12 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Account Group Path'
                                name = 'groupPath'
                                register = {register}
                                placeholder= 'Enter Group Path'
                                validation={{required: true}}
                            />
                        </Col>
                        {data.type === 'LIVE' &&
                            <Col md='12 mb-3' >
                                <FInput
                                    errors={errors}
                                    label = 'Account Swap Free Group Path'
                                    name = 'groupPathSwapFree'
                                    register = {register}
                                    placeholder= 'Enter Swap Free Group Path'
                                    validation={{required: true}}
                                    />
                            </Col>
                        }
                       
                        <Col md={12} className="d-flex flex-row-reverse">
                            <Btn disabled={loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn>
                        </Col>
                        
                    </Row>

                    {/* <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn> */}
                </Form>
        </CommonModal>
    </Fragment>
  );
};

export default EditAccountType;
