import React, { Fragment, useState } from "react";
import { Btn } from "../../AbstractElements";
// import { NewMessage } from '../../../../../Constant';
import AddFormModal from "../Todo/AddFormModal";

const AddModalButton = ({ btnText, defaultVal, reloadData }) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <Fragment>
      <Btn attrBtn={{ color: "primary", onClick: toggle  }} >{btnText}</Btn>
      <AddFormModal
        reloadData={reloadData}
        modal={modal}
        NewMessage="Add Ticket"
        toggle={toggle}
        defaultVal={defaultVal}
        float = "right"
      />
    </Fragment>
  );
};

export default AddModalButton;
