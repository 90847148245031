import React, { Fragment, useEffect, useState } from "react";
import { Col, Form, Row } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { useForm } from "react-hook-form";
import { FInput, FTextArea, FSelect } from "CommonElements/Form";
import CommonModal from "../../../Common/Data/Ui-kits/Modals/common/modal";
import { transactionsService } from "Services";
import { toast } from "react-toastify";
import { ModalFooter } from "reactstrap";
import { Close, SaveChanges } from "../../../../Constant";

const ApprovedFormModal = (
  { show, toggle, onSubmit, data },
) => {
  const [ submitState, setSubmitState] = useState({
    loading: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control
  } = useForm();



  return (
    <Fragment>
      <CommonModal
        isOpen={show}
        title={'Approve Document'}
        toggler={toggle}
        isButtonShown={true}
        
      >
        <Form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row style={{ marginBottom: 10 }}>
            <Col md='12 mb-3' >
                <FInput
                    errors={errors}
                    label = 'Note'
                    name = 'note'
                    register = {register}
                    placeholder= 'Enter Note'
                    validation={{required: false}}
                />
            </Col>
            {data.type === 'PROOF_OF_ID' && <>
                <Col md='6 mb-3' >
                    <FSelect
                        control={control}
                        errors={errors}
                        label = 'Document Type'
                        name = 'docType'
                        register = {register}
                        placeholder= 'Select docType'
                        options = {[
                            {name: 'ID', value: 'ID'},
                            {name: 'Passport', value: 'Passport'},
                            {name: 'Driving License', value: 'Driving License'}
                        ]}
                        setValue={setValue}
                        validation={{required: false}}

                    />
                </Col>
                <Col md='6 mb-3' >
                    <FInput
                        errors={errors}
                        label = 'Document No.'
                        name = 'documentNo'
                        register = {register}
                        placeholder= 'Enter Document Number'
                        validation={{required: false}}
                    />
                </Col>
                <Col md='6 mb-3' >
                    <FInput
                        errors={errors}
                        label = 'Date of Issue'
                        name = 'dateOfIssue'
                        register = {register}
                        placeholder= 'Enter Date of Issue'
                        validation={{required: false}}
                        type='date'
                    />
                </Col>
                <Col md='6 mb-3' >
                    <FInput
                        errors={errors}
                        label = 'Date of Expiry'
                        name = 'dateOfExpiry'
                        register = {register}
                        placeholder= 'Enter Date of Expiry'
                        validation={{required: false}}
                        type='date'
                    />
                </Col>
                <Col md='6 mb-3' >
                    <FInput
                        errors={errors}
                        label = 'Country of Issue'
                        name = 'countryOfIssue'
                        register = {register}
                        placeholder= 'Enter Country of Issue'
                        validation={{required: false}}
                    />
                </Col>
            </>}
          </Row>

         
          <ModalFooter>
            <Btn
              attrBtn={{
                color: "secondary",
                onClick: () => toggle(),
                type: "button",
              }}
            >
              {Close}
            </Btn>
            <Btn disabled={submitState.loading} attrBtn={{ color: "primary"}}>
              {SaveChanges}
            </Btn>
          </ModalFooter>
        </Form>
      </CommonModal>
    </Fragment>
  );
};
export default ApprovedFormModal;
