import React, { Fragment, useEffect, useState } from "react";
import { Label } from "reactstrap";
import DatePicker from "react-datepicker";
import { formatDateTime, parseDateString   } from "Components/Common/Utility";

const DateTimeSelect = ({
  errors = {},
  label = "",
  name = "",
  register = () => {},
  type = "text",
  placeholder = "",
  value,
  setValue = () => {},
  validation = {},
  disabled,
  selectedValue,
  filter = () => {},
}) => {
  const [selectedValueDate, setSelectedValueDate] = useState(null);
  console.log('placehoder ',placeholder);

  const convertToDate = (dateString) => {
    // Decode the URI component
    const decodedString = decodeURIComponent(dateString);
  
    // Create a Date object from the decoded string
    const date = new Date(decodedString);
  
    // Extract year, month, and day parts
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
  
    // Format the date as yyyy-mm-dd
    const formattedDate = `${year}-${month}-${day}`;
  
    return formattedDate;
  }


  const handleChange = (e) => {
    setSelectedValueDate(e);
    setValue(name, e);
    
    if (name === 'dateFrom') {
      const formattedDate = convertToDate(e);

      filter(name, formattedDate);
      setSelectedValueDate(e);
      setValue(name, e);
    }

    if (name === 'dateTo') {
      const formattedDate = convertToDate(e);

      filter(name, formattedDate);
      setSelectedValueDate(e);
      setValue(name, e);
    }

  };

  useEffect(() => {
    if (selectedValue !== undefined && selectedValue !== null && selectedValue !== "") {
      setSelectedValueDate(new Date(selectedValue));
    } else {
      setSelectedValueDate(null);
    }
  }, [selectedValue]);

  return (
    <Fragment>
      <Label className="form-label" for="validationCustom01">
        {label}
      </Label>
      <DatePicker
        className="form-control digits"
        selected={selectedValueDate}
        autoComplete="off"
        {...register(name, validation)}
        type={type}
        value={value}
        placeholder={placeholder}
        name={name}
        onChange={handleChange}
        disabled={disabled}
        dateFormat="dd/MM/yyyy"
      />
      {errors[name] ? <span>{errors[name] && `${label} is ${errors[name].type}`}</span> : ''}
      <div className="valid-feedback">Looks good!</div>
    </Fragment>
  );
};

export default DateTimeSelect;
