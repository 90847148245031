import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardHeader, Table } from "reactstrap";
import { Withdrawals } from "../../../Constant";
import { H5 } from "../../../AbstractElements";
import { Row } from "react-bootstrap";
import WithdrawalModalButton from "./WithdrwalModalButton";
import { transactionsService } from "Services";
import Select from 'react-select';
import TableRowStatus from "CommonElements/Table/TableRowStatus";
import TableRowApprove from "CommonElements/Table/TableRowApprove";
import TableRowReject from "CommonElements/Table/TableRowReject";
import ApprovedFormModal from "../Withdrawals/Approved/ApprovedFormModal";
import RejectFormModal from "../Withdrawals/Reject/rejectFormModal";
import WithoutFilterPagination from "CommonElements/Table/WithoutFilterPagination";
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';
import { formatDate } from '../../Common/Utility';


const HoverableRowsClass = () => {
  const [approvedModal, setApprovedModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [approvedModalData, setApprovedModalData] = useState([]);
  const [rejectModalData, setRejectModalData] = useState([]);
  const [limit, setLimit] = useState(10);
  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  );

  const [rec, setRec] = useState({});
  useEffect(() => {
    getListRecord(1);
  }, []);
  const getListRecord = (page = 1) => {
    setRec({
      loading: true,
      data: [],
    });
    transactionsService
      .getCountryTransactions({ type: "WITHDRAWAL", page, limit: limit })
      .then((res) => {
        setRec({
          ...res,
          loading: false,
        });
        console.log('Withdraw Details ', res);
      })
      .catch((err) => {
      });
    console.log('data is dsaf', rec);
  };
  const toggle = (e, rowData) => {
    e.preventDefault();
    if (rowData !== undefined && rowData !== null && rowData.idc !== 0) {
      setApprovedModalData({ ...rowData });
    }
    setApprovedModal(!approvedModal);
  };

  //Reject Transaction

  const rejecttl = (e, rowData) => {
    e.preventDefault();
    if (rowData !== undefined && rowData !== null && rowData.id !== 0) {
      setRejectModalData({ ...rowData });
    }
    setRejectModal(!rejectModal);
  };

  const [filter, setFilter] = useState('');
  const [filteredData, setFilteredData] = useState(rec);

  const handleFilterChange = (event) => {
    const inputValue = event.target.value;
    setFilter(inputValue);
  };

  // Watch for changes in rec and filter, and update filteredData
  useEffect(() => {
    // Filter rec based on the input value
    const newFilteredData = rec && rec.data && rec.data.filter((item) => {
      return (item && item.id && item.id.toString().includes(filter)) || (item && item["Account.login"] && item["Account.login"].toString().includes(filter)) ||
        (item && item.gateway &&
          item.gateway.toLowerCase().includes(filter.toLowerCase())) ||
        (item && item.amount &&
          item.amount.toString().includes(filter.toLowerCase())) ||
        (item && item.status &&
          item.status.toLowerCase().includes(filter.toLowerCase()));
    });

    // Update the filtered data
    setFilteredData(newFilteredData);
  }, [rec, filter]);

  const options = [
    { value: '10', label: '10' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '200', label: '200' },
    { value: '500', label: '500' },
    { value: '1000', label: '1000' }
  ]
  // Function to handle limit change
  const handleLimitChange = (selectedOption) => {
    const newLimit = selectedOption.value; // Use the selected option's value directly
    setLimit(newLimit); // Update the limit state
    console.log('hmmm', selectedOption.value);
    transactionsService
      .getCountryTransactions({ type: "WITHDRAWAL", page: 1, limit: newLimit })
      .then((res) => {
        console.log("details for withdrawal is ", res);
        setRec({
          ...res,
          limit: newLimit,
          loading: false,
        });
      })
      .catch((err) => {
      });
  };

  return (
    <Fragment>
      <ApprovedFormModal
        approvedModal={approvedModal}
        NewMessage="Approved WithDrawals"
        toggle={toggle}
        defaultVal="Default Value"
        setApprovedModal={setApprovedModal}
        approvedData={approvedModalData}
        refreshRecord={getListRecord}
      />
      <RejectFormModal
        rejectModal={rejectModal}
        NewMessage="Reject Deposit"
        rejecttl={rejecttl}
        defaultVal="Default Value"
        setRejectModal={setRejectModal}
        rejectData={rejectModalData}
        refreshRecord={getListRecord}
      />

      <Col sm="12">
        <Card>
          <CardHeader>
            <Row>
              <Col md="6">
                <H5>{Withdrawals}</H5>
              </Col>
              <Col md="6">
                <Row className="float-end">
                  <Col md="6">
                    <input
                      className="form-control"
                      type="text"
                      value={filter}
                      //style={{width: '30%', top: '11px', position: 'absolute', right: '20px' }}
                      onChange={handleFilterChange}
                      placeholder="Search..."
                    />
                  </Col>
                  <Col md="3">
                    {getMenuPerm(permissions, 'transactions:create') === 1 &&
                      <WithdrawalModalButton btnText="Add" defaultVal="Godo" refreshRecord={getListRecord} />
                    }
                  </Col>
                  <Col md="3">
                    <Select
                      defaultValue={options.find(option => option.value === limit.toString())}
                      onChange={handleLimitChange}
                      options={options}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardHeader>
          <div className="table-responsive">
            <Table hover striped>
              <thead>
                <tr>
                  <th scope="col">{"#"}</th>
                  <th scope="col">{"Deal Id"}</th>
                  <th scope="col">{"Date"}</th>
                  <th scope="col">{"Account"}</th>
                  <th scope="col">{"Type"}</th>
                  <th scope="col">{"Name"}</th>
                  <th scope="col">{"TP Amount"}</th>
                  <th scope="col">{"TP Currency"}</th>
                  <th scope="col">{"Paid"}</th>
                  <th scope="col">{"Fee"}</th>
                  <th scope="col">{"Gateway"}</th>
                  <th scope="col">{"note"}</th>
                  <th scope="col">{"reason"}</th>
                  <th scope="col">{"Status"}</th>
                  <th scope="col">{"Action"}</th>
                </tr>
              </thead>
              <tbody>
                {filteredData && filteredData.length > 0 ? (
                  filteredData.map((item, index) => (
                    <tr key={index}>
                      <th scope="row">{item.id}</th>
                      <th scope="row">{item.dealId}</th>
                      <th scope="row">{formatDate(new Date(item.createdAt))}</th>
                      <td>
                        {item["Account.login"] ||
                          `${item["AccountFrom.login"]} -> ${item["AccountTo.login"]}`}
                      </td>
                      <td>
                        { item["Account.type"] === 'LIVE' ? 'CLIENT' : item["Account.type"] }
                      </td>
                      <td>
                        {item["Account.Customer.firstName"] + ' ' + (item["Account.Customer.lastName"] !== null ? item["Account.Customer.lastName"] : '') ||
                          `${item["Account.Customer.firstName"]} -> ${(item["Account.Customer.lastName"] !== null ? item["Account.Customer.lastName"] : '')}`}
                      </td>
                      <td>{item.amount}</td>
                      <td>{item["Account.currency"]}</td>
                      <td>{item.paid}</td>
                      <td>{item.fee}</td>
                      <td>{item.gateway}</td>
                      <td>{item.note}</td>
                      <td>{item.reason}</td>
                      <td>
                        <TableRowStatus status={item.status} />
                      </td>

                      <td>
                        {item.status === 'PENDING' && getMenuPerm(permissions, 'transactions:actions') === 1 &&
                          <a href="#javascript" className="tble_edit">
                            <TableRowApprove onClick={(e) => toggle(e, item)} />
                          </a>
                        }
                        {item.status === 'PENDING' && getMenuPerm(permissions, 'transactions:actions') === 1 &&
                          <a href="#javascript" className="tble_delete">
                            <TableRowReject onClick={(e) => rejecttl(e, item)} />
                          </a>
                        }
                      </td>
                    </tr>
                  ))
                ) : (
                  ''
                )}
              </tbody>
            </Table>
            <WithoutFilterPagination data={rec} loadData={getListRecord} />

          </div>
        </Card>
      </Col>
    </Fragment>
  );
};

export default HoverableRowsClass;
