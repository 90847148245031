import api from "./api";

export const getBankAccount = (params) => {
  return api.get("v1/crm/bank-accounts", params);
};

export const getAccountSearch = (params) => {
  console.log("params",params);
  return api.get("/v1/crm/accounts/search?login=" + params);
};

export const updateAccount = (params, id) => {
  console.log("Params,", id, params);
  return api.patch("/v1/crm/accounts/" + id, params);
};