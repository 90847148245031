import api from './api';
var queryString = (params) => {
  return Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
};

export const getCustomerNotes = (params) => {
  return api.get('/v1/crm/customer-notes/?' + queryString(params));
};
export const getNotes = (params) => {
  return api.get('/v1/crm/customer-notes/notes?' + queryString(params));
}
export const getTasks = () => {
  return api.get("/v1/crm/customer-notes");
};
export const postCustomerNotes = (params) => {
  console.log('params for custemrNOte', params);
  return api.post('/v1/crm/customer-notes', params);
};
export const updateCustomerNotes = (id, params) => {
  return api.patch("v1/crm/customer-notes/" + id, params);
};