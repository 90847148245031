import React, { Fragment, useState  } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { H5 } from "../../AbstractElements";
// import TodoCheckbox from "./TodoCheckbox";
import TodoList from "./TodoList";
import AddModalButton from "../Todo/AddModalButton";

const TodoContain = () => {
  const [reloadData, setReloadData] = useState(false); // State to trigger data reload

  // Function to reload data
  const handleReloadData = () => {
    setReloadData(prevState => !prevState);
  };

  
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
        <Col xl="12" style={{ marginTop: "30px" }}>
            <Card>
              <CardHeader className='p-3 d-flex align-items-center justify-content-between'>
              <H5>Tickets</H5>
              <AddModalButton btnText='Add New Task' defaultVal='Godo' reloadData={handleReloadData} />
              
            </CardHeader>
              <CardBody style={{ padding: "20px" }}>
                <div className="todo">
                  <div className="todo-list-wrapper">
                    <div className="todo-list-container">
                      {/* <TodoCheckbox /> */}
                      <TodoList reloadData={reloadData} />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default TodoContain;
