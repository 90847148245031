export const SET_LOADING = "SET_LOADING"
export const GET_LOGIN = "GET_LOGIN"
export const GET_LOGIN_REQUESTED = "GET_LOGIN_REQUESTED"
export const SET_LOGIN_TITLE = "SET_LOGIN_TITLE"
export const SET_LOGIN_TITLE_REQUESTED = "SET_LOGIN_TITLE_REQUESTED"
export const CLEAR_LOGIN_TITLE = "CLEAR_LOGIN_TITLE"
export const CREATE_LOGIN = "CREATE_LOGIN"
export const CREATE_LOGIN_REQUESTED = "CREATE_LOGIN_REQUESTED"
export const DELETE_LOGIN = "DELETE_LOGIN"
export const DELETE_LOGIN_REQUESTED = "DELETE_LOGIN_REQUESTED"
export const GET_PROFILE = "GET_PROFILE"
export const GET_PROFILE_DONE = "GET_PROFILE_DONE"
