import api from "./api";
var queryString = (params) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};
export const getGroups = (params={}) => {
  return api.get("v1/crm/groups?" + queryString(params));
};

export const deleteGroup = (id) => {
  return api.delete("v1/crm/groups/" + id);
};

export const addGroup = (params) => {
  console.log('details ',params);
  return api.post("v1/crm/groups", params);
};

export const updateGroup = (id, params) => {
  return api.patch("v1/crm/groups/"+id, params);
};

// export default new UserService();
export const getUsersDropdown = (query = "") => {
  return api.get("/v1/crm/groups/dropdown?query=" + query);
};