import api from "./api";
var queryString = (params) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};

export const getTickets = (params) => {
  return api.get("/v1/crm/tickets?page="+ params.page);
};

export const deleteTicket = (id) => {
  return api.delete("/v1/crm/tickets/" + id);
};
export const postTicket = (params) => {
  return api.post("/v1/crm/tickets/", params);
};

export const postTicketImage = (params) => {
  return api.post("/v1/crm/tickets/upload", params,{
    headers:{
    "Content-Type":"multipart/form-data"
  }
});
};
export const updateTicket = (params, id) => {
  return api.patch("/v1/crm/tickets/" + id, params,{
    headers:{
      "Content-Type":"multipart/form-data"
    }
  });
};

export const getDocumentSas = (params) => {
  // Assuming you want to send imageUrl as a query parameter
  return api.get(`/v1/crm/tickets/view?imageUrl=${encodeURIComponent(params.imageUrl)}`);
};