import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import img1 from "../../../../../assets/images/appointment/app-ent.jpg";
import { Image, P } from "../../../../../AbstractElements";

const SelectedSalesCard = ({ salesData }, props) => {
  return (
    <Col xl="12" className="appointment" style={{ border: "1px solid #eee" }}>
      <Card>
        <CardBody className="pt-10" style={{ padding: "10px" }}>
          <div className="appointment-table table-responsive">
            <table className="table table-bordernone">
              <tbody>
                {salesData.map((x, index) => (
                  <tr key={index}>
                    <td>
                      <span
                        style={{
                          border: "2px solid #888",
                          padding: "3px",
                          borderRadius: "5px",
                          fontSize: "10px",
                        }}
                        className="font-roboto"
                      >
                        {x.id}
                      </span>
                    </td>
                    <td>
                      <Image
                        attrImage={{
                          className: "img-fluid img-40 rounded-circle mb-3",
                          src: `${img1}`,
                          alt: "",
                        }}
                      />
                      <div className="status-circle bg-primary"></div>
                    </td>
                    <td className="img-content-box">
                      <span className="d-block">
                        {x.firstName} {x.lastName}
                      </span>
                      <span className="font-roboto">{x.city}</span>
                    </td>
                    <td>
                      <P attrPara={{ className: "m-0 font-primary" }}>
                        {x.phone}
                      </P>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SelectedSalesCard;
