import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row, Table, Card, CardHeader, CardFooter } from 'reactstrap';
import { bankAccountsService } from 'Services';
import TableLoader from 'CommonElements/Table/TableLoader';
import TableNodata from 'CommonElements/Table/TableNodata';
import EditBankAccount from './EditBankAccount';
import DeletBankAccount from './DeletBankAccount';
import AddBankAccount from './AddBankAccount';
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';
import WalletAccounts from './Wallets/WalletAccounts';


const BankAccounts = (props) => {
  const {
    customerId = '',
    activeTab, tabNumber
  } = props;
  const [rec, setRec] = useState({});
  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  );
  useEffect(()=>{
    if(activeTab === tabNumber) {
        loadData();
    }
  }, [activeTab, tabNumber]);

  const loadData = () => {
    bankAccountsService.getBankAccounts(customerId).then((res) => {
        setRec({
            ...res,
            loading: false,
        })
        }).catch(err =>{
        });
  }

  return (
    <Fragment>
        <Card>
            <CardHeader className='p-3'>
            <Row>
                <Col md={6} className="d-flex">
                    Bank Accounts
                </Col>
                <Col md={6} className="d-flex flex-row-reverse">
                    {getMenuPerm(permissions, 'bankAccounts:create') === 1 &&
                        <AddBankAccount customerId={customerId} loadData={loadData} />
                    }
                </Col>
            </Row>
            </CardHeader>
        
         <div className="table-responsive">
            <Table hover striped>
                <thead>
                    <tr>
                        <th scope="col">{'#'}</th>
                        <th scope="col">{'Account Holder'}</th>
                        <th scope="col">{'Bank Name'}</th>
                        <th scope="col">{'Bank Address'}</th>
                        <th scope="col">{'Account Number'}</th>
                        <th scope="col">{'Swift Code'}</th>
                        <th scope="col">{'Address'}</th>
                        <th scope="col">{'IBAN'}</th>
                        <th scope="col">{'Currency'}</th>
                        <th scope="col">{'Actions'}</th>
                        
                    </tr>
                </thead>
                {rec.loading && <TableLoader />}
                {!rec.loading && rec.data && rec.data.length === 0 && <TableNodata title="Bank Accounts" />}
                
                <tbody>
                    {
                        rec.data && rec.data.map((item, id) =>
                            <tr key={id}>
                                <th scope="row">{item.id}</th>
                                <td >{item.accountHolderName}</td>
                                <td >{item.bankName}</td>
                                <td >{item.bankAddress}</td>
                                <td >{item.accountNumber}</td>
                                <td >{item.swiftCode}</td>
                                <td >{item.address}</td>
                                <td >{item.iban}</td>
                                <td >{item.currency}</td>
                                <td>
                                    {getMenuPerm(permissions, 'bankAccounts:update') === 1 &&
                                        <EditBankAccount data={item} loadData={loadData} />
                                    }
                                    {getMenuPerm(permissions, 'bankAccounts:delete') === 1 &&
                                        <DeletBankAccount data={item} loadData={loadData}  />
                                    }
                                </td>

                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </div>
        </Card>
        <WalletAccounts customerId={customerId}/>

    </Fragment>
  );
};
export default BankAccounts;