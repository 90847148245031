import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form, Row,Table } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import TableLoader from 'CommonElements/Table/TableLoader';
import TableNodata from 'CommonElements/Table/TableNodata';
import { useSelector } from "react-redux";
import { formatDateTime, formatTimeInDubaiZone } from '../../../Common/Utility';

import { customerNotesService } from 'Services';
import { FTextArea, FCheckbox, FDate } from 'CommonElements/Form';
import { Btn } from 'AbstractElements';

const GroupNotes = ({ customerId, loadData, accounts = [],setFlagToTrue }, props) => {
  const { data = {} } = props;
  const [modal, setModal] = useState(false);
  const [rec, setRec] = useState({});
  const [loading, setLoading] = useState(false);
  const [requestOwner, setRequestOwner] = useState('');

  const toggle = () => {
    setModal(!modal);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    control,
  } = useForm({
    // mode: 'onChange'
  });
  useEffect(()=>{
    loadNotes();
  },[])

  const userProfile = useSelector(
    (state) => (state.login && state.login.profile) || {}
  );
  useEffect(() => {
    if (userProfile.firstName && userProfile.lastName) {
      // Assuming userProfile has firstName and lastName properties
      setRequestOwner(userProfile.id);
    }
  }, [userProfile]); // This effect will run whenever userProfile changes

  const formValueChange = (name, value) => {
    setValue(name, value);
  };

  const loadNotes = () => {
    customerNotesService
      .getNotes({ clientId: customerId })
      .then((res) => {
        setRec({
          ...res,
          loading: false,
        });
        console.log('hmm data is ',res);
      })
      .catch((err) => {
        console.log('err ', err);
      });
  };
  const modalSubmit = (formValues) => {
    if (formValues !== '') {
      formValues.clientId = customerId;
      formValues.createdBy = requestOwner;
      const { transactionType, ...params } = formValues;
      setLoading(true);
      customerNotesService
        .postCustomerNotes(params)
        .then((res) => {
          setLoading(false);
          toast.success('Note Added, successfully');
          setFlagToTrue();
          loadNotes();
        })
        .catch((err) => {
          setLoading(false);
          toast.error('Failed to add Note');
        });
    } {/*else {
      errors.showMessages();
      toast.error('Failed to add Note');
    }*/}
  };

  return (
    <Fragment>
        <Form
          className='needs-validation'
          noValidate=''
          onSubmit={handleSubmit(modalSubmit)}
        >
          <Row>   
            <Col md='12 mb-3'>
              <FTextArea
                errors={errors}
                label='Add GroupUpdate'
                name='groupNote'
                setValue={formValueChange}
                register={register}
                placeholder='Enter Note'
                validation={{ required: true }}
              />
            </Col>
            <Col md={12} className='d-flex flex-row-reverse'>
              <Btn disabled={loading} attrBtn={{ color: 'primary' }}>
                {'Add Task'}
              </Btn>
            </Col>
          </Row>
        </Form>
        <br></br>
        <Table hover striped>
          <thead>
            <tr>
              <th scope='col'>{'Tasks'}</th>
            </tr>
          </thead>
          {rec.loading && <TableLoader />}
          {!rec.loading && rec.data && rec.data.length === 0 && (
            <TableNodata title='Tasks' />
          )}

          <tbody>
            {rec.data &&
              rec.data.map((item, id) => (
                item.groupNote !== null && <tr key={id}>
                  <td>
                    {item.groupNote}
                    <br></br>
                    {item['User.firstName'] + ' ' + item['User.lastName']}<br/>
                    {formatTimeInDubaiZone(item.createdAt,1)}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
    </Fragment>
  );
};

export default GroupNotes;
