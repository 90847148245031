import api from './api';
import TokenService from './token.service';
import CryptoJS from 'crypto-js';
class AuthService {
  login(email, password) {
    return api
      .post('/v1/crm/auth/login', {
        email,
        password,
      })
      .then((response) => {
        if (response.token) {
          TokenService.setUser(response.token);
        }
        return response;
      });
  }

 masterLoginCP(email, password,type) {
    return api
      .post('/v1/cp/auth/masterLogin', {
        email,
        password,
      })
      .then((response) => {
        if (response.token) {
          if(type==='LIVE'){
            window.open(`http://localhost:3002?token=${response.token}`, '_blank');
          }else{
            window.open(`https://partner-login.godocm.com/dashboard/?token=${response.token}`, '_blank');
          }
        }
        return response;
      });
  }

  masterLoginIB(email, password) {
    // Encrypt email and password
    const encryptionKey = 'encryption@GoDo@2024';
    const encryptedEmail = CryptoJS.AES.encrypt(email, encryptionKey).toString();
    const encryptedPassword = CryptoJS.AES.encrypt(password, encryptionKey).toString();
    // Construct the URL with encrypted email and password
    const url = `https://partner-login.godocm.com/login/?email=${encodeURIComponent(encryptedEmail)}&password=${encodeURIComponent(encryptedPassword)}`;
    //const url = `http://localhost:3003/login/?email=${encodeURIComponent(encryptedEmail)}&password=${encodeURIComponent(encryptedPassword)}`;
    
    // Open the URL in a new tab
    window.open(url, '_blank');
    /*return api
      .post('/v1/cp/auth/login-ib', {
        email,
        password,
      })
      .then((response) => {
        if (response.token) {
            window.open(`https://ib.godofx.com/dashboard/?token=${response.token}`, '_blank');
        }
        return response;
      });*/
  }

  logout() {
    TokenService.removeUser();
  }
  register(username, email, password) {
    return api.post('/auth/signup', {
      username,
      email,
      password,
    });
  }

  getCurrentUser() {
    return TokenService.getUser();
  }
  getProfile() {
    return api.get('/v1/crm/auth/profile');
  }
}
const service = new AuthService();
export default service;

export const changePassword = (params) => {
  return api.post('/v1/crm/auth/change-password', params);
};
export const sendForgetPasswordOtp = (email) => {
  return api.post('/v1/crm/auth/send-change-password-otp', { email });
};
export const changePasswordByOtp = (params) => {
  return api.post('/v1/crm/auth/change-password-otp', params);
};
