import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Btn } from "../../../../../../AbstractElements";
import { Close, SaveChanges } from "../../../../../../Constant";

const CommonModal = (props) => {
  // const hideFooter = props.hideFooter || false;
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggler}
      size={props.size}
      centered
      className={`${props.customClassName}`}
    >
      <ModalHeader toggle={props.toggler}>{props.title}</ModalHeader>
      <ModalBody className={props.bodyClass}>{props.children}</ModalBody>
      {props.isButtonShown === false && (
        <ModalFooter>
          <Btn attrBtn={{ color: "secondary", onClick: props.toggler }}>
            {Close}
          </Btn>
          <Btn attrBtn={{ color: "primary", onClick: props.formSubmit }}>
            {SaveChanges}
          </Btn>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default CommonModal;
