import React, { Fragment } from "react";
import { Col, Table } from "reactstrap";
import WithoutFilterPagination from "CommonElements/Table/WithoutFilterPagination";
import TableLoader from "CommonElements/Table/TableLoader";

const HoverableRowsClass = (props) => {
  const { reportData={}, formValues, loadData, submitState={} } = props;
  const {data} = reportData;
  return (
    <Fragment>
      {data != null && data.length > 0 ? (
        <Col sm="12">
          <div className="table-responsive">
            <Table hover striped>
              {submitState.loading && <TableLoader />}
              {(formValues.report === 'deposit' || formValues.report === 'withdrawal') && <>
                <thead>
                  <tr>
                    <th scope="col">{"#"}</th>
                    <th scope="col">{"Deal ID"}</th>
                    <th scope="col">{"Login"}</th>
                    <th scope="col">{"Accoun Type"}</th>
                    <th scope="col">{"Amount"}</th>
                    <th scope="col">{"Fee"}</th>
                    <th scope="col">{"Gateway"}</th>
                    <th scope="col">{"Paid"}</th>
                    <th scope="col">{"Note"}</th>
                    <th scope="col">{"Created At"}</th>
                    <th scope="col">{"Updated At"}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, id) => (
                    <tr key={id}>
                      <th scope="row">{item.id}</th>
                      <th scope="row">{item.dealId}</th>
                      <td>{item["Account.login"]}</td>
                      <td>{item["Account.type"]}</td>
                      <td>{item.amount}</td>
                      <td>{item.fee}</td>
                      <td>{item.gateway}</td>
                      <td>{item.paid}</td>
                      <td>{item.note}</td>
                      <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                      <td>{new Date(item.updatedAt).toLocaleDateString()}</td>
                    </tr>
                  ))}
                </tbody>
              </>}
              {(formValues.report === 'funded' || formValues.report === 'unFunded') && <>
                <thead>
                  <tr>
                    <th scope="col">{"#"}</th>
                    <th scope="col">{"firstName"}</th>
                    <th scope="col">{"lastName"}</th>
                    {/* <th scope="col">{"email"}</th> */}
                    <th scope="col">{"country"}</th>
                    {/* <th scope="col">{"phone"}</th> */}
                    <th scope="col">{"nationality"}</th>
                    <th scope="col">{"createdAt"}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, id) => (
                    <tr key={id}>
                      <th scope="row">{item.id}</th>
                      <td>{item.firstName}</td>
                      <td>{item.lastName}</td>
                      {/* <td>{item.email}</td> */}
                      <td>{item.country}</td>
                      {/* <td>{item.phone}</td> */}
                      <td>{item.nationality}</td>
                      <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                    </tr>
                  ))}
                </tbody>
              </>}
              {(formValues.report === 'ibPayout') && <>
                <thead>
                  <tr>
                    <th scope="col">{"IB ID"}</th>
                    <th scope="col">{"Deal ID"}</th>
                    <th scope="col">{"IB Name"}</th>
                    <th scope="col">{"Amount"}</th>
                    <th scope="col">{"Payment Date"}</th>
                    <th scope="col">{"Payment Status"}</th>
                    <th scope="col">{"Payment Method"}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, id) => (
                    <tr key={id}>
                      <td>{item.id}</td>
                      <td>{item.dealId}</td>
                      <td>{item['Account.Customer.firstName']}{' '}{item['Account.Customer.lastName']}</td>
                      <td>{item.amount}</td>
                      <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                      <td>{item.status}</td>
                      <td>{item.gateway}</td>
                    </tr>
                  ))}
                </tbody>
              </>}
              {(formValues.report === 'ibTotal') && <>
                <thead>
                  <tr>
                    <th scope="col">{"IB ID"}</th>
                    <th scope="col">{"IB Name"}</th>
                    <th scope="col">{"Master IB"}</th>
                    <th scope="col">{"Total Outstanding Balance"}</th>
                    <th scope="col">{"Total Payout"}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, id) => (
                    <tr key={id}>
                      <td>{item.ibId}</td>
                      <td>{item.ibName}</td>
                      <td>{item.parentIbName}</td>
                      <td>{item.balance || 0.00}</td>
                      <td>{item.totalPayout || 0.00}</td>
                    </tr>
                  ))}
                </tbody>
              </>}
              {(formValues.report === 'ibInternalTransfer') && <>
                <thead>
                  <tr>
                    <th scope="col">{"IB ID"}</th>
                    <th scope="col">{"IB Name"}</th>
                    <th scope="col">{"IB Acc No"}</th>
                    <th scope="col">{"Amount"}</th>
                    <th scope="col">{"To Account"}</th>
                    <th scope="col">{"Date"}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, id) => (
                    <tr key={id}>
                      <th scope="row">{item.id}</th>
                      <td>{item["AccountFrom.Customer.firstName"]}{' '}{item["AccountFrom.Customer.lastName"]}</td>
                      <td>{item['AccountFrom.login']}</td>
                      <td>{item.amount}</td>
                      <td>{item["AccountTo.login"]}</td>
                      <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                    </tr>
                  ))}
                </tbody>
              </>}
              
            </Table>
            <WithoutFilterPagination data={reportData} loadData={loadData} />

          </div>
        </Col>
      ) : (
        <div className="template">No Data Found</div>
      )}
    </Fragment>
  );
};

export default HoverableRowsClass;
