import React, { Fragment, useState } from "react";
import { Btn } from "../../AbstractElements";
// import { NewMessage } from '../../../../../Constant';
import EditFormModal from "../Todo/EditFormModal";
import { Edit } from "react-feather";

const EditModalButton = ({ btnText, defaultVal, loadData , item, row}) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <Fragment>
      {/*<Btn attrBtn={{ color: "primary", onClick: toggle  }} >{btnText}</Btn>*/}
      <span
        className="action-box large delete-btn"
        title="Edit Task"
        onClick={(e) => toggle()}
      >
      <Edit />
      </span>
      <EditFormModal
        loadData={loadData}
        modal={modal}
        NewMessage="Edit Ticket"
        toggle={toggle}
        item={item}
        row={row}
        float = "right"
      />
    </Fragment>
  );
};

export default EditModalButton;
