import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { useForm } from 'react-hook-form';
import { FInput, FTextArea, FSelect } from 'CommonElements/Form';
import gateways from '../../Common/Component/Gateways';
import Swal from "sweetalert2";

import CommonModal from '../../../../src/Components/Common/Data/Ui-kits/Modals/common/modal';
import { transactionsService, BankAccount } from 'Services';
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";

import { ModalFooter } from 'reactstrap';
import { Close, SaveChanges } from '../../../Constant';

const WithdrawalFormModal = (
  { modal, NewMessage, toggle, setModal, refreshRecord },
  props
) => {
  const { data = {} } = props;
  const [accountData, setAccountData] = useState('');
  const [isAccounInputDisable, setIsAccounInputDisable] = useState(false);
  const [submitState, setSubmitState] = useState({
    loading: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    reset,
    control,
  } = useForm();
  const formValueChange = (name, value) => {
    setValue(name, value);
  };
  const resetForm = () => {
    return reset({
      login: '',
      accountTypeId: '',
      gateway: '',
      amount: '',
      reason: '',
      // fee: '',
      note: '',
    });
  };
  useEffect(() => {
    if (!modal) {
      clearErrors();
      resetForm();
      setIsAccounInputDisable(false);
    }
  }, [modal]);

  // 5secondDelay
  const handleLoginChange = (e) => {
    e.preventDefault();
    const loginNo = e.target.value;
    setValue('login', loginNo);
    // searchTimer =
    setTimeout(startSearch, 3000);
  };
  const startSearch = () => {
    const formFields = getValues();
    var loginNo = formFields.login;
    if (loginNo != null && loginNo !== '') {
      setIsAccounInputDisable(false);
      setValue('accountTypeId', '');
      BankAccount.getAccountSearch(`${loginNo}`)
        .then((res) => {
          if (res.id != null) {
            setAccountData(res);
            setValue('accountTypeId', res.id);
            setIsAccounInputDisable(true);
          }
        })
        .catch((err) => {
        });
    }
  };
  const onSubmit = (data) => {
    if (data !== '') {
      setSubmitState({ loading: true });
      const payload = {
        accountId: Number(data.accountTypeId),
        gateway: data.gateway,
        note: data.note,
        reason: data.reason,
        // fee: data.fee,
        amount: data.amount,
      };

      Swal.fire({
        title: "Are you sure you want to Approve?",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
        reverseButtons: true,
        showCancelButton: true,
        text: "Once approved, you will not be able to approve this request!",
        icon: "success",
      }).then((res) => {
        if (res.isConfirmed) {
          setModal(false);
          transactionsService
            .postWithdrawals(payload)
            .then((res) => {
              setSubmitState({
                loading: false,
              });
              toast.success('Withdrawal Added Successfully');
              refreshRecord();
            })
            .catch((err) => {
              setSubmitState({
                loading: false,
              });
              toast.error(' Add Withdrawal failed ');
            });
        }
      });

    } else {
      errors.showMessages();
    }
  };

  const roleEntity = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.entity) || {}
  );

  return (
    <Fragment>
      <CommonModal
        isOpen={modal}
        title={NewMessage}
        toggler={toggle}
        isButtonShown={true}
      >
        <Form
          className='needs-validation'
          noValidate=''
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row style={{ marginBottom: 10 }}>
            <Col md='12'>
              <FInput
                type='number'
                errors={errors}
                label='Login No:'
                name='login'
                register={register}
                placeholder='Enter Login No'
                value={data.login}
                validation={{ required: true }}
                handleOnChange={(e) => handleLoginChange(e)}
              />
            </Col>
            <Col md='12'>
              <FInput
                type='text'
                label='Name:'
                //name='login'
                placeholder={accountData.Customer?.firstName + ' ' + accountData.Customer?.lastName || ''}
                value={accountData.Customer?.firstName + ' ' + accountData.Customer?.lastName}
                disabled={true}
              />
            </Col>
            <Col md='6' style={{ display: 'none' }}>
              <FInput
                type='number'
                errors={errors}
                label='Account No:'
                name='accountTypeId'
                register={register}
                placeholder='Enter Account No'
                value={data.accountTypeId}
                validation={{ required: true }}
                disabled={isAccounInputDisable}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col md='6'>
              <FInput
                errors={errors}
                type='number'
                label='Amount:'
                name='amount'
                value={data.amount}
                register={register}
                placeholder='Enter your amount'
                validation={{ required: true }}
              />
            </Col>
            <Col md='6'>
              <FSelect
                control={control}
                errors={errors}
                label='Gateway'
                name='gateway'
                register={register}
                placeholder='Enter gateway'
                options={roleEntity && Object.values(roleEntity).length !== 0 ? [{ name: 'Local Withdrawal Morocco', value: 'Local Withdrawal Morocco' }] : gateways.filter(item => item.key === 0 || item.key === 2)}
                validation={{ required: true }}
                value={''}
                setValue={formValueChange}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col md='12'>
              <FInput
                errors={errors}
                label='Reason:'
                name='reason'
                value={data.reason}
                register={register}
                placeholder='Enter Reason'
                validation={{ required: true }}
              />
            </Col>
            {/* <Col md='6'>
              <FInput
                type='number'
                errors={errors}
                label='Fee:'
                name='fee'
                value={data.fee}
                register={register}
                placeholder='Enter fee'
                validation={{ required: true }}
              />
            </Col> */}
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col md='12'>
              <FTextArea
                errors={errors}
                label='Note:'
                name='note'
                value={data.note}
                register={register}
                placeholder='Enter Note'
              />
            </Col>
          </Row>
          <ModalFooter>
            <Btn
              attrBtn={{
                color: 'secondary',
                onClick: () => setModal(false),
                type: 'button',
              }}
            >
              {Close}
            </Btn>
            <Btn
              disabled={submitState.loading}
              attrBtn={{ color: 'primary' }}
            // onSubmit={() => handleMyFunction()}
            >
              {SaveChanges}
            </Btn>
          </ModalFooter>
        </Form>
      </CommonModal>
    </Fragment>
  );
};
export default WithdrawalFormModal;
