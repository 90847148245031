import api from './api';

export const getDocuments = (customerId) => {
    return api.get('/v1/crm/documents?customerId=' + customerId)
}
export const getMoroccoDocuments = (params) => {
    console.log("params are ", params);
    return api.get('/v1/crm/documents/morocco-documents', { params });
}
export const uploadDocuments = (params) => {
    console.log('Upload Doc Files', params);
    return api.post('/v1/crm/documents', params, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    }
    );
}
export const deleteDocument = (id) => {
    return api.delete('/v1/crm/documents/' + id)
}
export const updateDocument = (id, params) => {
    return api.patch('/v1/crm/documents/' + id, params)
}
export const updateDocuments = (id, params)=> {
    return api.patch('/v1/crm/documents/documentUpdate/'+id, params,{
        headers: {
          "Content-Type": "multipart/form-data",
        }
      })
}
export const getDocumentSas = (id, number = 1)=> {
    console.log(number, 'fileNumber is');
    //return api.get('/v1/crm/documents/'+id, '?fileNumber='+number)
    return api.get(`/v1/crm/documents/${id}?fileNumber=${number}`)
}
export const pendingDocs = (params) => {
    console.log('Sent Data is ', params);
    return api.post('/v1/crm/documents/pendingDocs', params);
}
