import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardHeader, Table } from "reactstrap";
import { Users } from "../../../Constant";
import { H5 } from "../../../AbstractElements";
import { userService } from "Services";
import TableLoader from "CommonElements/Table/TableLoader";
import TableNodata from "CommonElements/Table/TableNodata";
import { Btn } from "../../../AbstractElements";
import { Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { Trash2, Edit } from "react-feather";
import AddUserModal from "./AddUser/addUser";
import { FSwitch } from "CommonElements/Form";
import { toast } from "react-toastify";
import TablePagination from "CommonElements/Table/TablePagination";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { getMenuPerm } from 'utils';
import Filter from '../../Common/Component/filter';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const HoverableRowsClass = () => {
  const query = useQuery();
  const pageQuery = query.get('page');

  const [modal, setModal] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [initialPage, setInitialPage] = useState(1);
  const toggle = () => {
    setEditUser(null);
    setModal(!modal);
  };
  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  );
  const [rec, setRec] = useState({});
  useEffect(() => {
    loadData();
  }, []);
  const loadData = () => {
    const currentUrl = window.location.href;
    let { page, limit, bodyParams } = getParamsAndBody(currentUrl);
    setInitialPage(page);
    (!bodyParams.page || !bodyParams.limit) &&
      setRec({
        loading: true,
      })
    userService
      .getUsers({
        page,
        limit,
        ...bodyParams
      })
      .then((res) => {
        setRec({
          ...res,
          loading: false,
        });
        console.log("users are ", res);
      })
      .catch((err) => {
      });
  };
  function delbtn(id) {
    Swal.fire({
      title: "Are you sure you want to delete?",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      reverseButtons: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        userService
          .delUsers(id)
          .then((resDelUser) => {
            if (resDelUser) {
              Swal.fire("Deleted!", "User has been deleted.", "success");
              setModal(false);
            }
          })
          .catch((err) => {
            Swal.fire("Not Deleted!", "User has not been  deleted.", "error");
          });
        //
      }
    });
  }

  const editBtn = (e, rowData) => {
    e.preventDefault();
    if (rowData !== undefined && rowData !== null && rowData.id !== 0) {
      setEditUser({ ...rowData });
    }
    setModal(!modal);
    // Swal.fire({
    //   title: "Are you sure you want to edit this?",
    //   cancelButtonText: "No",
    //   confirmButtonText: "Yes",
    //   reverseButtons: true,
    //   showCancelButton: true,
    // });
  };
  const changeAccess = (item, index) => {
    userService
      .updateUser(
        {
          isActive: !item.isActive,
        },
        item.id
      )
      .then((res) => {
        rec.data[index].isActive = !item.isActive;
        toast.success("User updated, successfully");
      })
      .catch((err) => {
        toast.error("Failed to update User");
      });
  };

  const changeStatus = (item, index) => {
    userService
      .updateUser(
        {
          status: !item.status,
        },
        item.id
      )
      .then((res) => {
        rec.data[index].status = !item.status;
        toast.success("User updated, successfully");
      })
      .catch((err) => {
        toast.error("Failed to update User");
      });
  };

  const handleLimitChange = (selectedOption) => {
    const newLimit = selectedOption.value; // Use the selected option's value directly
    //setLimit(newLimit); // Update the limit state
    //loadData(1,newLimit);
    appendParameterToUrl('limit', newLimit);
  };
  const appendParameterToUrl = (key, value) => {
    // Get the current URL
    const currentUrl = window.location.href;

    // Create a URL object
    const url = new URL(currentUrl);

    if (key == 'subLeads') {
      url.search = '';
    }
    if (key == 'language' || key == 'status' || key == 'firstName') {
      url.searchParams.set('page', 1);
    }

    // Check if the parameter exists
    if (url.searchParams.has(key)) {
      // Update the existing parameter
      url.searchParams.set(key, value);
    } else {
      // Append the new parameter
      url.searchParams.append(key, value);
    }

    if (key == 'language' && value == '' || key == 'status' && value == '' || key == 'firstName' && value == '') {
      console.log("value is ", value);
      url.searchParams.delete(key);
    }

    // Update the browser's address bar
    window.history.pushState(null, '', url.toString());
    loadData();
    console.log("Updated URL:", url.toString());
  };

  // Function to separate page and limit, and construct body params
  const getParamsAndBody = (url) => {
    const queryParams = extractUrlParams(url);

    const page = queryParams.page || 1;
    const limit = queryParams.limit || 25;
    //setLimit(limit);
    // Remove page and limit from queryParams to construct the body parameters
    delete queryParams.page;
    delete queryParams.limit;

    const bodyParams = queryParams;

    return {
      page,
      limit,
      bodyParams
    };
  };
  // Function to extract query parameters from the URL
  const extractUrlParams = (url) => {
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    const queryParams = {};
    for (const [key, value] of params) {
      queryParams[key] = value;
    }

    return queryParams;
  };
  return (
    <Fragment>
      <Col sm="12">
        <Card>
          <CardHeader>
            <Row className="align-items-center">
              <Col md="2">
                <H5>{Users}</H5>
              </Col>
              <Col md="10">
                <Row className="align-items-center">

                  <Col md="9">
                    <Filter filter={appendParameterToUrl} show={'isUser'} />
                  </Col>
                  <Col md="3 text-right">
                    {getMenuPerm(permissions, 'users:create') === 1 &&
                      <Btn attrBtn={{ color: "primary", onClick: toggle }}>
                        Add
                      </Btn>
                    }
                    <AddUserModal
                      modal={modal}
                      NewMessage="Add User"
                      toggle={toggle}
                      setModal={setModal}
                      loadData={loadData}
                      editData={editUser}
                    />
                  </Col>
                  {/* <Col md="6">
                            <Link className='btn btn-secondary' to={'/godo/detail'}> Users Detail
                    </Link>
                            </Col> */}
                </Row>
              </Col>
            </Row>
          </CardHeader>
          <div className="table-responsive">
            <Table hover striped>
              <thead>
                <tr>
                  <th scope="col">{"#"}</th>
                  <th scope="col">{"First Name"}</th>
                  <th scope="col">{"Last Name"}</th>
                  <th scope="col">{"Email"}</th>
                  <th scope="col">{"Role"}</th>
                  <th scope="col">{"Phone"}</th>
                  <th scope="col">{"Access"}</th>
                  <th scope="col">{"Status"}</th>
                  <th scope="col">{"Language"}</th>
                  <th scope="col">{"Action"}</th>
                </tr>
              </thead>
              {rec.loading && <TableLoader />}
              {!rec.loading && rec.data && rec.data.length === 0 && (
                <TableNodata title="Users" />
              )}
              <tbody>
                {rec.data &&
                  rec.data.map((item, id) => (
                    <tr key={id}>
                      <th scope="row">{item.id}</th>
                      <td>{item.firstName}</td>
                      <td>{item.lastName}</td>
                      <td>{item.email}</td>
                      <td>{item["Role.title"] || "---"}</td>
                      <td>{item.phone}</td>
                      <td>
                        {getMenuPerm(permissions, 'users:update') === 1 &&
                          <FSwitch
                            onChange={() => {
                              changeAccess(item, id);
                            }}
                            value={item.isActive}
                          />
                        }
                      </td>
                      <td>
                        {getMenuPerm(permissions, 'users:update') === 1 &&
                          <FSwitch
                            onChange={() => {
                              changeStatus(item, id);
                            }}
                            value={item.status}
                          />
                        }
                      </td>
                      <td>
                        {item.language}
                      </td>

                      <td>
                        {item.action}
                        {getMenuPerm(permissions, 'users:update') === 1 &&
                          <a href="#javascript" className="tble_edit">
                            <Edit
                              onClick={(e) => editBtn(e, item)}
                              style={{ color: "blue" }}
                            />
                          </a>
                        }
                        {getMenuPerm(permissions, 'users:delete') === 1 &&
                          <a href="#javascript" className="tble_delete">
                            <Trash2
                              onClick={() => delbtn(item.id)}
                              style={{ color: "red" }}
                            />
                          </a>
                        }
                        {/* <Button onClick={editBtn} color="primary" size="sm">
                            <i className="fa fa-pencil"></i> {""}
                            {Edit}
                          </Button>
                          {"  "}
                          <Button
                            onClick={() => delbtn(item.id)}
                            color="danger"
                            size="sm"
                          >
                            <i className="fa fa-trash"></i> {""}
                            {Delete}
                          </Button>{" "} */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <TablePagination data={rec} loadData={loadData} param={appendParameterToUrl} />
          </div>
        </Card>
      </Col>
    </Fragment>
  );
};

export default HoverableRowsClass;
