import React, { Fragment } from 'react';
import { Table, Row, Col } from 'reactstrap';
import TableNodata from 'CommonElements/Table/TableNodata';
import Select from 'react-select';

const OpenPositions = ({ positions = {}, loadData, limit, accountId }) => {
  const {
    data = []
  } = positions;
  if (!positions || !positions.data) return ('');
  const options = [
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' }
  ]
  // Function to handle limit change
  const handleLimitChange = (selectedOption) => {
    loadData(1, selectedOption.value); // Reload data with the selected limit
  };

  return (
    <Fragment>

      <Row>
        <Col md={8} className="mt-5 ">
          <h5>Open Positions</h5>
        </Col>
        <Col md={4} className="mt-5 mb-3">
          <Select
            defaultValue={options.find(option => option.value === limit.toString())}
            onChange={handleLimitChange}
            options={options}
          />
        </Col>
      </Row>
      <div className="table-responsive">
        <Table hover striped>
          <thead>
            <tr>
              <th scope="col">Login</th>
              <th scope="col">Symbol</th>
              <th scope="col">Position</th>
              <th scope="col">Time</th>
              <th scope="col">Deal Type</th>
              <th scope="col">Lots</th>
              <th scope="col">Price</th>
              <th scope="col">Price SL</th>
              <th scope="col">Price TP</th>
              <th scope="col">Price Position</th>
              <th scope="col">Swap</th>
              <th scope="col">Profit</th>

            </tr>
          </thead>
          <tbody>
            {
              data.map((item, id) =>
                <tr key={id}>
                  <td >{item.Login || item.login}</td>
                  <td>{item.Symbol || item.symbol}</td>
                  <td>{item.Position || item.ticket}</td>
                  <td>{(item.TimeCreate && new Date(item.TimeCreate * 1000).toLocaleDateString()) || (item.open_time_str && new Date(item.open_time_str).toLocaleDateString())}</td>
                  <td className={'Class' + item.Action}>{(item.Action && item.Action == 1 ? "SELL" : "BUY") || item.type_str}</td>
                  {/*<td className='slkdf'>{(item.Volume && item.Volume) || (item.volume && item.volume)}</td>*/}
                  <td>{(item.Volume && item.Volume / 10000) || (item.volume && item.volume / 100)}</td>
                  <td>{item.PriceOpen && parseInt(item.PriceOpen) || item.open_price && parseInt(item.open_price)}</td>
                  <td>{item['PriceSL'] || item.sl}</td>
                  <td>{item['PriceTP'] || item.tp}</td>
                  <td>
                    {(item['PricePosition'] ? parseInt(item['PricePosition']) : 0) ||
                      (item.open_price ? parseInt(item.open_price) : 0)}
                  </td>
                  <td>{item.Swap || item.swap}</td>
                  <td>{item.Profit && item.Profit || item.profit}</td>
                </tr>
              )
            }

          </tbody>
          {
            positions.data && positions.data.length === 0 &&
            <TableNodata title="Open Positions" />

          }
        </Table>
      </div>
    </Fragment>
  );
};
export default OpenPositions;