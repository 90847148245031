import React, { Fragment, useState, useEffect } from "react";
import { Col, Form, Row, FormGroup, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";

import { groupsService, userService } from 'Services';
import CommonModal from "Components/Common/Data/Ui-kits/Modals/common/modal";
import { FInput, FMultiUserSelect, FUserSelect } from 'CommonElements/Form';
import { Btn } from "AbstractElements";

const GroupAdd = ({ loadData, loadUsers }) => {

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const { register, handleSubmit, formState: { errors }, reset, control, setValue, getValues } = useForm();

  const [rec, setRec] = useState({});
  useEffect(() => {
    loadUsers(1);
  }, []);
  
  loadUsers = (page = 1) => {
    setRec({
      loading: true,
      data: [],
    });
    userService
      .getUsers({
        page,
      })
      .then((res) => {
        setRec({
          ...res,
          loading: false,
        });
      })
      .catch((err) => {
      });
  };

  const modalSubmit = formValues => {
    if (formValues !== '') {
        setLoading(true);

        groupsService.addGroup(formValues).then(res => {
            setLoading(false);
            toggle();
            reset();
            toast.success('Group added, successfully');
            loadData();
        }).catch(err => {
            setLoading(false);
            toast.error('Failed to add Group');
        });
    } else {
        errors.showMessages();
    }
};
const handleChange = (e) => {
    setValue(e.target.name, e.target.files[0]);
}

  return (
    <Fragment>
      <Btn attrBtn={{ disabled: loading, onClick: toggle, color: 'primary' }}>Add Group</Btn>

      <CommonModal
        isOpen={modal}
        title={'Add New Group'}
        toggler={toggle}
        formSubmit={() => { }}
        hideFooter={true}
      >
        <Form className="needs-validation" noValidate=""
          onSubmit={handleSubmit(modalSubmit)}
        >
          <Row>
            <Col md='12 mb-3'>
              <FInput
                errors={errors}
                label='Title'
                name='name'
                register={register}
                placeholder='Enter Group Title'
                validation={{ required: true }}
              />
            </Col>

            <Col md='12 mb-3'>
              <FInput
                errors={errors}
                label='Description'
                name='description'
                register={register}
                placeholder='Enter Description'
                validation={{ required: false }}
              />
            </Col>
            <Col md='12 mb-3'>
              <FUserSelect
                control={control}
                errors={errors}
                label='Select Agent'
                name='groupMember'
                validation={{ required: true }}
                setValue={setValue}
                value={rec.agentId}
                defaultOptions={rec.Agent ? [rec.Agent] : []}

                // isClient={true}
              />
            </Col>
            
            <Col md={12} className="d-flex flex-row-reverse">
              <Btn disabled={loading} attrBtn={{ color: 'primary' }}>Add</Btn>
            </Col>

          </Row>
        </Form>
      </CommonModal>
    </Fragment>
  );
};

export default GroupAdd;
