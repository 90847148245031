

export const getMenuPerm = (permissionsObj, permKey) => {
    if(!permKey) return true;
    const splitArr = permKey.split(':');
    if(splitArr.length !== 2) return true;
    try {
        return permissionsObj[splitArr[0]] && permissionsObj[splitArr[0]][splitArr[1]] | false;    
    } catch(err) {
        console.log('_______', permKey, permissionsObj);
        return false;
    }
}