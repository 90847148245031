import React, { Fragment, useState } from "react";
import { Col, Form, Row, FormGroup, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";

import { groupsService } from 'Services';
import TableRowEdit from 'CommonElements/Table/TableRowEdit';
import CommonModal from "Components/Common/Data/Ui-kits/Modals/common/modal";
import { FInput } from 'CommonElements/Form';
import { Btn } from "AbstractElements";

const EditGroup = ({ data={},  loadData  }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal(!modal)
    setTimeout(()=>{
        setFormFields();
    },1)
  };
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  
  // for adding data values to form
  const setFormFields = () => {
    setValue('name', data.name);
    setValue('description', data.description);
    
  }

  const modalSubmit = formValues => {
    if (formValues !== '') {
        setLoading(true);
        
        var formData = new FormData();
        formData.append("name", formValues.name);
        formData.append("description", formValues.description);
        {/*if(formValues['fileUrl'] && formValues['fileUrl'][0]) {
            formData.append("fileUrl", formValues['fileUrl'][0]);
        }*/}


        groupsService.updateGroup(data.id, formData).then(res => {
            setLoading(false);
            toggle();
            toast.success('Group updated, successfully');
            loadData();
        }).catch(err => {
            setLoading(false);
            toast.error('Failed to update Group');
        });
    } else {
        errors.showMessages();
    }
};
{/*const handleChange = (e) => {
    setValue(e.target.name, e.target.files);
}*/}

  return (
    <Fragment>
        <TableRowEdit onClick={toggle} />
        <CommonModal
            isOpen={modal}
            title={'Edit Group'}
            toggler={toggle}
            formSubmit={()=>{}}
            hideFooter={true}
            size={'lg'}
        >
            <Form  className="needs-validation" noValidate="" 
                onSubmit={handleSubmit(modalSubmit)}
            >
                     <Row>  
                        <Col md='12 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Title'
                                name = 'name'
                                register = {register}
                                placeholder= 'Enter Group Title'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='12 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Description'
                                name = 'description'
                                register = {register}
                                placeholder= 'Enter Description'
                                validation={{required: false}}
                            />
                        </Col>
                        <Col md={12} className="d-flex flex-row-reverse">
                            <Btn disabled={loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn>
                        </Col>
                    </Row>
                </Form>
        </CommonModal>
    </Fragment>
  );
};

export default EditGroup;
