import React, { Fragment, useEffect, useState, useCallback } from 'react';
import {
  Row,
  Col,
  Label,
} from 'reactstrap';
import InputField from '../../Shared/Input/Index';
import { useForm } from 'react-hook-form';
import { leadService, activitiesService, roleService, userService, clientService } from 'Services';
import { writeFile, utils } from 'xlsx';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { FInput, FUserSelect, FSelect, FDateTime } from 'CommonElements/Form';

import ptrInfo from '../json/ptrInfo.json';

// Function to extract query parameters from the URL
const extractUrlParams = () => {
  const url = new URL(window.location.href);
  return url.searchParams;
};

const Filter = ({ filter, show, user }) => {
  const params = extractUrlParams();
  const [filterValue, setFilterValue] = useState({});
  const [agentId, setAgentId] = useState(null);
  const [roleList, setSetRoleList] = useState([]);
  const [roleId, setRoleId] = useState(null);
  const [firstName, setFirstName] = useState(params.get('firstName') || null);
  const [agentName, setAgentName] = useState();
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [ibInfo, setIbInfo] = useState(null);

  const [rec, setRec] = useState(null);

  useEffect(() => {
    if (!roleList.length > 0 && show == 'isUser') {
      roleService
        .getRoles({ limit: 20 })
        .then((res) => {
          let roleList = [];
          if (res.data.length > 0) {
            res.data.map((item) => {
              return roleList.push({ name: item.title, value: item.id });
            });
            setSetRoleList(roleList);
          } else {
            console.log("No Role Found ");
          }
        })
        .catch((err) => {
          console.log("No Role Found ");
        });
    }
  }, [roleList]);

  useEffect(() => {
    if (agentId !== null) {
      // Place your effect logic here
      console.log('Running useEffect because agentId is not null', agentId);

      const [key, value] = Object.entries(agentId)[0];
      filter(key, value);
      setAgentId(value);
    }
  }, [agentId]); // Run this effect when agentId changes

  useEffect(() => {
    if (roleId !== null) {
      // Place your effect logic here
      console.log('Running useEffect because roleId is not null', roleId);
      let [key, value] = [];
      Object.entries(roleId)[0] ?
        [key, value] = Object.entries(roleId)[0] : [key, value] = ['roleId', roleId];
      filter(key, value);
      setRoleId(value);
    }
  }, [roleId]); // Run this effect when agentId changes

  useEffect(() => {
    const fetchUserData = async () => {
      const id = params.get('agentId');
      if (id) {
        try {
          const data = await userService.getUser(id);
          console.log("Data of user:", data, data.lastName);
          setAgentName(data.firstName + ' ' + data.lastName);
          // Here you can set the data to state or perform further actions
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, [params.get('agentId')]);

  useEffect(() => {

    if (ibInfo !== null) {
      console.log('Running useEffect because agentId is not null', ibInfo);
      const [key, value] = Object.entries(ibInfo)[0];
      filter(key, value);
      setIbInfo(value);
      setRec(value);
    }

    const fetchIb = async () => {
      const id = params.get('parentId');
      if (id) {
        try {
          const data = await clientService.getClientDetail(id);
          console.log("Data of user:", data, data.lastName);
          setRec(data.firstName + ' ' + data.lastName);
        }  catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    }

    fetchIb();
    
    // const fetchIb = async () => {

    //   const parentId = params.get('parentId');

    //   if (parentId) {
        
    //     try {
          
    //       const bodyParams = {
    //         'isIb': 1,
    //         'id': parentId
    //       }
           
    //       const data = await clientService
    //       .searchCustomers({
    //         page: 1,
    //         limit: 10,
    //         ...bodyParams,
    //       })

    //       console.log("Data of user:", data, data.lastName);
    //       setRec(data.firstName + ' ' + data.lastName ? data.lastName : '');

    //     }  catch (error) {
    //       console.error("Error fetching user data:", error);
    //     }

    //   }

    // };

    // fetchIb();

  }, [ibInfo])

  useEffect(() => {
    const date = params.get('dateFrom');
    const dateTo = params.get('dateTo')
    if (date) {
      setDateFrom(date);
    }

    if (dateTo) {
      setDateTo(dateTo);
    }

  }, [dateFrom, dateTo]);

  const handleName = (e) => {
    console.log(e.target.value);
    setFirstName(e.target.value);
  }
  const handleChange = (e) => {
    if (e.type === 'keydown' && e.key !== 'Enter') {
      return;
    }
  
    let { value, text } = '';
    if (e.target) {
      value = e.target.value;
      text = e.target.name;
    } else {
      value = e;
      text = 'callStatus';
    }
    
    filter(text, value);
    setDateFrom(e.target.value);
  };
  const handleIbAndDirectClient = (e) => {
    const value = e;
      const text = 'parentId'
      filter(text, value);
  };
  const handleEntity = (e) => {
    const value = e;
    const text = 'entity'
    filter(text, value);
  };
  const handleKYC = (e) => {
    const value = e;
    const text = 'kycStatus';
    filter(text, value);
  };

  const handleFunded = (e) => {
    const value = e;
    const text = 'isFunded';
    filter(text, value);
  };

  const handleStage = (e) => {
    const value = e;
    const text = 'approvalStage';
    filter(text, value);
  };
  const handleStatus = (e) => {
    const value = e;
    const text = 'accountStatus';

    filter(text, value);
  };
  const handleLanguage = (e) => {
    const value = e;
    const text = 'language';
    filter(text, value);
  }
  const handleUserStatus = (e) => {
    const value = e;
    const text = 'status';
    filter(text, value);
  }
  const { formState: { errors }, setValue, getValues, register, control } = useForm();

  // const options = [
  //   { text: 'ALL', value: '' },
  //   { text: 'Direct Client', value: 'DirectClient' },
  //   ...(rec.data
  //     ? rec.data.map((item) => ({
  //         text: `${item.firstName} ${item.lastName ? item.lastName : ''}`,
  //         value: item.id
  //       }))
  //     : []
  //   )
  // ];

  return (
    <Fragment>
      <Row className='mb-3'>
        <Col md={show !== 'isUser' ? '2' : '3'}>
          <Label className="form-label" for="validationCustom01">
            {"Name"}
          </Label>
          <input
            className="form-control"
            type={'input'}
            placeholder={'Search Name'}
            name={'firstName'}
            value={firstName}
            onChange={handleName}
            onKeyDown={handleChange}
          />
        </Col>
        {show !== 'isUser' &&
          <>
            <Col md='2'>
              <FUserSelect
                control={control}
                label='Sales Person'
                name='agentId'
                setValue={setAgentId} // Updated to directly use setSearchValue
                value={agentName || ''} // Set value from searchValue state
                agentName={agentName || ''}
                //={handleFilter}
                context="specificPage"
              />
            </Col>
            <Col md='2'>

              <FDateTime
                errors={errors}
                name='dateFrom'
                label='Date From'
                filter={filter}
                //defaultValue={dateFrom}
                //handleOnChange={handleChange}
                register={register}
                placeholder='Select date from'
                //type='date'
                validation={{ required: true }}
                setValue={setValue}
                selectedValue={dateFrom || new Date()}
              />
            </Col>
            <Col md='2'>
            <FDateTime
                errors={errors}
                name='dateTo'
                label='Date To'
                filter={filter}
                register={register}
                placeholder='Select date from'
                //type='date'
                validation={{ required: true }}
                setValue={setValue}
                selectedValue={dateTo || new Date()}
              />
              {/* <FInput
                errors={errors}
                name='dateTo'
                label='Date To'
                handleOnChange={handleChange}
                register={register}
                placeholder='Select date to'
                type='date'
                validation={{ required: true }}
              /> */}
            </Col>
          </>
        }
        {show === 'isLead' && <Col md='2'>
          <InputField
            name='callStatus'
            label='Lead Status'
            placeholder='Enter Lead Status'
            value={params.get('callStatus') || ''}
            onChange={handleChange}
            elementType='select'
            option={[
              { text: 'ALL', value: '' },
              { text: 'New Lead', value: 'New Lead' },
              { text: 'Not contacted', value: 'Not contacted' },
              { text: 'Not Interested', value: 'Not Interested' },
              { text: 'Not Reachable', value: 'Not Reachable' },
              { text: 'Cold -  call later', value: 'Cold -  call later' },
              { text: 'Warm -  call after 10 days', value: 'Warm -  call after 10 days' },
              { text: 'Hot - Interested', value: 'Hot - Interested ' },
              { text: 'Wrong Number', value: 'Wrong Number' },
              { text: 'Invalid Contact', value: 'Invalid Contact' },
              { text: 'Converted', value: 'Converted' },
              { text: 'Do not call again', value: 'Do not call again' },
            ]}
          />
        </Col>
        }
        {show === 'isClient' && user.id && user.id !== 99 && <Col md='2'>
          <InputField
            name='entity'
            label='Entity'
            placeholder='Enter Entity'
            value={params.get('entity') || ''}
            onChange={handleEntity}
            elementType='select'
            option={[
              { text: 'ALL', value: '' },
              { text: 'FSA', value: 'FSA' },
              { text: 'FSC', value: 'FSC' },
              { text: 'SCA', value: 'SCA' },
              { text: 'FSA_MA', value: 'FSA_MA' }
            ]}
          />
        </Col>
        }
        {show === 'isClient' && <Col md='2'>
          <InputField
            name='isFunded'
            label='Deposit Client'
            value={params.get('isFunded') || ''}
            onChange={handleFunded}
            elementType='select'
            option={[
              { text: 'ALL', value: '' },
              { text: 'Deposit', value: '1' },
              { text: 'Non Deposit', value: '0' },
            ]}
          />
        </Col>
        }
        {show === 'isClient' && <Col md='2'>
          <InputField
            name='approvalStage'
            label='Stage'
            value={params.get('approvalStage') || ''}
            onChange={handleStage}
            elementType='select'
            option={[
              { text: 'ALL', value: '' },
              { text: 'New Application', value: 'New Application' },
              { text: 'Pending Documents', value: 'Pending Documents' },
              { text: 'Documents Approved', value: 'Documents Approved' },
              { text: 'Trade Enabled/Active', value: 'Trade Enabled/Active' },
              { text: 'KYC / CDD Approved', value: 'KYC / CDD Approved' },
            ]}
          />
        </Col>
        }
        {show === 'isClient' && <Col md='2'>
          <InputField
            name='accountStatus'
            label='Status'
            value={params.get('accountStatus') || ''}
            onChange={handleStatus}
            elementType='select'
            option={[
              { text: 'ALL', value: '' },
              { text: 'New', value: 'New' },
              { text: 'Pending', value: 'Pending' },
              { text: 'Rejected', value: 'Rejected' },
              { text: 'Approved', value: 'Approved' },
              { text: 'Active', value: 'Active' },
              { text: 'Frozen', value: 'Frozen' },
              { text: 'Closed', value: 'Closed ' },
              { text: 'Blacklisted', value: 'Blacklisted' },
              { text: 'Demo', value: 'Demo' },
            ]}
          />
        </Col>
        }
        {show === 'isIb' && <Col md='2'>
          <InputField
            name='kycStatus'
            label='KYC'
            placeholder='Enter KYC'
            value={params.get('kycStatus') || ''}
            onChange={handleKYC}
            elementType='select'
            option={[
              { text: 'ALL', value: '' },
              { text: 'Missing', value: 'missing' },
              { text: 'Approved', value: 'approved' },
              { text: 'Pending', value: 'pending' },
            ]}
          />
        </Col>}
        {show === 'isUser' && <Col md='3'>
          <InputField
            name='language'
            label='Language'
            placeholder='Select Language'
            value={params.get('language') || ''}
            onChange={handleLanguage}
            elementType='select'
            option={[
              { text: 'ALL', value: '' },
              { text: 'ENGLISH', value: 'ENGLISH' },
              { text: 'ARABIC', value: 'ARABIC' },
              { text: 'BOTH', value: 'BOTH' },
            ]}
          />
        </Col>}
        {show === 'isUser' &&
          <><Col md='3'>
            <InputField
              name='status'
              label='Status'
              placeholder='Select Status'
              value={params.get('status') || ''}
              onChange={handleUserStatus}
              elementType='select'
              option={[
                { text: 'ALL', value: '' },
                { text: 'Active', value: true },
                { text: 'Not Active', value: false },
              ]}
            />
          </Col>
            <Col md='3'>
              <FSelect
                control={control}
                label="Select Role"
                name="roleId"
                placeholder="Select Role"
                options={roleList}
                value={roleList.id}
                setValue={setRoleId}
                context="specificPage"
              />
            </Col>
          </>}
        <Col md='2' style={{display: 'none'}}>
          {/* <InputField
            name='parentId'
            label='IB / Direct Client'
            value={params.get('parentId') || ''}
            onChange={handleIbAndDirectClient}
            elementType='select'
            option={options}
          /> */}
          <FUserSelect
                control={control}
                label='IB / Direct Client'
                name='parentId'
                setValue={setIbInfo} // Updated to directly use setSearchValue
                value={rec || ''} // Set value from searchValue state
                agentName={rec || ''}
                //={handleFilter}
                context="specificPage"
              />
        </Col>
      </Row>
    </Fragment>
  );
};

export default Filter;
