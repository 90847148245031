import React from 'react';
import styles from './Editprofile.module.scss';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Form, InputGroup, ListGroup } from 'react-bootstrap';
import Select from 'react-select';
import { useSelector } from "react-redux";

import EditProfileForm from './EditProfileForm';
import ChangePasswordForm from './ChangePasswordForm';



const Editprofile = () => {


  const userProfile = useSelector(
    (state) => (state.login && state.login.profile ) || {}
  );


  return (

  <div className={styles.Editprofile}>
    <Row>
      <Col xl={4}>
        <Card>
          <Card.Header>
            <Card.Title>Change Password</Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="text-center chat-image mb-5">
              <div className="main-chat-msg-name">
                <Link to={`${process.env.PUBLIC_URL}/profile`}>
                  <h5 className="mb-1 text-dark fw-semibold">{userProfile.firstName +' ' + userProfile.lastName }</h5>
                </Link>
                <p className="text-muted mt-0 mb-0 pt-0 fs-13">{userProfile.profession}</p>
              </div>
            </div>
            <ChangePasswordForm />
          </Card.Body>
        </Card>
     
      </Col>
      <Col xl={8}>
        <EditProfileForm />
      </Col>
    </Row>
    {/* <!-- ROW-1 CLOSED --> */}
  </div>
)
};

export default Editprofile;
