import api from './api';
var queryString = (params) => {
    return Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
};

export const getAccounts = (customerId, page) => {
    console.log('hmm accounts are', customerId);
    return api.get('/v1/crm/accounts?customerId=' + customerId + '&page=' + page)
}
export const createAccount = (params) => {

    console.log(params, 'params are');
    return api.post('/v1/crm/accounts', params)
}

export const linkAccount = (params) => {
    console.log('link Account Params ', params);
    return api.post('/v1/crm/accounts/link', params)
}

export const getOpenPositions = (login, page = 1, limit = 10) => {
    return api.get('/v1/crm/accounts/' + login + '/open-positions', queryString({ page, limit }))
}

export const getClosePositions = (login, page = 1, limit = 5) => {
    console.log("loading ", login, page, limit);
    return api.get('/v1/crm/accounts/' + login + '/close-positions?' + queryString({ page, limit }))
}

export const setPayout = (balance, withdrawals, login) => {
    const params = {
        balance: balance,
        withdrawals: withdrawals,
        login: login,
    };
    console.log('link Account Params ', params);
    return api.post('/v1/crm/accounts/payout', params)
}
