import React, { Fragment, useEffect, useState } from 'react';
import { transactionsService, tradingAccountsService } from 'Services';
import { Table, Row, Col } from 'reactstrap';
import TableLoader from 'CommonElements/Table/TableLoader';
import TableNodata from 'CommonElements/Table/TableNodata';
import AddTransaction from './AddTransaction';
import TableRowStatus from 'CommonElements/Table/TableRowStatus';
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';
import WithoutFilterPagination from "CommonElements/Table/WithoutFilterPagination";

const Transactions = (props) => {
    const {
        customerId = '',
        activeTab, tabNumber
    } = props;
    const [rec, setRec] = useState({});
    const [accounts, setAccounts] = useState([]);
    const permissions = useSelector(
        (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
    );
    useEffect(() => {
        if (activeTab === tabNumber) {
            loadData();
            loadAccounts();
        }
    }, [activeTab, tabNumber]);
    const loadData = (page = 1) => {
        transactionsService.getTransactions({ customerId, page }).then((res) => {
            setRec({
                ...res,
                loading: false,
            })
        }).catch(err => {
        });
    }
    const loadAccounts = async () => {
        tradingAccountsService.getAccounts(customerId, true).then((res) => {
            if (res && res.data) {
                setAccounts(res.data)
            }

        }).catch(err => {
        });
    }
    return (
        <Fragment>
            <Row>
                <Col md={12} className="d-flex flex-row-reverse">
                    {getMenuPerm(permissions, 'transactions:create') === 1 &&
                        <AddTransaction customerId={customerId} accounts={accounts} loadData={loadData} />
                    }
                </Col>
            </Row>
            <div className="table-responsive">
                <Table hover striped>
                    <thead>
                        <tr>
                            <th scope="col">{'#'}</th>
                            <th scope="col">{'Type'}</th>
                            <th scope="col">{'Account'}</th>
                            <th scope="col">{'Date'}</th>
                            <th scope="col">{'Amount'}</th>
                            <th scope="col">{'Paid'}</th>
                            <th scope="col">{'Fee'}</th>
                            <th scope="col">{'Gateway'}</th>
                            <th scope="col">{'note'}</th>
                            <th scope="col">{'reason'}</th>
                            <th scope="col">{'Status'}</th>

                        </tr>
                    </thead>
                    {rec.loading && <TableLoader />}
                    {!rec.loading && rec.data && rec.data.length === 0 && <TableNodata title="Transactions" />}

                    <tbody>
                        {
                            rec.data && rec.data.map((item, id) =>
                                <tr key={id}>
                                    <th scope="row">{item.id}</th>
                                    <td >{item.type}</td>
                                    <td >{item['Account.login'] || `${item['AccountFrom.login']} -> ${item['AccountTo.login']}`}</td>
                                    <td>{new Date(item.createdAt).toDateString()}</td>
                                    <td >{item.amount}</td>
                                    <td >{item.paid}</td>
                                    <td >{item.fee}</td>
                                    <td >{item.gateway}</td>
                                    <td >{item.note}</td>
                                    <td >{item.reason}</td>
                                    <td >
                                        <TableRowStatus status={item.status} />
                                    </td>

                                </tr>
                            )
                        }
                    </tbody>
                </Table>
                <WithoutFilterPagination data={rec} loadData={loadData} />
            </div>
        </Fragment>
    );
};
export default Transactions;