import React, { Fragment, useEffect, useState } from 'react';
import { customerNotesService, tradingAccountsService } from 'Services';
import { Table, Row, Col } from 'reactstrap';
import TableLoader from 'CommonElements/Table/TableLoader';
import TableNodata from 'CommonElements/Table/TableNodata';
import AddNote from './AddNotes';
import TableRowStatus from 'CommonElements/Table/TableRowStatus';
import { useSelector } from 'react-redux';
import { getMenuPerm } from 'utils';
import TablePagination from "CommonElements/Table/WithoutFilterPagination";

const Index = (props) => {
  const { customerId = '', activeTab, tabNumber } = props;
  const [rec, setRec] = useState({});
  const [accounts, setAccounts] = useState([]);
  const permissions = useSelector(
    (state) =>
      (state.login &&
        state.login.profile &&
        state.login.profile.Role &&
        state.login.profile.Role.permissions) ||
      {}
  );
  useEffect(() => {
    if (activeTab === tabNumber) {
      loadData();
      loadAccounts();
    }
  }, [activeTab, tabNumber]);
  const loadData = (page = 1) => {
    customerNotesService
      .getCustomerNotes({ clientId: customerId, page })
      .then((res) => {
        setRec({
          ...res,
          loading: false,
        });
      })
      .catch((err) => {
      });
  };
  const loadAccounts = async () => {
    tradingAccountsService
      .getAccounts(customerId, true)
      .then((res) => {
        if (res && res.data) {
          setAccounts(res.data);
        }
      })
      .catch((err) => {
      });
  };
  const formatTimeInDubaiZone = (dateTimeString, isReminder) => {
    if (!dateTimeString || isReminder === 0) {
      return 'N/A';
    }

    const localTime = new Date(dateTimeString);
    const dubaiTime = new Date(localTime);
    dubaiTime.setUTCHours(localTime.getUTCHours()); // Adding 4 hours to convert to Dubai time

    return dubaiTime.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      //timeZoneName: 'short',
    });
  }
  return (
    <Fragment>
      <Row>
        <Col md={12} className='d-flex flex-row-reverse'>
          {getMenuPerm(permissions, 'transactions:create') === 1 && (
            <AddNote
              customerId={customerId}
              accounts={accounts}
              loadData={loadData}
            />
          )}
        </Col>
      </Row>
      <div className='table-responsive'>
        <Table hover>
          <thead>
            <tr>
              <th scope='col'>{'#'}</th>
              <th scope='col'>{'Note'}</th>
              <th scope='col'>{'Is Reminder'}</th>
              <th scope='col'>{'Reminder Time'}</th>
              <th scope='col'>{'Created By'}</th>
            </tr>
          </thead>
          {rec.loading && <TableLoader />}
          {!rec.loading && rec.data && rec.data.length === 0 && (
            <TableNodata title='Transactions' />
          )}

          <tbody>
            {rec.data &&
              rec.data.map((item, id) => (
                item.note !== null && <tr key={id}>
                  <th scope='row'>{item.id}</th>
                  <td>{item.note}</td>
                  <td>{item.isReminder === 1 ? 'Yes' : 'No'}</td>
                  <td>{formatTimeInDubaiZone(item.remainderTime, item.isReminder)}</td>
                  <td>{item['User.firstName'] + ' ' + item['User.lastName']}</td>
                </tr>
              ))}
          </tbody>
        </Table>
        <TablePagination data={rec} loadData={loadData} />

      </div>
    </Fragment>
  );
};
export default Index;
