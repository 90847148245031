import React, { Fragment, useState } from 'react';
import { Label, FormGroup } from 'reactstrap';
import { Controller } from 'react-hook-form';
import { clientService, userService } from 'Services';
import AsyncSelect from 'react-select/async';
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';

const appendParameterToUrl = (key, value) => {
  // Get the current URL
  const currentUrl = window.location.href;

  // Create a URL object
  const url = new URL(currentUrl);

  // Check if the parameter exists
  if (url.searchParams.has(key)) {
    // Update the existing parameter
    url.searchParams.set(key, value);
  } else {
    // Append the new parameter
    url.searchParams.append(key, value);
  }

  // Update the browser's address bar
  window.history.pushState(null, '', url.toString());
  console.log("Updated URL:", url.toString());
};

const FormInput = ({
    errors = {},
    label = '',
    name = '',
    validation={},
    value: defValue,
    value,
    setValue = () => {},
    control,
    defaultOptions=[],
    context ,
    agentName,
    onChange,
}) => {
  const [, setSelectedValue] = useState( null);

  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  );

  // handle selection
  const handleChange = e => {
    const selectedId = e.id;
  
    // Set the selected value
    setSelectedValue(e);
  
    // Append parameter to URL if context exists
    if (context) {
      appendParameterToUrl(name, selectedId);
  
      setValue((prevValues) => ({
        ...prevValues,
        [name]: selectedId,
      }));
    } else {
      // Directly set the value without context
      setValue(name, selectedId);
    }
  };

  /*const handleChange = (selectedOption) => {
    setSelectedValue(selectedOption);
    setValue((prevValues) => ({
      ...prevValues,
      [name]: selectedOption.id,
    }));
  };*/
  
  // load options using API call
  const loadOptions = (inputValue) => {
    
    if (name === 'agentId' || name === 'saleUserId') {
      return new Promise(async(resolve , reject) => {
        return userService.getUsersDropdown(inputValue).then(res => {
          return resolve(res);
        }).catch(err => {
          resolve([])
        });
      })

    } else {

      return new Promise(async(resolve, reject) => {
        return clientService.getCustomersDropdown(inputValue).then(res => {
          return resolve(res);
        }).catch(err => {
          resolve([])
        });
      })
    }

  };
  return (
    <Fragment>
        <FormGroup>
            <Label className="form-label" for={name}>{label}</Label>
          {control && 
            <Controller
                  control={control}
                  defaultValue={defValue}
                  value={defValue}
                  name={name}
                  id={name}
                  // className="form-select" 
                  isSearchable={true}
                  isClearable
                  // className="react-dropdown"
                  // classNamePrefix="dropdown"
                  // {...register(name, validation)}
                  rules={validation}
                  render={({ 
                    field: { onChange, onBlur, value, name, ref }
                  }) => (
                    <AsyncSelect
                    // defaultInputValue={defValue && defValue.id}
                    cacheOptions
                    defaultOptions={defaultOptions}
                    isDisabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                    // value={selectedValue}
                    value={defaultOptions && defaultOptions.find(c => c.id === value)}
                    getOptionLabel={e => `${e.firstName} ${e.lastName}`}
                    getOptionValue={e => e.id}
                    loadOptions={loadOptions}
                    // onInputChange={handleUpdate}
                    //onChange={(e)=>{onChange(e); handleChange(e)}}
                    onChange={(selectedOption) => { onChange(selectedOption); handleChange(selectedOption); }}
                    classNamePrefix={ name + '_' }
                    placeholder = {
                      agentName ? agentName : 'Select...'
                    }
                  />
                      
                  )}
              />
            }
            <span className='error'>{errors[name] && `${label} is ${errors[name].type}`}</span>
        </FormGroup>
    </Fragment>
  );
};
export default FormInput;
