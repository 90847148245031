import api from "./api";
var queryString = (params) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};
export const getvideos = (params={}) => {
  return api.get("v1/crm/videos?" + queryString(params));
};

export const deleteVideo = (id) => {
  return api.delete("v1/crm/videos/" + id);
};

export const addVideo = (params) => {
  console.log(params);
  return api.post("v1/crm/videos", params,{
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const updateVideo = (id, params) => {
  console.log('update Video data ',params);
  //return api.patch("v1/crm/videos/"+id, params);
  return api.patch("v1/crm/videos/"+id, params,{
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};
