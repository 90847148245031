import React, { Fragment, useEffect, useState } from "react";
import { Label } from "reactstrap";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
const FDate = ({
  errors = {},
  label = "",
  name = "",
  register = () => {},
  type = "text",
  placeholder = "",
  value,
  setValue = () => {},
  validation = {},
  disabled,
  selectedValue,
  maxDate,
  minDate,
}) => {
  const [selectedValueDate, setSelectedValueDate] = useState(null);
  const [time, onChangeTime] = useState('00:00');
  const handleChange = (e) => {
    console.log("new value ", e);
    setSelectedValueDate(e);
    setValue(name, e);
  };

  const handleChangeDate = (e) => {
    setSelectedValueDate(e);
  };

  const handleChangeTime = (e) => {
    onChangeTime(e);
  };

  useEffect(() => {
    if (selectedValue !== undefined && selectedValue != null && selectedValueDate) {
      // Get the date and time values
      const dateValue = selectedValueDate;
      const timeValue = time;
      
      // Extract hours and minutes from the time string
      const [hours, minutes] = timeValue.split(":");
      
      // Create a new Date object with the selected date and time
      const formattedDateTime = new Date(
        dateValue.getFullYear(),
        dateValue.getMonth(),
        dateValue.getDate(),
        hours,
        minutes
      );
      //setSelectedValueDate(formattedDateTime);
  
      setValue(name, formattedDateTime);
    }
  }, [selectedValue, selectedValueDate, time]);
  
  
  return (
    <Fragment>
      <Label className="form-label" for="validationCustom01">
        {label}
      </Label>
      <DatePicker
        maxDate={maxDate}
        minDate={minDate}
        className="form-control digits"
        selected={selectedValueDate == null ? selectedValue : selectedValueDate}
        autoComplete="off"
        {...register(name, validation)}
        type={type}
        value={value}
        placeholder={placeholder}
        name={name}
        onChange={handleChangeDate}
        disabled={disabled}
      />
      <br></br>
      {selectedValueDate && (
        <TimePicker className="form-control"
          onChange={handleChangeTime}
          amPmAriaLabel
          disableClock
          required
          clearIcon= {null}
          value={time}/>
      )}
      {errors[name]?<span>{errors[name] && `${label} is ${errors[name].type}`}</span>:''}
      <div className="valid-feedback">Looks good!</div>
    </Fragment>
  );
};
export default FDate;
