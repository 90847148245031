import {
  Home,
  Settings,
  Layers,
  Sliders,
  Users,
  UserMinus,
  Database,
} from "react-feather";
export const MenuSearchItems = [
  {
    menutitle: "Sample",
    menucontent: "All neccesory Sample added",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard`,
        icon: Home,
        title: "Dashboard",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/leads`,
        icon: UserMinus,
        title: "Leads",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/clients`,
        icon: Users,
        title: "Clients",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/ibs`,
        icon: Users,
        title: "IBs",
        type: "link",
      },
      {
        title: "Transactions",
        icon: Sliders,
        type: "sub",
        badge2: true,
        active: false,
        children: [
          {
            title: "Deposits",
            path: `${process.env.PUBLIC_URL}/deposits`,
            type: "link",
          },
          {
            title: "Withdrawals",
            path: `${process.env.PUBLIC_URL}/withdrawals`,
            type: "link",
          },
          {
            title: "Internal Transfers",
            path: `${process.env.PUBLIC_URL}/internal-transfers`,
            type: "link",
          },
        ],
      },
      {
        title: "Requests",
        icon: Layers,
        type: "sub",
        badge2: true,
        active: false,
        children: [
          {
            title: "IB Requests",
            path: `${process.env.PUBLIC_URL}/ib-requests`,
            type: "link",
          },
          {
            title: "Account Requests",
            path: `${process.env.PUBLIC_URL}/account-requests`,
            type: "link",
          },
        ],
      },
      {
        path: `${process.env.PUBLIC_URL}/tickets`,
        icon: Layers,
        title: "Tickets",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/reports`,
        icon: Database,
        title: "Reports",
        type: "link",
      },

      {
        title: "Settings",
        icon: Settings,
        type: "sub",
        badge2: true,
        active: false,
        children: [
          {
            title: "Users",
            path: `${process.env.PUBLIC_URL}/users`,
            type: "link",
          },
          {
            title: "Roles",
            path: `${process.env.PUBLIC_URL}/roles`,
            type: "link",
          },
          {
            title: "Groups",
            path: `${process.env.PUBLIC_URL}/groups`,
            type: "link",
          },
          {
            title: "Account Types",
            path: `${process.env.PUBLIC_URL}/account-types`,
            type: "link",
          },
          {
            title: "Banners",
            path: `${process.env.PUBLIC_URL}/banners`,
            type: "link",
          },
          {
            title: "System Emails",
            path: `${process.env.PUBLIC_URL}/system-emails`,
            type: "link",
          },
          {
            title: "User Logs",
            path: `${process.env.PUBLIC_URL}/user-logs`,
            type: "link",
          },
        ],
      },
    ],
  },
];
