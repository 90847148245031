import React, { Fragment, useState } from "react";
import { Col, Form, Row, FormGroup, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";

import { videosService } from 'Services';
import TableRowEdit from 'CommonElements/Table/TableRowEdit';
import CommonModal from "Components/Common/Data/Ui-kits/Modals/common/modal";
import { FInput, FSelect } from 'CommonElements/Form';
import { Btn } from "AbstractElements";

const EditVideo = ({ data={},  loadData  }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal(!modal)
    setTimeout(()=>{
        setFormFields();
    },1)
  };
  const { register, handleSubmit, formState: { errors }, control,setValue } = useForm();
  
  // for adding data values to form
  const setFormFields = () => {
    setValue('gallery', data.gallery);
    setValue('url', data.url);
    setValue('fileUrl', data.fileUrl);
    
  }

  const modalSubmit = formValues => {
    if (formValues !== '') {
        setLoading(true);
        
        var formData = new FormData();
        formData.append("gallery", formValues.gallery);
        formData.append("url", formValues.url);
        if(formValues['fileUrl'] && formValues['fileUrl'][0]) {
            formData.append("fileUrl", formValues['fileUrl'][0]);
        }
        
        //formData.append("fileUrl", formValues['fileUrl']);


        videosService.updateVideo(data.id, formData).then(res => {
            setLoading(false);
            toggle();
            toast.success('Video updated, successfully');
            loadData();
        }).catch(err => {
            setLoading(false);
            toast.error('Failed to update Video');
        });
    } else {
        errors.showMessages();
    }
};
const handleChange = (e) => {
    setValue(e.target.name, e.target.files);
}
const formValueChange = (name, value) => {
    setValue(name , value);
  }
  return (
    <Fragment>
        <TableRowEdit onClick={toggle} />

        <CommonModal
            isOpen={modal}
            title={'Edit Video'}
            toggler={toggle}
            formSubmit={()=>{}}
            hideFooter={true}
            size={'lg'}
        >
            {/* {JSON.stringify(data.permissions)} */}
            <Form  className="needs-validation" noValidate="" 
                onSubmit={handleSubmit(modalSubmit)}
            >
                     <Row>  
                        <Col md='12 mb-3' >
                            <FSelect
                                control={control}
                                errors={errors}
                                label="Gallery"
                                name="gallery"
                                register={register}
                                value={data.gallery}
                                placeholder="Gallery Select"
                                options={[{ name: "Webinars", value: "Webinars" },{ name: "Market Overview", value: "MarketOverview" },{ name: "Technical Analysis", value: "TechnicalAnalysis" }]}
                                validation={{ required: true }}
                                setValue={formValueChange}
                                />
                        </Col>
                        
                        <Col md='12 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'URL'
                                name = 'url'
                                register = {register}
                                placeholder= 'Enter Url'
                                validation={{required: false}}
                            />
                        </Col>
                        <Col md='12 mb-3' >
                            <FormGroup className="row">
                                <Label className="col-sm-3 col-form-label">{'Image'}</Label>
                                <Col sm="9">
                                    <input onChange={handleChange} name="fileUrl" className="form-control" type="file" />
                                </Col>
                            </FormGroup>
                        </Col>
                        
                       
                        
                        <Col md={12} className="d-flex flex-row-reverse">
                            <Btn disabled={loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn>
                        </Col>
                        
                    </Row>

                    {/* <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn> */}
                </Form>
        </CommonModal>
    </Fragment>
  );
};

export default EditVideo;
