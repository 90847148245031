import React from 'react';
import { Badges } from 'AbstractElements';

const TableRowEdit = ({ status = '', name = '' }) => {
    let clr = '';
    switch (status) {
        case 'Pending':
        case 'PENDING':
        case 'pending':
            clr = 'primary';
            break;
        case 'Approved':
        case 'APPROVED':
        case 'approved':
        case 'Active':
            clr = 'success';
            break;
        case 'Rejected':
        case 'REJECTED':
        case 'Expired':
        case 'EXPIRED':
        case 'rejected':
            clr = 'danger';
            break;
        default:
            clr = 'primary';
            break;
    }

    const statusValue =
        status === 'pending' ? 'Pending Documents' :
            status === 'new' || status === 'New' ? 'New Case' :
                status === 'approved' ? 'KYC Approved' :
                    'Missing';

    const IBStatus = 
        status ===  'pending' ? 'Pending' :
        status ===  'rejected' ? 'Rejected' :
        status ===  'terminated' ? 'Terminated' :
        'Active';          


    return (
        <React.Fragment>
            <Badges attrBadge={{ color: clr }} >{name === 'KYC' ? statusValue : name === 'IBStatus' ? IBStatus : status}</Badges>
        </React.Fragment>
    );
};

export default TableRowEdit;