import React, { useState, Fragment, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AddNewTask, AddTask, Close, Search } from "../../Constant";
import { Check, Trash2, Edit } from "react-feather";
import { Btn, H4, LI, UL } from "../../AbstractElements";
import EditModalButton from "../Todo/editModalButton";
import SweetAlert from 'sweetalert2';
import { FormGroup, Button, Table } from "reactstrap";
import { userService, ticketService, accountService, documentsService } from "Services";
import { Col, Form, Row, Container } from "reactstrap";
import TableLoader from "CommonElements/Table/TableLoader";
import { FInput, FSelect, FTextArea } from "CommonElements/Form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

import TablePagination from "CommonElements/Table/WithoutFilterPagination";
import data from './data.json';
// Access the requestTypes array
const requestTypes = data.requestTypes;
const requestStatus = data.requestStatus;
const subType = data.subType;

const TodoList = (props) => {
  const { data = {} } = props;
  const [users, setUsers] = useState(null);
  const [assignToList, setAssignToList] = useState([]);
  const [ticketList, setTicketList] = useState([]);
  const [clientType, setClientType] = useState('');
  const [clientName, setClientName] = useState('');
  const [requestOwner, setRequestOwner] = useState('');
  const [modifiedBy, setModifiedBy] = useState('');
  const [accountStatus, setAccountStatus] = useState('');
  const [accountNo, setAccountNo] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [submitState, setSubmitState] = useState({
    loading: false,
  });
  const userProfile = useSelector(
    (state) => (state.login && state.login.profile) || {}
  );
  const [formData, setFormData] = useState({
    // Initialize your form data here
    login: '',
    // other fields...
  });
  useEffect(() => {
    if (userProfile.firstName && userProfile.lastName) {
      // Assuming userProfile has firstName and lastName properties
      setRequestOwner(userProfile.firstName + ' ' + userProfile.lastName);
      setModifiedBy(userProfile.firstName + ' ' + userProfile.lastName);
    }
  }, [userProfile]); // This effect will run whenever userProfile changes
  const [accounts, setAccount] = useState([]);

  const [rec, setRec] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
    reset,
  } = useForm();
  useEffect(() => {
    const formFields = getValues();
    Object.keys(formFields).forEach((key) => {
      if (data[key]) {
        setValue(key, data[key]);
      }
    });


    getUserList();
    getTicketList(1);
    //getAccountList();
  }, []);

  const getUserList = () => {
    var limit = 100;
    setRec({
      loading: true,
      data: [],
    });
    userService
      .getUsers({
        limit,
      })
      .then((res) => {
        console.log('users data is ', res.data);
        setUsers(res.data);
      })
      .catch((err) => {
        console.log("err getUser ", err);
      });
  };


  const getTicketList = (page = 1) => {
    setRec({
      loading: true,
      data: [],
    });
    ticketService
      .getTickets({ page })
      .then((res) => {
        const formattedData = res.data.map((ticket) => ({
          ...ticket,
          createdAt: new Date(ticket.createdAt).toLocaleDateString(),
          updatedAt: new Date(ticket.updatedAt).toLocaleDateString(),
        }));
        setRec({
          loading: false,
          ...res,
        });
      })
      .catch((err) => {
        console.log("err getTickets ", err);
      });
    console.log('data is dsaf', rec);
  };
  useEffect(() => {
    if (users !== null && users !== undefined && users.length > 0) {
      var optionArr = [];
      users.map((opt) =>
        optionArr.push({
          name: opt.firstName + " " + opt.lastName + " - " + opt["Role.title"],
          value: opt.id,
        })
      );
      setAssignToList(optionArr);
      console.log("optionArr" + optionArr);
    }
  }, [users]);

  const tableRef = useRef(null); // Create a ref for the table
  const editBtn = (e, rowData) => {
    e.preventDefault();
    // Get the button element with the ID "changeTextButton"
    //const changeTextButton = document.getElementById('submitBtn');
    //changeTextButton.textContent = 'Update Task';
    if (tableRef.current) {
      tableRef.current.scrollIntoView({
        behavior: 'smooth', // Use 'auto' for instant scrolling
        block: 'start', // Scroll to the top of the table
      });
    }
    if (rowData !== undefined && rowData !== null && rowData.id !== 0) {
      const formFields = getValues();
      setSelectedRow(rowData);
      console.log('edited data is ', rowData);
      setAccountNo(rowData.tag_tp_acc_no);
      Object.keys(formFields).forEach((key) => {
        if (rowData[key]) {
          if (key === "startTime") {
            var newDate = new Date(rowData[key]);
            const dateOptions = {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            };
            const formattedDate = newDate.toLocaleDateString(
              "en-CA",
              dateOptions
            );
            setValue(key, formattedDate);
          } else {
            setValue(key, rowData[key]);
          }
        }
        setAddTask(" visible");
        //document.getElementById("addTaskBtn").classList.add("hide");
      });
    }
  };
  const resetTicketData = () => {
    clearErrors();
    reset({
      request_owner: "",
      request_mode: "",
      request_category: "",
      request_type: "",
      request_status: "",
      assignTo: "",
      sub_type: "",
      description: "",
      priority: "",
      modified_by: "",
      tag_c_type: "",
      tag_c_name: "",
      tag_c_acc_status: "",
      tag_tp_acc_no: "",
      tag_ib_name: "",
      tag_ib_no: "",
      imageUrl: "",
    });
    setSelectedRow(null);
  };
  const objectReturn = (data) => {
    console.log('update ');
    return {
      request_owner: requestOwner,
      request_mode: data.request_mode,
      request_category: data.request_category,
      request_type: data.request_type,
      request_status: data.request_status,
      assignTo: data.assignTo,
      sub_type: data.sub_type,
      action: data.action,
      description: data.description,
      priority: data.priority,
      modified_by: modifiedBy,
      tag_c_type: clientType,
      tag_c_name: clientName,
      tag_c_acc_status: accountStatus !== null ? accountStatus : "",
      tag_tp_acc_no: accountNo,
      //customerId: customerId,
      tag_ib_name: "",
      tag_ib_no: "",
    };
  };
  const onSubmit = (data) => {
    var payload = objectReturn(data);
    console.log('data is', payload);
    if (payload !== "" && selectedRow == null) {
      setSubmitState({ loading: true });
      const formData = new FormData();

      // Assuming data is an object containing file data
      Object.keys(data).forEach((fieldKey) => {
        if (fieldKey === "imageUrl" && data[fieldKey]) {
          // If data[fieldKey] is an array (multiple files), loop through it
          for (let i = 0; i < data[fieldKey].length; i++) {
            const fileData = data[fieldKey][i];
            formData.append("imageUrl", fileData);
          }
        }
      });

      // Append payload to formData
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });

      console.log('combined form data is', formData);

      ticketService.postTicketImage(formData)
        .then((res) => {
          setSubmitState({
            loading: false,
            status: 'Documents uploaded successfully',
          });

          getTicketList();
          closeTaskWrapper();
          resetTicketData();
          toast.success("Ticket Added Successfully");
        })
        .catch((err) => {
          setSubmitState({
            loading: false,
            status: 'Error in uploading documents',
          });

          toast.error("Ticket Not Added");
        });
    } else {
      const formData = new FormData();

      formData.append("imageUrl", data['imageUrl'][0]);
      formData.delete('customerId');
      // Append payload to formData
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });

      console.log('combined form data is for updation', formData);

      // Pass payload as the first argument to updateTicket
      updateTicket(formData, selectedRow.id);
      resetTicketData();
    }
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    // Handle the search form submission here
    console.log('Search button clicked');
    console.log(formData.login); // Access the form data

    accountService
      .getAccountSearch(formData.login)
      .then((res) => {
        console.log('Accounts data is ', res);
        setClientName(res.Customer.firstName + ' ' + res.Customer.lastName);
        setAccountStatus(res.Customer.accountStatus);
        setAccountNo(res.login);
        setClientType(res.AccountType.type);
        setCustomerId(res.Customer.id);
      })
      .catch((err) => {
        setSubmitState({
          loading: false,
        });
        console.log('eeror fetching data');
      });
    console.log(clientName, accountStatus, accountNo, customerId);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const updateTicket = (formData, selectedRowId) => {
    ticketService
      .updateTicket(formData, selectedRowId)
      .then((res) => {
        getTicketList();
        toast.success("Ticket Update Successfully");
        closeTaskWrapper();
      })
      .catch((err) => {
        setSubmitState({
          loading: false,
        });
        toast.error("Ticket Not Updated ");
      });
  };

  const [addTask, setAddTask] = useState("");
  const border_danger = useState("");
  const [status, setStatus] = useState("pending");

  const handleRemoveTodo = (todoId) => {
    Swal.fire({
      title: "Are you sure you want to delete this item?",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      reverseButtons: true,
      showCancelButton: true,
      text: "Once deleted, you will not be able to get back this request!",
      icon: "error",
    }).then((res) => {
      if (res.isConfirmed) {
        ticketService
          .deleteTicket(todoId)
          .then((res) => {
            getTicketList();
            setTicketList(ticketList.filter((data) => data.id !== todoId));
            toast.success("Deleted Ticket !");
          })
          .catch((err) => {
            setSubmitState({
              loading: false,
            });
            toast.error("Ticket Not Delete ");
          });
      }
    });
  };
  const findObjectIndex = (id) => {
    ticketList.findIndex(function (c) {
      return c.id === id;
    });
  };
  const updateObject = (id, newData) => {
    const index = findObjectIndex(id);
    if (index === -1) return;
    const updatedList = [...ticketList];
    updatedList[index] = { ...updatedList[index], ...newData };
    setTicketList(updatedList);
  };
  const handleMarkedTodo = (itemId, itemStatus) => {
    const confirmed = window.confirm('Are you sure you want to complete this item?');
    if (confirmed) {
      var data = rec.data.filter((obj) => {
        return obj.id === itemId;
      });
      var updateStatus = data[0].request_status === "completed" ? "pending" : "completed";
      data[0].request_status = updateStatus;
      var updatedRecord = data[0];
      updateObject(updatedRecord.id, updatedRecord);
      var payloadObj = objectReturn(updatedRecord);
      updateTicket(payloadObj, updatedRecord.id);
      if (updateStatus === "completed") {
        setStatus("pending");
        toast.success("Task Completed !");
      } else if (updateStatus === "pending") {
        setStatus("completed");
        toast.error(" Task In-completed !");
      }
    }
  };
  const openTaskWrapper = () => {
    if (selectedRow !== null) {
      resetTicketData();
    }
    setAddTask(" visible");

    const changeTextButton = document.getElementById('submitBtn');
    changeTextButton.textContent = 'Add Task';
    document.getElementById("addTaskBtn").classList.add("hide");
  };
  const closeTaskWrapper = () => {
    resetTicketData();
    setAddTask("");
    document.getElementById("addTaskBtn").classList.remove("hide");
  };
  console.log(assignToList);


  const categories = [
    { name: 'Finance' },
    { name: 'Onboarding' },
    { name: 'Operation' }
  ];




  const [hiddenDetailsIndex, setHiddenDetailsIndex] = useState(null);
  const toggleDetails = (index) => {
    console.log('index', index);
    if (hiddenDetailsIndex === index) {
      // If the same row is clicked again, hide its details
      setHiddenDetailsIndex(null);
    } else {
      // Otherwise, show the details of the clicked row
      setHiddenDetailsIndex(index);
    }
  };




  // Define state for selected category and type
  const [selectedCategory, setSelectedCategory] = useState("Finance");
  const [selectedType, setSelectedType] = useState("");
  const [searchQuery, setSearchQuery] = useState('');

  const handleCategoryChange = (name, value) => {
    setValue(name, value);
    //setSelectedCategory(value);
    console.log(selectedCategory);
    if (name === 'request_category') {
      setSelectedCategory(value);
    }
  }
  const handleChange = (e) => {
    setSelectedFile(e.target.files[0]);
  }
  const getUrl = async (item, num = 1) => {
    ticketService.getDocumentSas(item).then(res => {
      console.log('res ', res);
      if (res && res.url) {
        window.open(res.url, '_blank')
      } else {
        toast.error('Failed to generate Link');
      }

    }).catch(err => {
      toast.error('Failed to generate Link');

    })
    return false;
  }
  const priorityColors = {
    High: 'high-priority',
    Normal: 'normal-priority',
    Low: 'low-priority',
  };

  const [filter, setFilter] = useState('');
  const [filteredData, setFilteredData] = useState(rec);

  const handleFilterChange = (event) => {
    const inputValue = event.target.value;
    setFilter(inputValue);
  };

  // Watch for changes in rec and filter, and update filteredData
  useEffect(() => {
    // Filter rec based on the input value
    const newFilteredData = rec && rec.data && rec.data.filter((item) => {
      return (item && item.id && item.id.toString().includes(filter)) || (item && item.tag_tp_acc_no && item.tag_tp_acc_no.toString().includes(filter)) ||
        (item && item["assigned.firstName"] &&
          item["assigned.firstName"].toLowerCase().includes(filter.toLowerCase())) ||
        (item && item.priority &&
          item.priority.toLowerCase().includes(filter.toLowerCase())) ||
        (item && item.request_status &&
          item.request_status.toLowerCase().includes(filter.toLowerCase()));
    });

    // Update the filtered data
    setFilteredData(newFilteredData);
  }, [rec, filter]);


  return (
    <Fragment>
      <input
        className="form-control"
        type="text"
        value={filter}
        style={{ width: '30%', top: '15px', position: 'absolute', right: '180px' }}
        onChange={handleFilterChange}
        placeholder="Search..."
      />

      {rec.loading && <TableLoader />}
      <div className="table-responsive">

        <Table hover striped>
          <thead>
            <tr>
              <th scope="col">{"Ticket No"}</th>
              <th scope="col">{"Category"}</th>
              <th scope="col">{"Request"}</th>
              <th scope="col">{"Assign To"}</th>
              <th scope="col">{"Account No"}</th>
              <th scope="col">{"Priority"}</th>
              <th scope="col">{"Status"}</th>
              <th scope="col">{"Action"}</th>
            </tr>
          </thead>
          <tbody class="tableBody">
            {filteredData && filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <th scope="row">{item.id}</th>
                    <td>{item.request_category}</td>
                    <td>{item.request_type}</td>
                    <td>{item["assigned.firstName"] + " " + item["assigned.lastName"]}</td>
                    <td>{item.tag_tp_acc_no}</td>
                    <td>
                      <span className={priorityColors[item.priority] || 'inherit'}>
                        {item.priority}
                      </span></td>
                    <td>{item.request_status}</td>
                    <td>
                      {/*<span
                              className="action-box large delete-btn"
                              title="Edit Task"
                              onClick={(e) => editBtn(e, item)}
                            >
                              <Edit />
                              
                            </span>*/}
                      <EditModalButton btnText='Edit Task' item={item} row={item.id} loadData={getTicketList} defaultVal='Godo' />
                      <span
                        className="action-box large delete-btn"
                        title="Delete Task"
                        onClick={() => handleRemoveTodo(item.id)}
                      >
                        <Trash2 />
                      </span>
                      <span
                        className="action-box large complete-btn"
                        title="Mark Complete"
                        onClick={() => handleMarkedTodo(item.id, status)}
                      >
                        <Check />
                      </span>
                      <span
                        className='fa fa-eye toggle-details'
                        style={{
                          position: 'absolute',
                          cursor: 'pointer',
                          marginLeft: '10px',
                        }}
                        onClick={() => toggleDetails(index)}
                      >
                      </span>
                    </td>
                  </tr>
                  {hiddenDetailsIndex === index && (
                    <tr>
                      <td colSpan="12"> {/* Adjust the colspan to match the number of columns in your main table */}
                        <table width="100%">
                          <tbody>
                            <tr>
                              <td><strong>Request Owner:</strong> {item.request_owner}</td>
                              <td><strong>Request Mode:</strong> {item.request_mode}</td>
                              <td><strong>Priority:</strong> {item.priority}</td>
                            </tr>
                            <tr>
                              <td><strong>Modified By:</strong> {item.modified_by}</td>
                              <td><strong>Client Type:</strong> {item.tag_c_type}</td>
                              <td><strong>Client Name:</strong> {item.tag_c_name}</td>
                            </tr>
                            <tr>
                              <td><strong>Account Status:</strong> {item.tag_c_acc_status}</td>
                              <td><strong>Created At:</strong> {new Date(item.createdAt).toLocaleDateString()}</td>
                              <td><strong>Modified At:</strong> {new Date(item.updatedAt).toLocaleDateString()}</td>
                            </tr>
                            <tr>
                              <td><strong>Sub Type:</strong> {item.sub_type}</td>
                              <td><strong>Description:</strong> {item.description}</td>
                            </tr>
                            {item.imageUrl ?
                              <tr>
                                <td><p className='m-0 text-decoration-underline' style={{ cursor: 'pointer' }} onClick={() => { getUrl(item, 1) }} target='_blank' rel="noreferrer">Attachment</p></td>
                              </tr> : ''}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              ''
            )}
          </tbody>

        </Table>
        <TablePagination data={rec} loadData={getTicketList} />
      </div>
      <div className="todo-list-footer">
        {/*<div className="add-task-btn-wrapper">
            <span className="add-task-btn" id="addTaskBtn">
              <Btn attrBtn={{ color: "primary", onClick: openTaskWrapper }}>
                {" "}
                {AddNewTask}
              </Btn>
            </span>
                  </div>*/}
        <div className={"new-task-wrapper" + addTask} ref={tableRef}>
          <Form
            className="needs-validation"
            noValidate=""
          >
            <Container>
              <FormGroup className="form-group row">

                <Col md="4 mb-3">
                  <input className="form-control"
                    control={control}
                    errors={errors}
                    name="login"
                    register={register}
                    placeholder="Enter Login No"
                    validation={{ required: true }}
                    onChange={handleInputChange}
                  />
                </Col>
                <Col md="4 mb-2">
                  <Button
                    className="ms-3 add-new-task-btn"
                    color="success"
                    onClick={handleSearchSubmit}
                  >
                    {Search}
                  </Button>
                </Col>

              </FormGroup>
            </Container>

          </Form>
          <Form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit(onSubmit)}
          >
            <Container>
              <FormGroup className="form-group row">
                <Col md="4 mb-3">
                  <FInput
                    type='text'
                    errors={errors}
                    label='Request Owner:'
                    name='request_owner'
                    register={register}
                    placeholder={requestOwner}
                    value={requestOwner}
                    defaultValue={requestOwner}
                    setValue={setValue}
                    validation={{ required: false }}
                    disabled={{ disabled: true }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <FInput
                    type='text'
                    errors={errors}
                    label='Request Modified:'
                    name='modified_by'
                    register={register}
                    placeholder={modifiedBy}
                    value={modifiedBy}
                    defaultValue={modifiedBy}
                    setValue={setValue}
                    validation={{ required: false }}
                    disabled={{ disabled: true }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <FInput
                    type='text'
                    errors={errors}
                    label='Account Type:'
                    name='tag_c_type'
                    register={register}
                    placeholder={clientType}
                    value={clientType}
                    defaultValue={clientType}
                    setValue={setValue}
                    validation={{ required: false }}
                    disabled={{ disabled: true }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <FInput
                    type='text'
                    errors={errors}
                    label='Client Name:'
                    name='tag_c_name'
                    register={register}
                    placeholder={clientName}
                    value={clientName}
                    defaultValue={clientName}
                    setValue={setValue}
                    validation={{ required: false }}
                    disabled={{ disabled: true }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <FInput
                    type='text'
                    errors={errors}
                    label='Account Status:'
                    name='c_account_status'
                    register={register}
                    placeholder={accountStatus}
                    value={accountStatus}
                    defaultValue={accountStatus}
                    setValue={setValue}
                    validation={{ required: false }}
                    disabled={{ disabled: true }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <FInput
                    type='text'
                    errors={errors}
                    label='TP Account No:'
                    name='tag_tp_acc_no'
                    register={register}
                    placeholder={accountNo}
                    value={accountNo}
                    defaultValue={accountNo}
                    setValue={setValue}
                    validation={{ required: false }}
                    disabled={{ disabled: true }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <FSelect
                    control={control}
                    errors={errors}
                    label="Request Mode"
                    name="request_mode"
                    register={register}
                    placeholder="Select Request"
                    options={[
                      { name: "Manual", value: "Manual" },
                      { name: "Online", value: "Online" },
                    ]}
                    validation={{ required: true }}
                    value={"Manual"}
                    setValue={setValue}
                    disabled={selectedRow == null ? false : true}
                  />
                </Col>


                <Col md="4 mb-3">
                  <FSelect
                    control={control}
                    errors={errors}
                    label="Request Category"
                    name="request_category"
                    register={register}
                    placeholder="Select Category"
                    options={categories.map(obj => {
                      return { value: obj.name, name: obj.name }
                    })}
                    validation={{ required: true }}
                    //value={data.category}
                    setValue={handleCategoryChange}
                    disabled={selectedRow == null ? false : true}
                  />
                </Col>

                <Col md="4 mb-3">
                  <FSelect
                    control={control}
                    errors={errors}
                    label="Request Type"
                    name="request_type"
                    register={register}
                    placeholder="Select Type"
                    options={requestTypes.filter(obj => obj.category === selectedCategory).map(obj => {
                      return { value: obj.name, name: obj.name }
                    })}
                    validation={{ required: true }}
                    setValue={handleCategoryChange}
                  />
                </Col>

                <Col md="4 mb-3">
                  <FSelect
                    control={control}
                    errors={errors}
                    label="Request Status"
                    name="request_status"
                    register={register}
                    placeholder="Select Status"
                    options={requestStatus.filter(obj => obj.category === selectedCategory).map(obj => {
                      return { value: obj.name, name: obj.name }
                    })}
                    validation={{ required: true }}
                    value={data.request_status || "Open"}
                    setValue={setValue}
                    disabled={selectedRow == null ? false : true}
                  />
                </Col>

                <Col md="4 mb-3">
                  <FSelect
                    control={control}
                    errors={errors}
                    label="Sub Type"
                    name="sub_type"
                    register={register}
                    placeholder="Select Type"
                    options={subType.filter(obj => obj.category === selectedCategory).map(obj => {
                      return { value: obj.name, name: obj.name }
                    })}
                    validation={{ required: true }}
                    value={data.sub_type}
                    setValue={setValue}
                  />
                </Col>

                <Col md="4 mb-3" style={{ display: selectedCategory === 'Finance' ? 'block' : 'none' }}>
                  <FSelect
                    control={control}
                    errors={errors}
                    label="Action"
                    name="action"
                    register={register}
                    placeholder="Select Type"
                    options={[
                      { name: "Add", value: "Add" },
                      { name: "Checked", value: "Checked" },
                      { name: "Processed", value: "Processed" },
                      { name: "NA", value: "NA" },
                    ]}
                    validation={{ required: false }}
                    value={data.action}
                    setValue={setValue}
                  />
                </Col>
                <Col md="4 mb-3">
                  <FSelect
                    control={control}
                    errors={errors}
                    label="Assign To"
                    name="assignTo"
                    register={register}
                    placeholder="Select Assigne"
                    options={assignToList}
                    validation={{ required: true }}
                    value={data.assignTo}
                    setValue={setValue}
                  />
                </Col>
                <Col md="4 mb-3">
                  <FSelect
                    control={control}
                    errors={errors}
                    label="Priority"
                    name="priority"
                    register={register}
                    placeholder="Select Priority"
                    options={[

                      { name: "High", value: "High" },
                      { name: "Normal", value: "Normal" },
                      { name: "Low", value: "Low" },
                    ]}
                    validation={{ required: true }}
                    value={data.priority}
                    setValue={setValue}
                  />
                </Col>
                <Col md="4 mb-3">
                  <FInput
                    errors={errors}
                    label="Attachments"
                    name="imageUrl"
                    type="file"
                    className={"ng-untouched ng-pristine" + border_danger}
                    onChange={handleChange}
                    register={register}
                    placeholder="Enter Description"
                    validation={{ required: false }}
                  //multiple
                  />
                </Col>
                {/*username: janycemetz_authorizer
                    password: QxVepIOEQzQ*/}

                <Col md="12 mb-3">
                  <FTextArea
                    errors={errors}
                    label="Description"
                    name="description"
                    className={"ng-untouched ng-pristine" + border_danger}
                    value={data.description}
                    register={register}
                    placeholder="Enter Description"
                    validation={{ required: false }}
                  />
                </Col>

              </FormGroup>
            </Container>
            <Btn
              attrBtn={{
                color: "danger",
                className: "cancel-btn",
                id: "close-task-panel",
                onClick: closeTaskWrapper,
              }}
            >
              {Close}
            </Btn>
            <Button
              className="ms-3 add-new-task-btn"
              color="success"
              id="submitBtn"
              disable={submitState}
            >
              {AddTask}
            </Button>
          </Form>
        </div>
      </div>
    </Fragment>
  );
};
export default TodoList;
