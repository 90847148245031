import React, { Fragment, useState } from "react";
import { Col, Form, Row, FormGroup, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";

import { bannersService } from 'Services';
import CommonModal from "Components/Common/Data/Ui-kits/Modals/common/modal";
import { FInput } from 'CommonElements/Form';
import { Btn } from "AbstractElements";

const BannerAdd = ({ loadData }) => {
  const [modal, setModal] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const { register, handleSubmit, formState: { errors }, reset, control, setValue, getValues } = useForm();

  const modalSubmit = formValues => {
    if (formValues !== '') {
      setLoading(true);

      var formData = new FormData();
      formData.append("title", formValues.title);
      formData.append("description", formValues.description);
      formData.append("link", formValues.link);
      // formData.append("fileUrl", formValues['fileUrl']);
      // formData.append("mblUrl", formValues['mblUrl']);
      if (imageList !== '') {
        imageList.forEach((item) => {
          formData.append(item.key, item.value[0]);
        });
      }

      bannersService.addBanner(formData).then(res => {
        setLoading(false);
        toggle();
        reset();
        toast.success('Banner added, successfully');
        loadData();
      }).catch(err => {
        setLoading(false);
        toast.error('Failed to add Banner');
      });
    } else {
      errors.showMessages();
    }
  };
  // const handleChange = (e) => {
  //     setValue(e.target.name, e.target.files[0]);
  // }
  const updateListFunction = (key, files) => {
    // Check if the key already exists in the imageList
    const existingIndex = imageList.findIndex(entry => entry.key === key);

    if (existingIndex !== -1) {
      // If the key already exists, update its value
      const updatedList = [...imageList];
      updatedList[existingIndex].value = files;
      setImageList(updatedList);
    } else {
      // If the key does not exist, add it as a new entry
      const newValue = {
        key,
        value: files
      };
      const updatedList = [...imageList, newValue];
      setImageList(updatedList);
    }
  };
  return (
    <Fragment>
      <Btn attrBtn={{ disabled: loading, onClick: toggle, color: 'primary' }}>Add Banner</Btn>

      <CommonModal
        isOpen={modal}
        title={'Add New Banner'}
        toggler={toggle}
        formSubmit={() => { }}
        hideFooter={true}
      >
        <Form className="needs-validation" noValidate=""
          onSubmit={handleSubmit(modalSubmit)}
        >
          <Row>
            <Col md='12 mb-3'>
              <FInput
                errors={errors}
                label='Title'
                name='title'
                register={register}
                placeholder='Enter Banner Title'
                validation={{ required: true }}
              />
            </Col>

            <Col md='12 mb-3'>
              <FInput
                errors={errors}
                label='Description'
                name='description'
                register={register}
                placeholder='Enter Description'
                validation={{ required: false }}
              />
            </Col>
            <Col md='12 mb-3'>
              <FInput
                errors={errors}
                label='Banner Link'
                name='link'
                register={register}
                placeholder='Enter Link'
                validation={{ required: false }}
              />
            </Col>
            <Col md='12 mb-3'>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label">Desktop Image</Label>
                <Col sm="9">
                  <input onChange={(e) => {
                    updateListFunction('fileUrl', [e.target.files[0]]);
                  }} name="fileUrl" className="form-control" type="file" />
                </Col>
              </FormGroup>
            </Col>
            <Col md='12 mb-3'>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label">Mobile Image</Label>
                <Col sm="9">
                  <input onChange={(e) => {
                    updateListFunction('mblUrl', [e.target.files[0]]);
                  }} name="mblUrl" className="form-control" type="file" />
                </Col>
              </FormGroup>
            </Col>

            <Col md={12} className="d-flex flex-row-reverse">
              <Btn disabled={loading} attrBtn={{ color: 'primary' }}>Add</Btn>
            </Col>

          </Row>
        </Form>
      </CommonModal>
    </Fragment>
  );
};

export default BannerAdd;
