import React, { Fragment, useState } from "react";
import { Col, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";

import { tradingAccountsService } from 'Services';
import CommonModal from "Components/Common/Data/Ui-kits/Modals/common/modal";
import { FSelect } from 'CommonElements/Form';
import { Btn } from "AbstractElements";

const AddBankAccount = ({ customerId,  loadData, accountTypes = []   }) => {
  const [modal, setModal] = useState(false);
  const [accountType, setAccountType] = useState('');

  const [loading, setLoading] = useState(false);
  const [platform, setPlatform] = useState('');
  const toggle = () => {
    setModal(!modal)

  };
  const { register, handleSubmit, formState: { errors }, reset, setValue, control } = useForm();
  
  const formValueChange = (name, value) => {
    setValue(name , value);
    if(name === 'accountType') {
        setAccountType(value);
    }
    if(name === 'platform'){
        console.log(value);
        setPlatform(value);
    }
  }

  /*setTimeout(() => {
    setAccountType('LIVE');
    setValue('accountType', 'LIVE')
  }, 1);*/

  const modalSubmit = formValues => {
    if (formValues !== '') {
        setLoading(true);
            const params = {
                leverage: formValues.leverage,
                accountTypeId: formValues.accountTypeId,
                customerId,
                platform: formValues.platform,
            }
            console.log('platform is',formValues.platform)
            tradingAccountsService.createAccount(params).then(res => {
                setLoading(false);
                toggle();
                reset();
                toast.success('Trading account created, successfully');
                loadData();
            }).catch(err => {
                setLoading(false);
                console.error('Failed to create Trading Account:', err);
                toast.error('Failed to create Trading Account');
            })
    } else {
        errors.showMessages();
        toast.error('Failed to create Trading Account');

    }
};
  return (
    <Fragment>
        {/* <Button disabled={loading} onClick={toggle}  color={'primary'} >{'Add Bank Account'}</Button> */}
        <Btn attrBtn={{ disabled:loading, onClick:toggle, color: 'primary' }} >{'Create Account'}</Btn>
        <CommonModal
            isOpen={modal}
            title={'Create New Account'}
            toggler={toggle}
            formSubmit={()=>{}}
            hideFooter={true}
        >
            <Form  className="needs-validation" noValidate="" 
                onSubmit={handleSubmit(modalSubmit)}
            >
                    <Row>  
                        <Col md='6 mb-3' >
                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Account Category'
                                name = 'accountType'
                                register = {register}
                                options = {[{name: 'Live', value: 'LIVE'},{name: 'Demo', value: 'DEMO'}]}
                                validation={{required: true}}
                                value={accountType}
                                setValue={formValueChange}
                            />
                        </Col>
                        <Col md='6 mb-3' >
                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Platform'
                                name = 'platform'
                                placeholder = 'Platform'
                                register = {register}
                                options = {[{name: 'MT5', value: 'MT5'},{name: 'MT4', value: 'MT4'}]}
                                validation={{required: true}}
                                value ={{platform}}
                                setValue={formValueChange}
                            />
                        </Col>
                        <Col md='6 mb-3' >
                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Select Account Type'
                                name = 'accountTypeId'
                                register = {register}
                                placeholder = 'Select account Type'
                                options = {accountTypes.filter(obj => obj.type === accountType && obj.platform === platform && obj.showInCrm === 1).map(obj => {
                                    return {value: obj.id, name: obj.title}
                                })}
                                validation={{required: true}}
                                // value={accountType}
                                setValue={formValueChange}
                            />
                        </Col>
                        <Col md='6 mb-3' >
                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Select Leverage'
                                name = 'leverage'
                                register = {register}
                                placeholder = 'Select account Leverage'
                                options = {[{
                                    name: '1:400', value: 400,
                                },{
                                    name: '1:300', value: 300,
                                }]}
                                validation={{required: true}}
                                // value={400}
                                setValue={formValueChange}
                            />
                        </Col>
                        <Col md={12} className="d-flex flex-row-reverse">
                            <Btn disabled={loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn>
                        </Col>
                        
                    </Row>

                    {/* <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn> */}
                </Form>
        </CommonModal>
    </Fragment>
  );
};

export default AddBankAccount;
