import React, { Fragment, useState } from "react";
import { Btn } from "../../../AbstractElements";
// import { NewMessage } from '../../../../../Constant';
import DepositFormModal from "../../Pages/Deposits/DepositFormModal";

const DepositModalButton = ({ btnText, defaultVal, refreshRecord }, props) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <Fragment>
      <Btn attrBtn={{ color: "primary", onClick: toggle }}>{btnText}</Btn>
      <DepositFormModal
        modal={modal}
        NewMessage="Add Deposit"
        toggle={toggle}
        defaultVal={defaultVal}
        setModal={setModal}
        refreshRecord={refreshRecord}
      />
    </Fragment>
  );
};

export default DepositModalButton;
