import React from 'react';

const TableRowApprove = ({
    onClick = () => {}
}) =>(
    <span onClick={onClick} className="m-2 font-success" style={{cursor: 'pointer'}}>
        <i className="fa fa-check"></i>
    </span>
);

export default TableRowApprove;