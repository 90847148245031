import React, { Fragment, useState } from "react";
import { Col, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";

import { bankAccountsService } from 'Services';
import TableRowEdit from 'CommonElements/Table/TableRowEdit';
import CommonModal from "Components/Common/Data/Ui-kits/Modals/common/modal";
import { FInput } from 'CommonElements/Form';
import { Btn } from "AbstractElements";

const EditBankAccount = ({ data={},  loadData  }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal(!modal)
    setTimeout(()=>{
        setFormFields();
    },1)
  };
  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm();
  
  // for adding data values to form
  const setFormFields = () => {
    const formFields = getValues();

    Object.keys(formFields).forEach(key => {

      if(data[key]) {
        setValue(key, data[key])
      }
    });
  }

  const modalSubmit = formValues => {
    if (formValues !== '') {
        setLoading(true);
        bankAccountsService.updateBankAccount(data.id, formValues).then(res => {
            setLoading(false);
            toggle();
            toast.success('Bank account updated, successfully');
            loadData();
        }).catch(err => {
            setLoading(false);
            toast.error('Failed to update Bank Account');
        });
    } else {
        errors.showMessages();
    }
};
  return (
    <Fragment>
        <TableRowEdit onClick={toggle} />

        <CommonModal
            isOpen={modal}
            title={'Edit Bank Account'}
            toggler={toggle}
            formSubmit={()=>{}}
            hideFooter={true}
        >
            <Form  className="needs-validation" noValidate="" 
                onSubmit={handleSubmit(modalSubmit)}
            >
                    <Row>  
                        <Col md='6 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Account Holder Name'
                                name = 'accountHolderName'
                                register = {register}
                                placeholder= 'Enter Account Holder Name'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='6 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Bank Name'
                                name = 'bankName'
                                register = {register}
                                placeholder= 'Enter bankName'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='6 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Account Number'
                                name = 'accountNumber'
                                register = {register}
                                placeholder= 'Enter Account Number'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='6 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Swift Code'
                                name = 'swiftCode'
                                register = {register}
                                placeholder= 'Enter Swift Code'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='6 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Address'
                                name = 'address'
                                register = {register}
                                placeholder= 'Enter Address'
                                validation={{required: false}}
                            />
                        </Col>
                        <Col md='6 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Currency'
                                name = 'currency'
                                register = {register}
                                placeholder= 'Enter Account Currency'
                                validation={{required: false}}
                            />
                        </Col>
                        <Col md='12 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'IBAN'
                                name = 'iban'
                                register = {register}
                                placeholder= 'Enter IBAN Number'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md={12} className="d-flex flex-row-reverse">
                            <Btn disabled={loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn>
                        </Col>
                        
                    </Row>

                    {/* <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn> */}
                </Form>
        </CommonModal>
    </Fragment>
  );
};

export default EditBankAccount;
