import React, { Fragment } from 'react';
import { H6 } from '../../../AbstractElements';
import { Col, Card, Row } from 'reactstrap';
import {  Label, Input } from 'reactstrap'

const ClientDetailHeader = ({customerId, data}) => {
  const arr = [
    {key: 'Is Funded', value: data.isFunded},
    // {key: 'Email Verified', value: data.emailVerified},
    // {key: 'Phone Verified', value: data.phoneVerified},
    {key: 'Is Active', value: data.isActive},
    {key: 'Is Blocked', value: data.isBlocked},
    {key: 'Is Client', value: data.isClient},
    {key: 'Is Ib', value: data.isIb},
    {key: 'Is Lead', value: data.isLead},

  ];
  return (
    <Fragment>
        <Card className='hovercard text-center'>
          <div className='info'>
         
            <div className="m-checkbox-inline mb-0 pull-left">
              {arr.map((stage, index) => 
                <div className="checkbox checkbox-dark" key={index}>
                    <Input id={stage.key} type="checkbox" checked={stage.value} onChange={()=>{}} />
                    <Label style={{marginBottom: '0px', marginRight: '3rem'}} for={stage.key}>{stage.key}</Label>
                </div>
              )}
              <Label style={{marginBottom: '0px', display: 'none'}}>{'KYC Status : '} {data.kycStatus}</Label>
            </div>

              {/* kycStatus
              startTrading
              submitClientProfile
              submitIbProfile
              submitIbQuestionaire
              isCorporate
              isDeleted */}
          </div>
        </Card>
     
    </Fragment>
  );
};
export default ClientDetailHeader;