import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Media } from 'reactstrap';
import { H4 } from '../../../../../AbstractElements';
import Accounts from '../../../../../assets/images/avtar/accounts.svg';
import FirstTime from '../../../../../assets/images/avtar/ftDeposit.svg';
import Deposit from '../../../../../assets/images/avtar/deposits.svg';
import { formatMoney } from 'Components/Common/Utility';

const SmallApexChart = ({ accounts = {}, depositsFtd = {}, deposits = {} }) => {

  return (
    <>
      <Col xl='4 xl-33' className={`chart_data_right `}>
        <Card className='color_1'>
          <div className='p-3 card-headers'>
            Accounts
          </div>
          <CardBody>
            {accounts &&
              <Media className='align-items-center'>
                <Media body className='right-chart-content'>
                  <div className='flex gap_20'>
                    <div>
                      <img src={Accounts} />
                    </div>
                    <div>
                      <span className='f_24 semibold'>{accounts.currentMonth ? Number(accounts.currentMonth) : '0'}</span><br />
                      <span className='new-boxs gray'>{'Month to Date'}</span>
                    </div>
                  </div>
                  <br /><br />
                  <div className='flex justify-between'>
                    <div>
                      <span className='gray'>{'Last Month'}</span><br />
                      <span className='f_20 semibold'>{accounts.lastMonth ? Number(accounts.lastMonth) : '0'}</span>
                    </div>
                    <div>
                      <span className='gray'>{'Today'}</span><br />
                      <span className='f_20 semibold'>{accounts.today ? Number(accounts.today) : '0'}</span>
                    </div>
                  </div>
                </Media>
              </Media>
            }
          </CardBody>
        </Card>
      </Col>
      <Col xl='4 xl-33' className={`chart_data_right `}>
        <Card className='color_2'>
          <div className='p-3 card-headers'>
            First Time Deposits
          </div>
          <CardBody>
            {depositsFtd && depositsFtd.lastMonth &&
              <Media className='align-items-center'>
                <Media body className='right-chart-content'>
                  <div className='flex gap_20'>
                    <div>
                      <img src={FirstTime} />
                    </div>
                    <div>
                      <span className='f_24 semibold'>{depositsFtd.currentMonth.deposits ? formatMoney(parseInt(depositsFtd.currentMonth.deposits)) + '$' : '0'}</span><br />
                      <span className='new-boxs gray'>{'Month to Date'}</span>
                    </div>
                  </div>
                  <br /><br />
                  <div className='flex justify-between'>
                    <div>
                      <span className='gray'>{'Last Month'}</span><br />
                      <span className='f_20 semibold'>{depositsFtd.lastMonth.deposits ? formatMoney(parseInt(depositsFtd.lastMonth.deposits)) + '$' : '0'}</span>
                    </div>
                    <div>
                      <span className='gray'>{'Today'}</span><br />
                      <span className='f_20 semibold'>{depositsFtd.today.deposits ? formatMoney(parseInt(depositsFtd.today.deposits)) + '$' : '0'}</span>
                    </div>
                  </div>
                </Media>
              </Media>
            }
          </CardBody>
        </Card>
      </Col>
      <Col xl='4 xl-33' className={`chart_data_right `}>
        <Card className='color_3'>
          <div className='p-3 card-headers'>
            Deposits
          </div>
          <CardBody>
            {accounts &&
              <Media className='align-items-center'>
                <Media body className='right-chart-content'>
                  <div className='flex gap_20'>
                    <div>
                      <img src={Deposit} />
                    </div>
                    <div>
                      <span className='f_24 semibold'>{deposits.currentMonth ? formatMoney(parseInt(deposits.currentMonth)) + '$' : '0'}</span><br />
                      <span className='new-boxs gray'>{'Month to Date'}</span>
                    </div>
                  </div>
                  <br /><br />
                  <div className='flex justify-between'>
                    <div>
                      <span className='gray'>{'Last Month'}</span><br />
                      <span className='f_20 semibold'>{deposits.lastMonth ? formatMoney(parseInt(deposits.lastMonth)) + '$' : '0'}</span>
                    </div>
                    <div>
                      <span className='gray'>{'Today'}</span><br />
                      <span className='f_20 semibold'>{deposits.today ? formatMoney(parseInt(deposits.today)) + '$' : '0'}</span>
                    </div>
                  </div>
                </Media>
              </Media>
            }
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default SmallApexChart;
