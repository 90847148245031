
import React, { Fragment } from "react";

import SweetAlert from 'sweetalert2';
import { toast } from "react-toastify";

import { videosService } from 'Services';
import TableRowDelete from 'CommonElements/Table/TableRowDelete';


const DeleteVideo = ({ data={}, loadData }) => {


  const deleteVideo = () => {
    SweetAlert.fire({
        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover this record!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            videosService.deleteVideo(data.id).then(res => {
                toast.success('Video deleted, successfully');
                loadData();
            }).catch(err => {
                toast.error('Failed to delete Video');

            })
        }
    });
};
  return (
    <Fragment>
        <TableRowDelete onClick={deleteVideo} />

    </Fragment>
  );
};

export default DeleteVideo;
