import React, { Fragment, useState } from "react";
import { Col, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";

import { walletsService } from 'Services';
import CommonModal from "Components/Common/Data/Ui-kits/Modals/common/modal";
import { FInput, FSelect } from 'CommonElements/Form';
import { Btn } from "AbstractElements";

const AddWallet = ({ customerId, loadData }) => {
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggle = () => {
        setModal(!modal)

    };
    const { register, handleSubmit, formState: { errors }, reset, setValue, control, } = useForm();


    const modalSubmit = formValues => {
        if (formValues !== '') {
            setLoading(true);
            const params = { ...formValues, customerId };
            walletsService.addWallet(params).then(res => {
                setLoading(false);
                toggle();
                reset();
                toast.success('Wallet added, successfully');
                loadData();
            }).catch(err => {
                setLoading(false);
                toast.error('Failed to add Wallet');
            });
        } else {
            errors.showMessages();
        }
    };
    return (
        <Fragment>
            <Btn attrBtn={{ disabled: loading, onClick: toggle, color: 'primary' }} >{'Add Wallet'}</Btn>

            <CommonModal
                isOpen={modal}
                title={'Add Wallet'}
                toggler={toggle}
                formSubmit={() => { }}
                hideFooter={true}
            >
                <Form className="needs-validation" noValidate=""
                    onSubmit={handleSubmit(modalSubmit)}
                >
                    <Row>
                        <Col md='6 mb-3' >
                            <FSelect
                                control={control}
                                errors={errors}
                                label="Network"
                                name="network"
                                register={register}
                                placeholder="Seleet Network"
                                options={[{
                                    value: 'USD Coin (USDC) | TRC20',
                                    name: 'USD Coin (USDC) | TRC20',
                                }, {
                                    value: 'Tether (USDT) | TRC20',
                                    name: 'Tether (USDT) | TRC20'
                                }, {
                                    value: 'USD Coin (USDC) | ERC20',
                                    name: 'USD Coin (USDC) | ERC20',
                                }, {
                                    value: 'Tether (USDT) | ERC20',
                                    name: 'Tether (USDT) | ERC20'
                                }]}
                                value={[]}
                                setValue={setValue}
                            />
                        </Col>
                        <Col md='6 mb-3' >
                            <FInput
                                errors={errors}
                                label='Address'
                                name='address'
                                register={register}
                                placeholder='Enter Wallet Address'
                                validation={{ required: true }}
                            />
                        </Col>
                        <Col md='6 mb-3' >
                            <FSelect
                                control={control}
                                errors={errors}
                                label="Crypto Currency"
                                name="currency"
                                register={register}
                                placeholder="Select Currency"
                                options={[{
                                    value: 'USDC',
                                    name: 'USDC',
                                }, {
                                    value: 'USDT',
                                    name: 'USDT'
                                }]}
                                value={[]}
                                setValue={setValue}
                            />
                        </Col>
                        <Col md={12} className="d-flex flex-row-reverse">
                            <Btn disabled={loading} attrBtn={{ color: 'primary' }} >{'Update'}</Btn>
                        </Col>

                    </Row>

                    {/* <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn> */}
                </Form>
            </CommonModal>
        </Fragment>
    );
};

export default AddWallet;
